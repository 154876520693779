
import StandardIcon from '@/components/standard-icon';

export default {
    components: {
        StandardIcon
    },
    props: {
        icon: String,
        title: String,
        class: String
    },
    
    //    data() {
//        return {
//            accept,
//            title,
//            icon
//        }
//    },    
    computed: {
        cssClass: function () {
            return 'icon-button ' + this.class;
        }
    },
    emits: ['click'],
    mounted() {

    },

    methods: {
        onClick: function () {
            this.$emit('click');
        }
    }

}

import { IonContent, IonInput, IonRow, IonCol, modalController } from '@ionic/vue';
import IconButton from '@/components/icon-button';
import DialogHeader from '@/components/dialog-header';
import captcha from '@/components/captcha';
import {LicenseCreateVM, LicenseSettingsVM} from '@/models/license';
import {StorageEnum} from '@/constants/enums';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';

export default {
    components: {
        IonContent, IonInput, IonRow, IonCol, DialogHeader, IconButton, captcha
    },

    props: {
        beforeShowHistoryCallback: null,
        afterShowHistoryCallback:null,
        afterImportProfiles: null
//        type: StorageEnum,
//        date: null,
//        nameParam: null,
//        locationCombinations: null
    },
    inject: ["storageService", "languageService", "dialogService", "authenticationService", "utilService", "licenseService"],
    data() {
        return {
            labels: {},
            mfaFeedback: "",
            licenseKey: "",
            authenticationKey: "",
            authenticationCode: "",
            oldLicenseKey: "",
            showMfaCode: false,
            licenseCreateResultVM: null,
            licenseEmail: null,
            showCaptcha: true,
            showLicenseCaptcha: true
//            type: StorageEnum,                        
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.Login).then(x => this.labels.login = x);
        this.languageService.getText(EnumResourceStrings.EmailAddress).then(x => this.labels.email = x);
        this.languageService.getText(EnumResourceStrings.License).then(x => this.labels.license = x);
        this.languageService.getText(EnumResourceStrings.MfaCode).then(x => this.labels.mfaCode = x);
        this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.date = x);
        this.languageService.getText(EnumResourceStrings.OK).then(x => this.labels.OK = x);
        this.languageService.getText(EnumResourceStrings.Remark).then(x => this.labels.remark = x);
    },
    computed: {
        isValidEmail: function () {
            return this.licenseEmail
                    && this.licenseEmail
                    && this.utilService.isValidEmail(this.licenseEmail);
        },
        isValidLicenseFormat: function () {
            var success = true;
            var indexes = new Array(8, 12, 16, 20);//, 32);
            success = this.licenseKey == '' || this.licenseKey.length == 36;
            if (success) {
                success = this.oldLicenseKey != this.licenseKey;
            }
            //console.log(this.licenseKey, this.licenseKey.length, success);
            var i = 0;
            if (success && this.licenseKey) {
                indexes.forEach(val => {
                    if (success) {
                        //          console.log(this.licenseKey.substring(val + i, val + i + 1));
                        success = this.licenseKey.substring(val + i, val + i + 1) == '-';
                        i++;
                    }
                });
            }
            return success;
        },
        canDismiss: function () {
            return false;
        }
    },
    methods: {
        setAuthenticationKey: function (authenticationKey) {
            this.authenticationKey = authenticationKey;
            this.authenticationCode = "";
            this.showMfaCode = false;
            return this.storageService.set(StorageEnum.AuthenticationKey, this.authenticationKey);
        },
        cleanupLicenseKey: function () {
            if (this.licenseKey) {
                var result = this.utilService.cleanupLicenseKey(this.licenseKey);
                if (this.licenseKey != result) {

                    this.licenseKey = result;
                }
            }
        },
        store: function () {
            var licenseSettingsVM = new LicenseSettingsVM(
                    this.licenseKey,
                    this.authenticationKey,
                    null,
                    null,
                    this.showHistoryButton,
                    this.aspectTemplate
                    );
            return this.storageService.storeLicense(licenseSettingsVM);
        },
        save: function () {
            this.licenseService.clear();
            var promises = [];
            var mfaSuccess = false;
            if (!this.licenseKey) {
                this.authenticationKey = null
            }
            if (this.oldLicenseKey != this.licenseKey) {                
                this.authenticationKey = null;
            }

            if (!this.authenticationKey
                    && !this.authenticationCode
                    && this.licenseKey) {
                promises.push(this.authenticationService.sendMfa(this.licenseKey).then(result => {
                    //   console.log(result);
                    if (result.license) {
                        if (result.succeeded) {
                            return this.setAuthenticationKey(result.license.authenticationKey);
                        }
                    } else {
                        this.showMfaCode = result["succeeded"];
                        this.mfaFeedback = result["message"];
                    }
                }));
            }

            if (!this.authenticationKey
                    && this.authenticationCode
                    && (this.licenseKey
                            || (this.licenseCreateResultVM
                                    && this.licenseCreateResultVM.encLic
                                    && this.licenseCreateResultVM.salt)
                            )
                    ) {
                promises.push(this.authenticationService.validateMfa(this.licenseKey, parseInt(this.authenticationCode), this.licenseCreateResultVM).then(result => {
                    mfaSuccess = result.succeeded;
                    var promises2 = [];
                    if (mfaSuccess) {
                        promises2.push(this.setAuthenticationKey(result.license.authenticationKey));
                        promises2.push(this.storageService.set(StorageEnum.AuthHeader, result.license.authHeader));
                        this.licenseKey = result.license.licenseKey;
                        this.cleanupLicenseKey();

                        this.oldLicenseKey = this.licenseKey;
                    } else {
                        this.mfaFeedback = result.message;
                    }
                    return Promise.all(promises2);
                }));
            }

            return Promise.all(promises).then(() => {
                if (mfaSuccess) {
                    return this.store().then(() => {
                        this.closeModal({success: true});
                    });

                }
            });
        },
        onEmailCaptchaSuccess: function (captchaResult) {
//create license, send authenticationcode to emailadress        
            this.showCaptcha = !captchaResult.success;
            if (captchaResult.success) {
                var licenseEmailVM = new LicenseCreateVM();
                licenseEmailVM.captchaCheck = captchaResult;
                licenseEmailVM.email = this.licenseEmail;
                this.authenticationService.createLicense(licenseEmailVM).then(x => {
//console.log(x.message);
                    this.licenseCreateResultVM = x;
                    this.showMfaCode = x.succeeded;
                    this.mfaFeedback = x.message;
                });
            }
        },
        onLicenseCaptchaSuccess: function (captchaResult) {
            this.showLicenseCaptcha = !captchaResult.success;
            if (captchaResult.success) {
                this.save();
            }
        },
        closeModal: function (data) {
            modalController.dismiss(data);
        },
    }
}
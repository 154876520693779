
import {Country, Province} from '@/models/geo';
export default{

    init(locationCombinationService, utilService) {
        this.locationCombinationService = locationCombinationService;
        this.utilService = utilService;
    },
    getUsedCountries: function (locations) {
        var result = [];
        if (locations) {
            var countries = locations.map(location => {
                var country = new Country();
                country.code = location.country.code;
                country.name = location.country.name;
                return country;
            });
            var lookup = {};
            countries.forEach(x => { //prevent duplicate locations
                var hash = this.utilService.getObjectHash(x);
                if (!lookup[hash]) {
                    result.push(x);
                    lookup[hash] = true;
                }
            });
        }
        return result;
    },

    getUsedProvinces: function (locations, country) {
        var result = [];
        if (locations) {
            var provinces = locations.filter(x => {
                return x.country.code == country.code && x.province
            }).map(location => {
                var province = new Province();
                province.code = location.province.code;
                province.name = location.province.name;
                return province;
            });
            var lookup = {};
            provinces.forEach(x => { //prevent duplicate locations
                var hash = this.utilService.getObjectHash(x);
                if (!lookup[hash]) {
                    result.push(x);
                    lookup[hash] = true;
                }
            });
        }
        return result;
    },
    getUsedSubCountries: function (locations, country) {
        var result = [];
        if (locations) {
            var countries = locations.filter(x => {
                return x.country.code == country.code && x.subCountry
            }).map(location => {
                var subCountry = new Country();
                subCountry.code = location.subCountry.code;
                subCountry.name = location.subCountry.name;
                return subCountry;
            });
            var lookup = {};
            countries.forEach(x => { //prevent duplicate locations
                var hash = this.utilService.getObjectHash(x);
                if (!lookup[hash]) {
                    result.push(x);
                    lookup[hash] = true;
                }
            });
        }
        return result;
    },
    
    getLocations: function (newLocations) {
        var locations = [];
        return this.locationCombinationService.getLocationCombinations().then(locationCombinations => {
            var result = [];
            if (locationCombinations) {
                locations = locationCombinations.filter(y => {
                    return y.birthLocation && y.birthLocation.name;
                }).map(x => {
                    return x.birthLocation;
                });
                locations = locations.concat(locationCombinations.filter(y => {
                    return y.eventLocation && y.eventLocation.name;
                }).map(x => {
                    return x.eventLocation;
                }));
            }
            if (newLocations) {
                locations = locations.concat(newLocations);
            }
            var lookup = {};
            locations.forEach(x => { //prevent duplicate locations
                var hash = this.utilService.getObjectHash(x);
                if (!lookup[hash]) {
                    result.push(x);
                    lookup[hash] = true;
                }
            });
            return result;
        });
    }
    /*
     * 
     */
};


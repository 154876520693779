<ion-page>
    <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">
            <ion-tab-button tab="dashboard" href="/tabs/dashboard"  v-if="isMainApp">                
                <ion-icon name="flash"></ion-icon>
                <ion-label v-text='labels.dashboard'></ion-label>
            </ion-tab-button>
            <ion-tab-button tab="calendar" href="/tabs/calendar"  v-if="isMainApp">
                <ion-icon name="calendar"></ion-icon>
                <ion-label v-text='labels.calendar'></ion-label>
            </ion-tab-button>
            <ion-tab-button tab="events" href="/tabs/events" class="hideiftoosmall"  v-if="isMainApp">
                <ion-icon name="send"></ion-icon>
                <ion-label v-text='labels.events'></ion-label>
            </ion-tab-button>
            <ion-tab-button tab="profiles" href="/tabs/profiles"  v-if="isMainApp">
                <ion-icon name="people"></ion-icon>
                <ion-label v-text='labels.profiles'></ion-label>
            </ion-tab-button>
            <ion-tab-button tab="settings" href="/tabs/settings" class="hideiftoosmall"  v-if="isMainApp">
                <ion-icon name="settings"></ion-icon>
                <ion-label v-text='labels.settings'></ion-label>
            </ion-tab-button>
            <ion-tab-button tab="information" href="/tabs/information" class="hideiftoosmall"   v-if="isMainApp">                
                <ion-icon name="apps"></ion-icon>
                <ion-label v-text='labels.information'></ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
</ion-page>
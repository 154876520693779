<ion-buttons v-if='pagerIsInMiddle'>
    <ion-button @click='setPage(0)' v-html='1'></ion-button>
    <ion-button @click='setPage(currentPage - 2)'>...</ion-button>
    <ion-button @click='setPage(currentPage - 1)' v-html='currentPage'></ion-button>
    <ion-button @click='setPage(currentPage)' v-html='currentPage + 1' class='selected' ></ion-button>            
    <ion-button @click='setPage(currentPage + 1)' v-html='currentPage + 2'></ion-button>            
    <ion-button @click='setPage(currentPage + 2)'>...</ion-button>                        
    <ion-button @click='setPage(pageCount - 1)' v-html='pageCount' :class='getPageClass(pageCount)'></ion-button>                                    
</ion-buttons>
<ion-buttons v-if='pagerIsAtEnd'>
    <ion-button @click='setPage(0)' v-html='1' :class='getPageClass(0)'></ion-button>
    <ion-button v-if='pageCount > 6' @click='setPage(5)' >...</ion-button>
    <ion-button v-for='index in endPages' @click='setPage(index)' v-html='index + 1' :class='getPageClass(index)'></ion-button>
</ion-buttons>
<ion-buttons v-if='pagerIsAtStart'>
    <ion-button v-for='index in startPages' v-html='index + 1' @click='setPage(index)'  :class='getPageClass(index)'></ion-button>
    <ion-button v-if='pageCount > 6' @click='setPage(5)'>...</ion-button>
    <ion-button v-if='pageCount > 5' v-html='pageCount' @click='setPage(pageCount -1)' :class='getPageClass(pageCount -1)'></ion-button>
</ion-buttons>
<ion-buttons v-if='showArrows'>
    <ion-button v-if='currentPage > 0' @click='setPage(currentPage - 1)' class="arrowbutton">
        <ion-icon  name="left"></ion-icon>
    </ion-button>
    <ion-button v-if='currentPage < pageCount - 1' @click='setPage(currentPage + 1)' class="arrowbutton">
        <ion-icon  name="right"></ion-icon>
    </ion-button>
</ion-buttons>
import { IonGrid, IonRow, IonCol, IonIcon, IonItem, modalController} from '@ionic/vue';
import {ProfileEvent} from '@/models/locationcombination';
import {LuckFigureVM} from '@/models/luck';
import IconButton from '@/components/icon-button';
import LuckFigure from '@/components/luck-figure';
import EventEditComponent from '@/popups/event-edit';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';


export default {
    components: {IonGrid, IonRow, IonCol, IonIcon, IonItem, IconButton, LuckFigure},
    props: {
        parent: null,
        luckFigure: LuckFigureVM,
        aspectTemplate: Object,
        outerLimit: Number,
        startTimeUnit: null,
        endTimeUnit: null,
        optionItemWidth: Number, //expandable isn't working as expected, so send along expected width        
        profileEvent: null,
        strMoveToEvents: String,
        folders: null
    },
    data() {
        return {
            labels: {},
            offsetWidth: null,
            offsetWidthSet: false,
            initCalled: false,
            //remark: null,
            event: null,
            //folderGuid: null,
            strMoveToCalendar: '',
            maxLength: 64
        }
    },
    watch: {
        luckFigure: function (/*val, oldVal*/) {            
            this.init();
        }
    },
    inject: ["languageService", "locationCombinationService", "utilService"],
    mounted() {
        this.init();
    },

//    updated: function () {
//
//    },
    computed: {
        eventItemOptionLabels: function () {
            return {
                fileName: this.labels.fileName,
                edit: this.labels.edit,
                selectFolder: this.labels.selectFolder,
                save: this.labels.save
            }
        },
        eventDate: function () {
            return this.utilService.formatDate(this.luckFigure.eventDate, 'MM/DD/YYYY HH:mm:ss');
        },
        surfaceDeepWeightString: function () {
            return this.luckFigure.totalSurfaceWeightString + ' *** ' + this.luckFigure.totalDeepWeightString;
        },
        surfaceDeepMode: function () {
            return this.aspectTemplate && this.aspectTemplate.propertiesVm && this.aspectTemplate.propertiesVm.surfaceDeepMode;
        },                
        starClass: function () {
            var result = "float-right star";
            if (this.profileEvent) {
                result += " has-event";
            }
            return result;
        },
        folderName: function () {
            var folderGuid = this.event.folderGuid
            var result = null;
            if (folderGuid && this.folders) {
                var folder = this.folders[folderGuid];
                if (folder) {
                    result = folder.name;
                }
            }
            return result;
        },
    },
    emits: ["onChange", "onFoldersChanged"],
    methods: {
        init: function () {
            this.event = this.profileEvent ? this.profileEvent : new ProfileEvent();
            this.event.date = this.luckFigure.eventDateStruct;
            this.luckFigure.surfaceDeepMode = this.surfaceDeepMode;
            this.event.luckFigure = this.luckFigure;
        },
        onClick: function () {
            this.parent.drillDown(this.luckFigure);
        },
        
        showEdit: function () {
            
            var evt = this.event;            
            var promises = [];
            var profileName = this.event.locationCombinationName;
            var locationCombination = null;
            if (!profileName) {
                promises.push(this.locationCombinationService.getSelectedLocationCombination().then(x => {//                              
                    if (x) {
                        profileName = x.name;
                    }
                    locationCombination = x;
                }));
            }
            console.log('profileEvent', this.profileEvent);
            Promise.all(promises).then(() => {
                var folderGuid = this.event.folderGuid;
                var eventJson = JSON.stringify(this.event);

                modalController.create({
                    showBackdrop: true,
                    cssClass: 'transparent-modal',
                    component: EventEditComponent,
                    componentProps: {
                        event: evt,
                        eventFolderView: this.eventFolderView,
                        folderNameParam: this.folderName,                        
                        profileNameParam: profileName,
                        locationCombination: locationCombination,
                        dateParam: this.eventDate,
                        showDelete: false
                    }
                }).then(popup => {
                    popup.present();
                    popup.onDidDismiss().then(x => {
                        var event = x.data;
                        var role = x.role;
                        if (role === 'deleteEvent') {
                            this.doDelete();
                        } else if (event) {
                            this.event = event;
                            this.save();
                            if (eventJson != JSON.stringify(event)) {
                                //this.onChangeEvent(this.eventDate);
                                this.save();
                            }
                            if (event.folderGuid != folderGuid) {
                                this.foldersChanged();
                            }
                        }
                    });
                });
            });
        },
        save: function () {
            return this.locationCombinationService.addLocationCombinationEvent(this.event).then(key => {
                this.$emit('onChange', key);                
            })

        },
        initControls: function () {
            if (!this.initCalled) {
                this.initCalled = true;
                this.languageService.getText(EnumResourceStrings.FileName).then(x => {
                    this.labels.fileName = x;
                });
                this.languageService.getText(EnumResourceStrings.Save).then(x => {
                    this.labels.save = x;
                });
                this.languageService.getText(EnumResourceStrings.Edit).then(x => {
                    this.labels.edit = x;
                });
                this.languageService.getText(EnumResourceStrings.SelectFolder).then(x => {
                    this.labels.selectFolder = x;
                });
            }
        },
        foldersChanged: function () {
            this.$emit('onFoldersChanged');
        }        
    }
}
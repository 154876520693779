<dialog-header :title="labels.title" :nameParam="nameParam" :showSave="false"  @onClose="closeModal(null)" ></dialog-header>
<ion-content class="dialog">
    <ion-row v-for="(item, key) in dates" :key="key" :class="item.class">                                    
        <ion-col size="9" @click="select(item)" v-html="item.dateString" ></ion-col>                        
        <ion-col size="3">
            <icon-button class="button ion-float-right" :title="labels.edit" @click="update(key, item.date)" icon="create"/>
        </ion-col>                        
    </ion-row>
    <ion-row>
        <ion-col size="9"></ion-col>                        
        <ion-col size="3">
            <icon-button class="button ion-float-right" :title="labels.add" @click="add" icon="add" />
        </ion-col>                        
    </ion-row>
</ion-content>

import { StorageEnum } from '@/constants/enums';
//import utilService from '@/services/utilService';

export default  {
    _keysToSave: [
        StorageEnum.ShowHistoryButton,
//        StorageEnum.StoreUserProfilesInDb,//always true now
        StorageEnum.ShowLuckFiguresOnProfileScreen,
        StorageEnum.DarkMode,
        StorageEnum.AspectTemplate
    ],
    init(storageService, httpClientService) {
        this.service = "settings/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },
    _getSettings: function () {
        return this.httpClientService.post(this.service).then(x => {
            return x && x.succeeded && x.settings ? JSON.parse(x.settings.content) : [];
        });
    },
    loadSettings: function () {
        return this._getSettings().then(settings => {
            var promises = [];
            this._keysToSave.forEach(key => {
                var value = settings[key];
                if (value) {
                    promises.push(this.storageService.set(key, value));
                }
            });
            return Promise.all(promises);
        });
    },
    save: function () {
        var data = {};
        var promises = [];
        this._keysToSave.forEach(storageEnum => {
            promises.push(this.storageService.get(storageEnum).then(x => {
                data[storageEnum] = x;
            }));
        });
        return Promise.all(promises).then(() => {
            return this.storageService.getLicenseKeys().then(licenseData => {
                if (licenseData.authenticationKey && licenseData.licenseKey) {
                    var settings = {
                        content: JSON.stringify(data),
                        //remark: remark,
                        licenseKey: licenseData.licenseKey,
                        authenticationKey: licenseData.authenticationKey
                    }
                    return this.httpClientService.post(this.service + "update", settings);
                } else {
                    return Promise.resolve(true);
                }
            });
        });
    }
}

import moment from 'moment';
import {DegreeVM} from '@/models/degreeVM';

export default  {

    toggleDarkTheme: function (shouldAdd) {
        document.body.classList.toggle('dark', shouldAdd);
    },
    padStartString: function (s, targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (s.length > targetLength) {
            return String(s);
        } else {
            targetLength = targetLength - s.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(s);
        }
    },
    cleanupLicenseKey: function (licenseKey) {
//insert dash at 8-4-4-4-12
        licenseKey = licenseKey.replace(/-/g, '');
        var indexes = new Array(8, 12, 16, 20, 32);
        var prev = 0;
        var result = '';
        indexes.forEach(function (val) {
            if (result.length > 0) {
                result += '-';
            }
            if (licenseKey.length >= val) {
                result += licenseKey.substring(prev, val);
            } else if (licenseKey.length > prev) {
                result += licenseKey.substring(prev);
            }
            prev = val;
        });
        return result;
    },
    createGuid() {
        var dt = new Date().getTime();
        var result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return result;
    },

    cloneObject: function (item) {
        return item ? JSON.parse(JSON.stringify(item)) : null;
    },
    formatString: function (s, args) {
        return s.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
        });
    },
    truncateString: function (value, maxLength) {
        var hasSpace = value.indexOf(' ') > 0;
        if (value.length > maxLength && !hasSpace) {
            value = value.substr(0, maxLength) + '...';
        } else if (hasSpace) {
            var parts = value.split(' ');
            var total = '';
            parts.forEach(function (part) {
                if (total) {
                    total += ' ';
                }
                if (total.length + part.length < maxLength * 2) {
                    total += part;
                } else {
                    total += '...';
                    return false;
                }
            });
            value = total;
        }
        return value;
    },
    formatISODate: function (date) {
        return this.formatDate(date, 'YYYY/MM/DD HH:mm:ss:SSS');
    },
    formatDate: function (value, format) {
        format = format ? format : 'MM/DD/YYYY HH:mm:ss:SSS';
        return (value) ? moment(value).format(format).replace(':000', '') : value;
    },
    getDateFromDateStruct: function (dateStruct)/*: Date */ {
        //  console.log('getDate', time);
        return dateStruct ? new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second) : null;
    },
    getObjectHash: function (item)/*: number*/ {
        return this.getHash(JSON.stringify(item));
    },
    getHash: function (s) {

        var hash = 0, i, chr;
        if (s.length === 0)
            return hash;
        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },

    isValidString: function (value) {
        return !(!value);
    },

    isValidNumber: function (value) {
        return value != null && value !== "" && !isNaN(value);
    },

    isValidEmail: function (value) {
        var rxEmail = /([\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(\w(\w*\w)?\.)+\w([\w-]*\w)?)/;
        return rxEmail.test(value);
    },
    parseNullableInt: function (value) {
        return value == null || value == "" || isNaN(value) ? null : parseInt(value);
    },
    sortDictionaryOnKeys: function (dict, desc) {
        var sorted = [];
        var result = {};
        for (var key in dict) {
            sorted.push(key);
        }
        sorted.sort((a, b) => {
            return desc ? (a > b ? -1 : 1) : (a > b ? 1 : -1);
        });
        for (var i = 0; i < sorted.length; i++) {
            result[sorted[i]] = dict[sorted[i]];
        }
        return result;
    },
    sortDictionaryOnStringValueField: function (dict, valueField, desc, extraDict, extraDictField) {
        var sortedValues = [];
        var result = {};
        for (var key in dict) {
            var sortValue = dict[key][valueField];
            if (extraDict && extraDictField) {
                var item = extraDict[sortValue];
                sortValue = item ? item[extraDictField] : '';
            }
            sortedValues.push({key: key, value: sortValue});
        }
        sortedValues.sort((a, b) => {
            return desc ? (a.value.toLowerCase() > b.value.toLowerCase() ? -1 : 1) : (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1);
        });
        //console.log('sortedValues', sortedValues);

        for (var i = 0; i < sortedValues.length; i++) {
            result[sortedValues[i].key] = dict[sortedValues[i].key];
        }
        return result;
    },
    removeDash: function (licenseKey) {
        return licenseKey ? licenseKey.replace(/-/g, '') : '';
    },

    getDegreeVM: function (value) {
        var result = new DegreeVM();
        var degree = Math.floor(value);
        value -= degree;
        value *= 60;
        var minute = Math.floor(value);
        value -= minute;
        value *= 60;
        var second = Math.floor(value);
        value -= second;
        value *= 100;
        var centSecond = Math.floor(value);
        if (centSecond >= 50) {
            second++;
        }
        result.degree = degree;
        result.minute = this.padStartString(minute, 2, '0');
        result.second = this.padStartString(second, 2, '0');
        result.totalSeconds = second + (minute * 60) + (degree * 60 * 60);
        return result;
    },
    getNormalizedOrb: function (normalizedDistance) {
        var result = (normalizedDistance * 60).toFixed(8);
        return result;
    },
    getDegree: function (degreeVM) {
        return degreeVM.degree + ' ' + degreeVM.minute + ' ' + degreeVM.second
    },
    getSign: function (planetLocation) {
        var result = '';
        if (planetLocation) {
            result = this.padStartString(planetLocation.degree.toString(), 2, '0') + ' ' + planetLocation.sign + ' ' + this.padStartString(planetLocation.minute.toString(), 2, '0');
        }
        return result;
    },

}
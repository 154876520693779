import { IonContent, IonGrid, IonRow, IonCol, IonInput, IonTextarea, IonItem, modalController } from '@ionic/vue';
import IconButton from '@/components/icon-button';
import {SignatureTargetEnum} from '@/constants/enums';
import DialogHeader from '@/components/dialog-header';
//import {ProfileEvent} from '@/models/locationcombination';
import FolderSelectComponent from '@/popups/folderselect';
import LuckFigure from '@/components/luck-figure';
//import {DateStruct} from '@/models/common'
import folder from '/public/assets/icon/folder-closed.svg';
import twostars from '/public/assets/icon/two-stars.svg';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';


export default {
    components: {
        IonContent, IonGrid, IonRow, IonCol, IonInput, IonTextarea, IonItem, DialogHeader, IconButton, LuckFigure
    },
    setup() {
        addIcons({
            folder,
            twostars
        });
    },
    props: {
        readOnly: Boolean,
        event: null,
        eventFolderView: Boolean,
        folderNameParam: String,
        profileNameParam: String,
        dateParam: String,
        showDelete: Boolean,
        locationCombination: null
    },
    inject: ["languageService", "dialogService", "luckService", "licenseService", "aspectTemplateService", "starcheckService", "utilService"],
    data() {
        return {
            labels: {},
            maxDescriptionLength: 256,
            maxLength: 64,
            folderName: null,
            orgDescription: null,
            topAspects: null,
            license: null,
            signatures: {},
            showAspectsTab: false,
            connectingGrandStars: false,
            aspectTemplateBenMals: {}
        }
    },
    computed: {
        luckFigure: function () {
            return this.event.luckFigure;
        },
        surfaceDeepMode: function () {
            return this.luckFigure ? this.luckFigure.surfaceDeepMode : false;
        },
        enrichedAspects: function () {
            var result = null;
            if (this.topAspects) {
                result = this.topAspects.map(aspect => {
                    var cssClassLeft = '';
                    var cssClassRight = '';
                    if (aspect.hasLeftSignature) {
                        cssClassLeft += 'star-left ';
                    }
                    if (aspect.hasRightSignature) {
                        cssClassRight += 'star-right ';
                    }
                    if (this.aspectTemplateBenMals.benefics) {

                        if (this.aspectTemplateBenMals.benefics.indexOf(aspect.sourcePlanet) > -1) {
                            cssClassLeft += 'ben ';
                        } else if (this.aspectTemplateBenMals.malefics.indexOf(aspect.sourcePlanet) > -1) {
                            cssClassLeft += 'mal ';
                        }
                        if (this.aspectTemplateBenMals.benefics.indexOf(aspect.matchPlanet) > -1) {
                            cssClassRight += 'ben ';
                        } else if (this.aspectTemplateBenMals.malefics.indexOf(aspect.matchPlanet) > -1) {
                            cssClassRight += 'mal ';
                        }
                    }
                    //aspect.matchSign = aspect.matchPlanetLocation;
                    aspect.sourcePlanetLocation = this.utilService.getSign(aspect.sourcePlanetLocation);
                    aspect.matchPlanetLocation = this.utilService.getSign(aspect.matchPlanetLocation);

//                    if (aspect.hasLeftCombinedSignature) {
//                        cssClass += 'left-planet-star ';
//                    }
//                    if (aspect.hasRightCombinedSignature) {
//                        cssClass += 'right-planet-star ';
//                    }
                    return {
                        aspect: aspect,
                        cssClassLeft: cssClassLeft,
                        cssClassRight: cssClassRight
                    }
                })
            }
            return result;
        },
        folderText: function () {
            var result = this.folderName;
            return result ? result : this.labels.selectFolder;
        },
        descriptionLengthRemaining: function () {
            var length = this.maxDescriptionLength - (this.event.description ? this.event.description.length : 0);
            return length + ' ' + this.labels.charactersRemaining;
        },
        showDescriptionLength: function () {
            return this.orgDescription != this.event.description;
        },
        canSeeAspects: function () {
            return this.license && this.license.propertiesVm && this.license.propertiesVm.canSeeAspects;
        },
        aspectsClass: function () {
            return this.showAspectsTab ? 'selected' : '';
        },
        generalClass: function () {
            return this.showAspectsTab ? '' : 'selected';
        },
        connectingGrandStarClass: function () {
            return this.connectingGrandStars ? '' : 'selected';
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.Event).then(x => this.labels.title = x);
        this.languageService.getText(EnumResourceStrings.FileName).then(x => this.labels.fileName = x);
        this.languageService.getText(EnumResourceStrings.Notes).then(x => this.labels.notes = x);
        this.languageService.getText(EnumResourceStrings.SelectFolder).then(x => this.labels.selectFolder = x);
        this.languageService.getText(EnumResourceStrings.Folder).then(x => this.labels.folder = x);
        this.languageService.getText(EnumResourceStrings.Save).then(x => this.labels.save = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.confirmDelete = x);
        this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.date = x);
        this.languageService.getText(EnumResourceStrings.Profile).then(x => this.labels.profile = x);
        this.languageService.getText(EnumResourceStrings.CharactersRemaining).then(x => this.labels.charactersRemaining = x);
        this.languageService.getText(EnumResourceStrings.TopH108Aspects).then(x => this.labels.topH108Aspects = x);
        this.languageService.getText(EnumResourceStrings.General).then(x => this.labels.general = x);
        this.languageService.getText(EnumResourceStrings.ShortLabelHarmonic).then(x => {
            this.labels.labelHarmonic = x;
        });
        this.languageService.getText(EnumResourceStrings.ShortLabelPlanet).then(x => {
            this.labels.labelPlanet = x;
        });
        this.languageService.getText(EnumResourceStrings.ShortLabelAspect).then(x => {
            this.labels.labelAspect = x;
        });
        this.languageService.getText(EnumResourceStrings.ShortLabelDistance).then(x => {
            this.labels.labelDistance = x;
        });
        this.languageService.getText(EnumResourceStrings.ShortLabelSign).then(x => {
            this.labels.labelSign = x;
        });

        this.languageService.getText(EnumResourceStrings.ViewInStarCheck).then(x => {
            this.labels.viewInStarCheck = x;
        });
        this.languageService.getText(EnumResourceStrings.ConnectingGrandStars).then(x => {
            this.labels.connectingGrandStars = x;
        });

        this.folderName = this.folderNameParam;
        this.orgDescription = this.event.description;
        this.loadLicense().then(() => {
            this.loadLuckDetails();
        });

    },
    methods: {
        loadLicense: function () {
            return this.licenseService.getLicense().then(x => {
                this.license = x.license;
                return this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                    var aspectTemplateProperties = aspectTemplate.propertiesVm;
                    this.aspectTemplateBenMals = {
                        benefics: aspectTemplateProperties.benefics,
                        malefics: aspectTemplateProperties.malefics,
                        showBens: aspectTemplateProperties.showBens,
                        showMals: aspectTemplateProperties.showMals,
                        flipColors: aspectTemplateProperties.flipColors
                    };
                    return true;
                });
            });

        },
        loadLuckDetails: function () {
            if (this.canSeeAspects) {
                var birthLocation = this.locationCombination.birthLocation;
                var birthDate = this.locationCombination.birthDate;
                var eventLocation = this.locationCombination.eventLocation;
                var eventDate = this.event.date;

                this.luckService.getTopAspects(birthLocation, birthDate, eventLocation, eventDate, SignatureTargetEnum.ChartX_ChartY, this.connectingGrandStars).then(topAspects => {
                    this.topAspects = topAspects;
                });
            }
        },
        viewInStarCheck: function () {
            return this.starcheckService.viewInStarCheck(this.locationCombination, this.event.date, SignatureTargetEnum.ChartX_ChartY, this.connectingGrandStars);
        },
        updateFolder: function () {
//            console.log('updateFolder', this.event.folderGuid);

            modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: FolderSelectComponent,
                componentProps: {
                    folderGuid: this.event.folderGuid,
                    readOnly: this.eventFolderView
                }
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    //console.log('ondiddismiss', x.data, date, locationCombination.birthDate);
                    if (x.data) {
                        if ((this.event.folderGuid == null || this.event.folderGuid != x.data.guid)) {
                            this.event.folderGuid = x.data.guid;
                            this.folderName = x.data.name;

                            //                         this.onChangeEvent(this.eventDate);
                        }
                        //                       this.$emit('onFoldersChanged');
                    }
                });
            });
        },
        save: function () {
            this.closeModal(this.event);
        },
        closeModal(event) {
            modalController.dismiss(event);
        },
        deleteEvent: function () {
            this.dialogService.confirm(this.labels.confirmDelete, () => {
                modalController.dismiss(event, "deleteEvent");
            });
        },
        toggleConnectingGrandStars: function () {
            this.connectingGrandStars = !this.connectingGrandStars;
            this.loadLuckDetails();
        }
    }
}
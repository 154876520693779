import { IonMenu, IonContent, IonButtons, IonButton, menuController} from '@ionic/vue';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import router from '@/router';

export default {
    components: {
        IonMenu, IonContent, IonButtons, IonButton
    },
    data() {
        return {
            menuId: 'hamburger-menu',
            labels: {}
        }
    },

    inject: ["languageService", "configService"],
    mounted() {
        this.languageService.getText(EnumResourceStrings.Dashboard).then(x => this.labels.dashboard = x);
        this.languageService.getText(EnumResourceStrings.Calendar).then(x => this.labels.calendar = x);
        this.languageService.getText(EnumResourceStrings.Events).then(x => this.labels.events = x);
        this.languageService.getText(EnumResourceStrings.Profiles).then(x => this.labels.profiles = x);
        this.languageService.getText(EnumResourceStrings.Information).then(x => this.labels.information = x);
        this.languageService.getText(EnumResourceStrings.Settings).then(x => this.labels.settings = x);
    },
    computed: {
        menuItems: function () {
            var result = [];

            if (this.configService.isMainApp()) {
                result.push(this.getMenuItem(this.labels.dashboard, '/tabs/dashboard'));
                result.push(this.getMenuItem(this.labels.calendar, '/tabs/calendar'));
                result.push(this.getMenuItem(this.labels.events, '/tabs/events'));
                result.push(this.getMenuItem(this.labels.profiles, '/tabs/profiles'));
                result.push(this.getMenuItem(this.labels.information, '/tabs/information'));
                result.push(this.getMenuItem(this.labels.settings, '/tabs/settings'));
            }
            return result;
        },

    },
    methods: {
        getMenuItem: function (title, path) {
            var currentPath = this.$route.path;
            return {title: title, href: path, active: currentPath == path};
        },
        menuClick: function (item) {
            //var type = item.type;
//            console.log(item, item.href);
            if (item.href) {
                router.replace(item.href);
            } else if (item.click) {
                item.click();
            }
            this.close();
        },
        close: function () {
            menuController.close(this.menuId);
        }
    }

}

<ion-item lines="none" :class="class" class="ion-item" >                
    <ion-grid>
        <ion-row>            
            <ion-col>
                <div @click="select" v-html="remark" class="float-left"></div>                    
                <button class="button button-small" @click="edit" >
                    <ion-icon name="details"  color="tertiary"></ion-icon>
                </button>                    
            </ion-col>    
            <ion-col v-html="date"  @click="select"></ion-col>                        

            <ion-col>                    
                <div v-if="!eventFolderView" v-html="folderName" @click="selectFolder"></div>
                <div v-if="eventFolderView" v-html="locationCombinationName" @click="selectProfile"></div>
            </ion-col>
            <ion-col  class="hidden-xs">
                <luck-figure :luckFigure="luckFigure" 
                             :surfaceDeepMode="surfaceDeepMode"
                             ></luck-figure>                    
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>
import { Http } from '@capacitor-community/http';
import configService from './configService';
import {StorageEnum} from '@/constants/enums';

const _apiRootUrl = configService.getApiRootUrl();
export default{

    init: function (storageService) {
        this.storageService = storageService;
    },

    authenticate: function () {
        
        var promises = [];
        var authenticationKey;
        var licenseKey;
        promises.push(this.storageService.get(StorageEnum.AuthenticationKey).then(x => {
            authenticationKey = x;
        }));
        promises.push(this.storageService.get(StorageEnum.LicenseKey).then(x => {
            licenseKey = x;
        }));
        //if (licenseKey && authenticationKey) {
        return Promise.all(promises).then(() => {
            var headers = {'Content-Type': 'application/json'};

            var options = {
                url: _apiRootUrl.substring(0, _apiRootUrl.length - 1) + '-auth/getauthheader',
                data: {
                    licenseKey: licenseKey,
                    authenticationKey: authenticationKey
                },
                headers: headers
            };
            return Http.request({...options, method: 'POST'}).then(x => {
                if (x.status == 200 && x.data) {                    
                    return this.storageService.set(StorageEnum.AuthHeader, x.data);
                        //return true;                    
                }
            });
        });

    },
    getAuthHeader: function () {
        return this.storageService.get(StorageEnum.AuthHeader);
    },
    get: function (url) {        
        return this.getAuthHeader().then(token => {
            var headers = {};            
            if (token) {                
                headers['Authorization'] = 'Basic ' + token;
            }
            return Http.request(
                    {
                        url: _apiRootUrl + url,
                        method: 'GET',
                        headers: headers
                    }).then(x => {
                return x.status == 200 ? x.data : null;
            });
        });
    },

    post: function (url, data, accepts) {
        if (url.indexOf('-') === 0) {
            url = _apiRootUrl.substring(0, _apiRootUrl.length - 1) + url;
        } else {
            url = _apiRootUrl + url
        }

        return this.getAuthHeader().then(token => {
            var headers = {'Content-Type': 'application/json'};
            if (token) {
                headers['Authorization'] = 'Basic ' + token;
            }
            var options = {
                url: url,
                data: data,
                headers: headers
            };
            if (accepts) {
                options.accepts = accepts;
            }
            return Http.request({...options, method: 'POST'}).then(x => {
                return x.status == 200 ? x.data : null;
            });
        });

    }
}
import { IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, menuController, modalController} from '@ionic/vue';
import { EnumResourceStrings } from '@/constants/enumresourcestrings';
import ProfileHistoryComponent from '@/popups/profile-history';
import router from '@/router';
import { reorderFourOutline as hamburger, ellipsisHorizontalOutline as ellipsis, logInOutline as login, logOutOutline as logout} from 'ionicons/icons';
import { addIcons } from 'ionicons';

export default {
    components: {
        IonToolbar, IonTitle, IonButtons, IonButton, IonIcon
    },
    setup() {
        addIcons({
            hamburger,
            ellipsis,
            login,
            logout
        });
    },

    props: {
        undoButtonText: String,
        showUndo: Boolean,
        beforeShowHistoryCallback: null,
        afterShowHistoryCallback: null,
        afterImportProfiles: null,
        showHistoryMenu: false,
        loadTrigger: null
//        reloadPage: null
//        showSearch: Boolean,
//        showAdd: Boolean,
//        isEditingParam: Boolean
    },
    data() {
        return {
            strTitle: "",
            strLogin: "",
            strLogout: "",
            strLogoutMessage: "",
            showLogin: false,
            requireLogin: false
        }
    },
    inject: ["languageService", "licenseService", "dialogService", "storageService", "httpClientService", "configService"],
    emits: ['onUndo'],
    watch: {
        loadTrigger: function () {
            this.init();
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.LuckFigures).then(x => this.strTitle = x);
        this.languageService.getText(EnumResourceStrings.Login).then(x => this.strLogin = x);
        this.languageService.getText(EnumResourceStrings.Logout).then(x => this.strLogout = x);
        this.languageService.getText(EnumResourceStrings.AppLogoutMessage).then(x => this.strLogoutMessage = x);
        this.init();
    },

    computed: {
//        showExtraBar: function () {
//            return this.showSearch || this.showAdd;
//        }
        showHistory: function () {
            return this.showHistoryMenu;
        },
        isMainApp: function () {
            return this.configService.isMainApp();
        }
    },
    methods: {
        init: function () {
            this.requireLogin = this.configService.requireLogin();
            this.licenseService.getLicense().then(license => {
                if (!license || license.succeeded) {
                    this.licenseService.usingDefaultLicense().then(x => {
                        this.showLogin = x;
                        //console.log('showLogin=', this.showLogin);
                    });
                } else {
                    return this.doLogout();
                }

            });

        },
        undo: function () {
            this.$emit('onUndo');
        },
        clickMenu: function () {
            menuController.open('hamburger-menu');
        },
        logout: function () {
            this.dialogService.confirm(this.strLogoutMessage, this.doLogout);
        },

        doLogout: function () {
            this.licenseService.clear();
            return this.storageService.clear().then(() => {
                //TODO when on profiles page, this redirect causes the profiles page to save the current profiles into local storage again
                //needs to be fixed
                return this.httpClientService.authenticate().then(() => {
                    this.storageService.isLoggingOut = true;
                    router.replace('/tabs/settings/loggedout');
                });
            })
        },
        showLoginPopup: function () {
//            this.dialogService.showLoginPopup(); //not here anymore
        },
        showHistoryPopup: function () {
            var props = {
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: ProfileHistoryComponent,
                componentProps: {
                    beforeShowHistoryCallback: this.beforeShowHistoryCallback,
                    afterShowHistoryCallback: this.afterShowHistoryCallback,
                    afterImportProfiles: this.afterImportProfiles
                }
            };
            modalController.create(props).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.role == "backdrop") {//escape button or backdrop clicked, so no data passed in callback
                    } else {
                        if (x.data) {
                            console.log(x.data);
//                            row.birthLocation = x.data.birthLocation;
//                            row.birthDate = x.data.birthDate;
//                            //                this.locationCombinations[i].eventLocation = x.data.eventLocation;
//                            //              this.locationCombinations[i].eventDate = x.data.eventDate;
//                            row.name = x.data.name + '-copy';
                        }
                    }
                });

            });
        }
    }
}

//import {ProfileEvent} from '@/models/locationcombination';


import {StorageEnum} from '@/constants/enums';
import {TimeZoneDateVM} from '@/models/TimeZoneDateVM';

export default  {
    gmtOffsetUrl: "luckfigures/gmtoffset",
    init: function (storageService, httpClientService, utilService) {
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.utilService = utilService;
    },

    getLocationCombinations: function () {
        return this.storageService.storageGet("Locationcombinations");
    },

    isComplete(locationCombination) {
        return locationCombination
                && locationCombination.eventLocation && locationCombination.eventLocation.latitude != null && locationCombination.eventLocation.longitude != null
                && locationCombination.birthLocation && locationCombination.birthLocation.latitude != null && locationCombination.birthLocation.longitude != null
                && locationCombination.birthDate;
    },
    getLocationCombinationHash: function (locationCombination) {
        var result = null;
        if (this.isComplete(locationCombination)) {
            var data = locationCombination.birthLocation.latitude + ',' + locationCombination.birthLocation.longitude + ',' 
                    + this.utilService.getObjectHash(locationCombination.birthDate) + ',' 
                    + locationCombination.eventLocation.latitude + ',' + locationCombination.eventLocation.longitude;
            result = this.utilService.getHash(data);
        }
        return result;
    },
    getLocationCombination: function (guid) {
        return this.storageService.storageGet("Locationcombinations").then(locationCombinations => {
            return locationCombinations.find(x => x.guid == guid);
        });
    },
    setLocationCombinations: function (locationCombinations) {
        locationCombinations = locationCombinations.filter(x => {
            return this.isComplete(x)
        }).map(x => {
            if (!x.guid) {
                x.guid = this.utilService.createGuid();
            }
            return x;
        });
        return this.storageService.storageSet("Locationcombinations", locationCombinations);
    },

    setSelectedLocationCombination: function (locationCombination) {
        var promises = [];
        if (locationCombination) {
            promises.push(this.storageService.setLocation(StorageEnum.Birth, locationCombination.birthLocation));
            promises.push(this.storageService.setDate(StorageEnum.Birth, locationCombination.birthDate));
            promises.push(this.storageService.setLocation(StorageEnum.Event, locationCombination.eventLocation));
            promises.push(this.storageService.storageSet("SelectedLocationcombination", locationCombination));
        }
        return Promise.all(promises);
    },

    setSelectedEvent: function (profileEvent) {
        return this.storageService.storeInMemory("SelectedEvent", profileEvent);
    },

    getSelectedEvent: function () {
        return this.storageService.storageGet("SelectedEvent");
    },

    getGmtOffset: function (timeZoneDateVM) {
        return this.httpClientService.post(this.gmtOffsetUrl, timeZoneDateVM);
    },
    fixLocationCombinationEventsGmtOffset: function (locationCombination, alwaysUpdate) {
        var promises = [];
        var eventTimeZone = locationCombination.eventLocation ? locationCombination.eventLocation.timeZone : null;
        var updatedEvents = {};
        Object.keys(locationCombination.events).forEach(isoDate => {
            var profileEvent = locationCombination.events[isoDate];
            if (alwaysUpdate || !profileEvent.gmtOffset) { //if timexone changed, gmtOffset may still point to another one, needs to be corrected still
                var timeZoneDateVM = new TimeZoneDateVM();
                timeZoneDateVM.timeZone = eventTimeZone;
                timeZoneDateVM.date = profileEvent.date;
                promises.push(this.getGmtOffset(timeZoneDateVM).then(x => {
                    profileEvent.gmtOffset = x.gmtOffset;
                    updatedEvents[isoDate] = profileEvent;
                }));
            } else {
                updatedEvents[isoDate] = profileEvent;
            }
        });
        return Promise.all(promises).then(() => {
            locationCombination.events = updatedEvents;
            return locationCombination;
        });

    },

    fixAllEventsGmtOffset: function (locationCombinations, alwaysUpdate, save) {

        console.log('fixGmtOffset');

        var firstPromises = [];
        if (!locationCombinations) {
            firstPromises.push(this.getLocationCombinations().then(x => {
                locationCombinations = x;
            }));
        }
        return Promise.all(firstPromises).then(() => {
            var promises = [];
            if (locationCombinations) {
                locationCombinations.forEach(locationCombination => {
                    promises.push(this.fixLocationCombinationEventsGmtOffset(locationCombination, alwaysUpdate));
                });
            }
            return Promise.all(promises).then(() => {
                if (locationCombinations && save) {
                    return this.setLocationCombinations(locationCombinations);
                }
            });
        });
    },
    addLocationCombinationEvent: function (profileEvent) {

        var key = this.utilService.formatISODate(profileEvent.date);
        return this.getSelectedLocationCombination().then(locationCombination => {

            if (!locationCombination.events) {
                locationCombination.events = {};
            }
            var eventTimeZone = locationCombination.eventLocation ? locationCombination.eventLocation.timeZone : null;
            var timeZoneDateVM = new TimeZoneDateVM();
            timeZoneDateVM.timeZone = eventTimeZone;
            timeZoneDateVM.date = profileEvent.date;
            return this.getGmtOffset(timeZoneDateVM).then(x => {
                var promises = [];
                profileEvent.gmtOffset = x.gmtOffset;

                locationCombination.events[key] = profileEvent;
                locationCombination.events = this.utilService.sortDictionaryOnKeys(locationCombination.events);
                promises.push(this.getLocationCombinations().then(locationCombinations => {
                    locationCombinations.forEach(x => {
                        if (x.guid == locationCombination.guid) {
                            x.events = locationCombination.events;
                            return false; //break loop
                        }
                    });

                    return this.setLocationCombinations(locationCombinations);
                }));
                promises.push(this.setSelectedLocationCombination(locationCombination));
                promises.push(this.setSelectedEvent(profileEvent));
                return Promise.all(promises);
            });
        }).then(() => {
            return key;
        });
    },
    getSelectedLocationCombination: function () {
        return this.storageService.storageGet("SelectedLocationcombination");
    }
}
import { IonRow, IonCol } from '@ionic/vue';
//import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import LuckFigure from '@/dashboard-page/luckfigure';
//import {StorageEnum} from '@/constants/enums';

export default {
    components: {
        IonRow, IonCol, LuckFigure
    },
    props: {
        parent: null,
        aspectTemplate: Object,
        outerLimit: Number,
        timeUnits: null,
        luckFigures: null,
        optionItemWidth: Number
    },
    data() {
        return {
            events: [],
            folders: {}
        }

    },
    watch: {
        luckFigures: function () {
            this.loadEvents();
            this.loadFolders();
        }
    },
    inject: ["folderService", "locationCombinationService", "utilService"],
    emits: ["onFoldersChanged"],
    mounted() {
        this.init();
    },
    computed: {
        enrichedLuckFigures: function () {
            var result = [];
            result = this.luckFigures.map(luckFigure => {
                var isoDate = this.utilService.formatISODate(luckFigure.eventDate);
                return {
                    luckFigure: luckFigure,
                    profileEvent: this.events[isoDate]
                }
            });            
            return result;
        }
    },
    methods: {
        init: function () {
            this.loadEvents();
            this.loadFolders();
        },
        loadFolders: function () {
            this.folderService.getFolderDictionary().then(folders => {
                this.folders = folders;
            });
        },
        foldersChanged: function () {
            this.loadFolders();
        },
        loadEvents: function () {
            this.locationCombinationService.getSelectedLocationCombination().then(locationCombination => {
                this.events = locationCombination ? locationCombination.events : []; 
            });
        }
    }

}
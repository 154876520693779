<ion-grid class="nomargin pad0">    
    <ion-row class="nomargin" v-if="luckFigure">
        <ion-col class="nomargin">
            <ion-buttons class="nomargin buttons" color='tertiary'>      
                <ion-button :class="getIntervalButtonClass(IntervalEnum.Day)" @click="setInterval(IntervalEnum.Day);">
                    <span v-text="labels.strDays"></span>
                </ion-button>                    
                <ion-button :class="getIntervalButtonClass(IntervalEnum.Hour)" @click="setInterval(IntervalEnum.Hour);">
                    <span v-text="labels.strHours"></span>
                </ion-button>                    
                <ion-button :class="getIntervalButtonClass(IntervalEnum.Minute)" @click="setInterval(IntervalEnum.Minute);">
                    <span v-text="labels.strMinutes"></span>
                </ion-button>                    
                <ion-button :class="getIntervalButtonClass(IntervalEnum.Week)" @click="setInterval(IntervalEnum.Week);">
                    <span v-text="labels.strWeeks"></span>
                </ion-button>                                    
            </ion-buttons>
        </ion-col>
    </ion-row>        
    <ion-row class="nomargin">
        <ion-col class="nomargin">
            <ion-buttons class="nomargin buttons" color='tertiary'>      
                <ion-button class="button btn width60" v-if="stepIntervalSet && luckFigure"  @click="goPrevious">
                    <ion-icon  name="arrowleft" size="large" class="minwidth50 panelicon"></ion-icon>
                </ion-button>                    
                <ion-button class="button btn btn-large center fullwidth" v-if="isReady"  @click="getLuckFigure(null)">
                    <ion-icon  name="go" size="large" class="panelicon"></ion-icon>                
                </ion-button>                    
                <ion-button class="button btn width60" v-if="showHistoryButton && timeUnitsArray.length > 1 && luckFigure"  @click="popTimeUnit(true)" :title="labels.strBack">
                    <ion-icon  name="arrowup" size="large" class="minwidth50  panelicon"></ion-icon>
                </ion-button>                                    
                <ion-button class="button btn width60" v-if="stepIntervalSet && luckFigure"  @click="goNext">
                    <ion-icon  name="arrowright" size="large" class="minwidth50  panelicon" ></ion-icon>
                </ion-button>                    
            </ion-buttons>
        </ion-col>
    </ion-row>        
    <luck-figures v-if="luckFigures" :optionItemWidth="optionItemWidth" :parent="this" :luckFigures="luckFigures" :aspectTemplate="aspectTemplate"
                  :outerLimit="outerLimit" :timeUnits="currentTimeUnits" ref="luckFiguresComponent"></luck-figures>            
</ion-grid>            

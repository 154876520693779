<dialog-header :title="labels.title" :showSave="false"  @onClose="closeModal(null)" ></dialog-header>
<ion-content class="dialog">
    <ion-row>
        <ion-col size="4" v-html="labels.date"></ion-col>                        
        <ion-col size="5" v-html="labels.remark"></ion-col>                        
        <ion-col size="3"></ion-col>                        
    </ion-row>
    <ion-row v-for="item in rows">                                    
        <ion-col size="4" @click="select(item.row)" v-html='item.date' ></ion-col>                        
        <ion-col size="5">                      
            <ion-textarea v-model="item.row.remark" v-if="item.row.isEditing" ></ion-textarea>            
            <span v-html="item.row.remark" v-if="!item.row.isEditing" @click="select(item.row)"></span>            
        </ion-col>                        
        <ion-col size="3">
            <icon-button class="button ion-float-right" :title="labels.select" @click="select(item.row)"  icon="done"/>
            <icon-button class="button ion-float-right" :title="labels.delete" @click="deleteProfile(item.row)"  icon="trash"/>
            <icon-button class="button ion-float-right" :title="labels.edit" @click="edit(item.row)" v-if="!item.row.isEditing" icon="edit"/>
            <icon-button class="button ion-float-right" :title="labels.save" @click="update(item.row)" v-if="item.row.isEditing" icon="save"/>
        </ion-col>                        
    </ion-row>
</ion-content>
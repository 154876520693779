import { IonContent, IonList, IonRow, IonCol, modalController, IonIcon} from '@ionic/vue';
import DialogHeader from '@/components/dialog-header';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';


export default {
    components: {
        IonContent, IonList, IonRow, IonCol, IonIcon, DialogHeader
    },
//    props: {
//
//    },
    inject: ["storageService", "locationCombinationService", "languageService", "dialogService"],
//    setup() {
//        addIcons({
//            trash, create, add
//        });
//    },
    data() {
        return {
            labels: {},
            locationCombinations: [],
            selectedLocationCombination: null
        }

    },

    mounted() {

        this.selectedLocation = this.location;
        this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.confirmDelete = x);
        this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.add = x);
        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(EnumResourceStrings.SelectLocationCombinationToCopy).then(x => {
            this.labels.title = x;
        });
        this.loadLocationCombinations();
    },
    computed: {
        rows: function () {
            var index = 0;
            return this.locationCombinations.map(row => {
                return {
                    locationCombination: row,
                    class: this.getRowClass(index++)
                };
            });
        }
    },
    methods: {
        loadLocationCombinations() {
            var locationCombinations = null;
            this.locationCombinationService.getLocationCombinations().then(x => {
                locationCombinations = x.filter(y => {
                    return y.name;
                });
                locationCombinations = locationCombinations.sort((first, second) => {
                    return first.name < second.name ? -1 : 1;
                });
                this.locationCombinations = locationCombinations;

            });

        },
        getRowClass: function (index) {
            var result = 'pointer';
            if (index % 2 == 1) {
                result += " odd";
            }
            return result;
        },
        closeModal: function () {
            this.storageService.setLocations(this.type, this.locationCombinations).then(() => {
                modalController.dismiss(this.selectedLocationCombination);
            });
            
        },
        select: function (locationCombination/*: Location*/) {
            this.selectedLocationCombination = locationCombination;
            this.closeModal();
        }
    }

}

import { LicenseCheckVM} from '../models/license';

export default  {
    service: "-auth/",
    init: function (storageService, httpClientService) {
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },    

    createLicense: function (licenseCreateVM) {
        return this.httpClientService.post(this.service + "create", licenseCreateVM);
    },    
    sendMfa: function (licenseKey) {
        var license = new LicenseCheckVM();
        license.licenseKey = licenseKey;
        return this.httpClientService.post(this.service + "sendmfa", license);
    },

    validateMfa: function (licenseKey, code, licenseCreateResultVM) {
        var license = new LicenseCheckVM();
        license.licenseKey = licenseKey;
        license.authenticationCode = code;
        if (licenseCreateResultVM) {
            license.salt = licenseCreateResultVM.salt;
            license.encLic = licenseCreateResultVM.encLic;
        }
        return this.httpClientService.post(this.service + "validatemfa", license);
    }
}

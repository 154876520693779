import {SignatureTypeEnum, SignatureTypeEnumValues, SignatureTypeEnumValuesReverse, CardEnum} from '@/constants/enums';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';

export default  {

    init: function (httpClientService) {
        this.service = "enum/";
        this.httpClientService = httpClientService;
        var promises = [];
        promises.push(this.loadCardEnum());
        promises.push(this.loadResourceStringEnum());
        promises.push(this.loadSignatureTypeEnum());
        return Promise.all(promises);
    },

    loadCardEnum: function () {
        var url = this.service + "cardenums";
        return this.httpClientService.get(url).then(result => {
            for (var key in result) {
                //CardEnum[key] = result[key];                
                CardEnum[key] = key;                
            }
            //console.log(result);
            return true;
        });
    },

    loadSignatureTypeEnum: function () {
        var url = this.service + "signaturetypeenum";
        return this.httpClientService.get(url).then(result => {
            for (var key in result) {
                SignatureTypeEnum[key] = key;
                SignatureTypeEnumValues[key] = result[key];
                SignatureTypeEnumValuesReverse[result[key]] = key;
            }
            return true;
        });
    },
    loadResourceStringEnum: function () {
        console.log('resourceStrings');
        var url = this.service + "resourcestringsenum";
        return this.httpClientService.get(url).then(result => {
            for (var key in result) {
                EnumResourceStrings[key] = result[key];
            }
            return true;
        });
    },

}

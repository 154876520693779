import { IonButtons, IonButton, IonIcon} from '@ionic/vue';
import IconButton from '@/components/icon-button';
import { caretForwardOutline as right, caretBackOutline as left,      } from 'ionicons/icons';
import { addIcons } from 'ionicons';
export default {
    components: {
        IonButtons, IonButton, IconButton, IonIcon
    },
    setup() {
        addIcons({
            right,left
        });
    },

    props: {
        pageCount: null,
        currentPage: 0,
        arrowMode: false
    },
    data() {
        return {
            startStep: 3
        }
    },

    emits: ['onPage'],

    mounted() {
    },

    computed: {
        pagerIsInMiddle: function () {
            return !this.arrowMode && this.pageCount > 1 && this.currentPage > this.startStep && this.currentPage < this.pageCount - this.startStep - 1;
        },
        pagerIsAtEnd: function () {
            return !this.arrowMode && this.pageCount > 1 && !this.pagerIsInMiddle && this.currentPage >= this.pageCount - this.startStep - 1;
        },
        pagerIsAtStart: function () {
            return !this.arrowMode && this.pageCount > 1 && !this.pagerIsInMiddle && !this.pagerIsAtEnd;
        },
        showArrows: function () {
            return this.arrowMode && this.pageCount > 1;
        },
        startPageForEnd: function () {
            var start = this.pageCount - 5;
            if (start <= 0)
            {
                start = 1;
            }
            return start;
        },
        endPages: function () {
            var result = [];
            for (var i = this.startPageForEnd; i < this.pageCount; i++) {
                result.push(i);
            }
            return result;
        },
        startPages: function () {
            var result = [];
            for (var i = 0; i < 5; i++) {
                result.push(i);
            }
            return result;
        }
    },
    methods: {
        setPage: function (page) {
//            this.currentPage = page;
            this.$emit('onPage', page);
        },
        getPageClass: function (page) {
            return  'pager' + ((page == this.currentPage) ? ' selected' : '');
        }
    }
}

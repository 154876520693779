import {IntervalEnum} from '@/constants/enums';
import {DateStruct} from '@/models/common';

export default  {

    init: function (locationCombinationService) {
        this.locationCombinationService = locationCombinationService;
    },

    getDaysInMonth(month, year) {
        var maxDay = null;
        switch (month + 1) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                maxDay = 31;
                break;
            case 2:
                if (year != null) {
                    maxDay = year % 4 == 0 ? 29 : 28;
                } else {
                    maxDay = 29;
                }
                break;
            default:
                maxDay = 30;
                break;
        }
        return maxDay;
    },
    getNextStepDate: function (stepInterval, dt, next, intervalEnum) {
        var direction = next ? 1 : -1;
        if (stepInterval.day) {
            switch (intervalEnum) {
                case IntervalEnum.Month:
                    dt.setMonth(dt.getMonth() + direction);
                    dt.setDate(1);
                    break;
                case IntervalEnum.Year:
                    dt.setFullYear(dt.getFullYear() + direction);
                    break;
                case IntervalEnum.Week:
                    dt.setDate(dt.getDate() + (7 * direction));
                    break;
                case IntervalEnum.MultiYear:
                    dt.setFullYear(dt.getFullYear() + (12 * direction));
                    break;
                default:
                    dt.setDate(dt.getDate() + ((stepInterval.day + (stepInterval.day > 31 ? 7 : 1)) * direction));
                    break;
            }

        }
        if (stepInterval.hour && intervalEnum != IntervalEnum.Week) {
            dt.setHours(dt.getHours() + ((parseInt(stepInterval.hour) + 2) * direction)); //twohours for calendar
        }
        if (stepInterval.minute) {
            dt.setMinutes(dt.getMinutes() + ((parseInt(stepInterval.minute) + 1) * direction));
        }
        var dateStruct = new DateStruct();
        dateStruct.loadFromDate(dt, true);
        return dateStruct;
    },
    generateTimeUnitsForYear: function (year) {
        var timeUnits = [];
        var day = 31;
        for (var month = 1; month < 12; month++) {//starting from february
            timeUnits.push({day: day, hour: 0, minute: 0});
            day += this.getDaysInMonth(month, year);
        }
        return timeUnits;
    },
    generateTimeUnitsForMultiYear: function (year) {
        var timeUnits = [];
        //year -= (year % 12);                
        var day = ((year + i) % 4 == 0) ? 365 : 366;
        for (var i = 1; i < 12; i++) {
            timeUnits.push({day: day, hour: 0, minute: 0});
            day += ((year + i) % 4 == 0) ? 365 : 366;
        }
        return timeUnits;
    },
    generateTimeUnitsForInterval: function (intervalEnum, daysInMonth) {
        var minutes = 0;
        var steps = 0;
        switch (intervalEnum)
        {
            case IntervalEnum.Month:
                steps = daysInMonth - 1;
                minutes = 1440;
                break;
            case IntervalEnum.Day:
                steps = 12 - 1;
                minutes = 120;
                break;
            case IntervalEnum.Week:
                steps = (7 * 4) - 1;
                minutes = 1440 / 4;
                break;
//            case IntervalEnum.Hour:
//                steps = 7;
//                minutes = 60;
//                break;
//            case IntervalEnum.Week:
//                steps = 7;
//                minutes = 1440 * 7;
//                break;
        }

        //minutes /= steps;//create 6 parts.
        console.log('generateTimeUnitsForInterval', minutes);
        var hours = parseInt(Math.floor(minutes / 60));
        minutes %= 60;
        var days = parseInt(Math.floor(hours / 24));
        hours %= 24;
        var timeUnits = [];
        for (var i = 0; i < steps; i++)
        {
            var minute = parseInt((i + 1) * (minutes || 0));
            var hour = parseInt((i + 1) * (hours || 0));
            var day = parseInt((i + 1) * (days || 0));
            if (minute > 59) {
                hour += Math.floor(minute / 60);
                minute %= 60;
            }
            if (hour > 24) {
                day += Math.floor(hour / 24);
                hour %= 24;
            }
            timeUnits.push({day: day, hour: hour, minute: minute});
        }
        return timeUnits;
    },
    getEventsForDateRange: function (calendarEvents, from, until, minimal) {
//        const date = new Date();
        //const localGmtOffsetSeconds = date.getTimezoneOffset() * -1 * 60;

        var dateFrom = new Date(from.getTime());
        var dateUntil = new Date(until.getTime());
        var localGmtOffsetSeconds = dateFrom.getTimezoneOffset() * -1 * 60;
        dateFrom.setSeconds(dateFrom.getSeconds() - localGmtOffsetSeconds);
        dateUntil.setSeconds(dateUntil.getSeconds() - localGmtOffsetSeconds);
        //console.log('dateFrom', dateFrom, dateUntil, from, until, localGmtOffsetSeconds);

        var yearFrom = dateFrom.getFullYear();
        var yearUntil = dateUntil.getFullYear();




//        var monthFrom = yearFrom + '' + utilService.padStartString((this.dateFrom.getMonth() + 1)), 2, '0');
//        var monthUntil = yearUntil + '' + utilService.padStartString((this.dateUntil.getMonth() + 1)), 2, '0');
//        var hourUntil = monthUntil + '' + utilService.padStartString(this.dateUntil.getHours(), 2, '0');
        var result = [];
        var breakout = false;
        for (var year = yearFrom; year <= yearUntil; year++) {
            if (breakout)
            {
                break;
            }
            var yearEvents = calendarEvents[year];
            if (yearEvents) {
//                console.log('yearEvents', Object.keys(yearEvents));
                var beforeLastYear = year < yearUntil;
                var afterFirstYear = year > yearFrom;
                var endMonth = beforeLastYear ? 12 : (dateUntil.getMonth());
                var startMonth = afterFirstYear ? 0 : (dateFrom.getMonth());
                for (var month = startMonth; month <= endMonth; month++) {
                    if (breakout)
                    {
                        break;
                    }
                    var monthEvents = yearEvents[month];
                    if (monthEvents) {
                        //                      console.log('monthEvents', month, Object.keys(monthEvents));
                        var beforeLastMonth = beforeLastYear || month < (dateUntil.getMonth());
                        var afterFirstMonth = afterFirstYear || month > (dateFrom.getMonth());
                        var endDay = beforeLastMonth ? 31 : (dateUntil.getDate());
                        var startDay = afterFirstMonth ? 1 : (dateFrom.getDate());
                        for (var day = startDay; day <= endDay; day++) {
                            if (breakout)
                            {
                                break;
                            }
                            var dayEvents = monthEvents[day];
                            if (dayEvents) {
                                //                            console.log('dayEvents', Object.keys(dayEvents));
                                var beforeLastDay = beforeLastMonth || day < (dateUntil.getDate());
                                var afterFirstDay = afterFirstMonth || day > (dateFrom.getDate());
                                var endHour = beforeLastDay ? 24 : (dateUntil.getHours());
                                var startHour = afterFirstDay ? 0 : (dateFrom.getHours());
                                for (var hour = startHour; hour <= endHour; hour++) {
                                    if (breakout)
                                    {
                                        break;
                                    }
                                    var hourEvents = dayEvents[hour];
                                    if (hourEvents) {
                                        //                                console.log('hourEvents', year, month, day, hour, Object.keys(hourEvents));
                                        var beforeLastHour = beforeLastDay || hour < (dateUntil.getHours());
                                        var afterFirstHour = afterFirstDay || hour > (dateFrom.getHours());
                                        var endMinute = beforeLastHour ? 60 : (dateUntil.getMinutes());
                                        var startMinute = afterFirstHour ? 0 : (dateFrom.getMinutes());
                                        for (var minute = startMinute; minute < endMinute; minute++) {
                                            var minuteEvents = hourEvents[minute];
                                            if (minuteEvents) {
                                                minuteEvents.forEach(item => {
                                                    result.push(item);
                                                });
                                                if (minimal) {
                                                    breakout = true;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return result;
    },

    getEvents: function (/*locationCombinations*/) {
//        console.log('getEventFolders');
        var dictYears = {};
        var promises = [];
        var locationCombinations = null;
        //const date = new Date();
        //const localGmtOffsetSeconds = date.getTimezoneOffset() * -1 * 60;
        //console.log('current GMT offset', localGmtOffsetSeconds);
        //TODO all dts have to go back to translated to GMT and then the current timezonoffset needs to be added...

        //var unCategorizedAdded = false;        
//        if (!locationCombinations || locationCombinations.length == 0) {
        promises.push(this.locationCombinationService.getLocationCombinations().then(x => {
            locationCombinations = x;
            locationCombinations = locationCombinations.filter(y => y.events);
        }));
        //var searchDictionary = {};
        return Promise.all(promises).then(() => {
            if (locationCombinations) {
                locationCombinations.filter(x => x.events).forEach(locationCombination => {
                    Object.keys(locationCombination.events).forEach(isoDate => {
                        var profileEvent = locationCombination.events[isoDate];
                        var dateStruct = profileEvent.date;
                        var gmtOffsetSeconds = profileEvent.gmtOffset;
                        if (gmtOffsetSeconds) {
                            var dt = new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute);
                            //dt.setSeconds(dt.getSeconds() - gmtOffsetSeconds + localGmtOffsetSeconds);
                            dt.setSeconds(dt.getSeconds() - gmtOffsetSeconds);
                            dateStruct = new DateStruct();
                            dateStruct.loadFromDate(dt, true);
                        }


                        if (!(dateStruct.year in dictYears)) {
                            dictYears[dateStruct.year] = {};
                        }
                        var month = dateStruct.month;
                        var hour = dateStruct.hour;
                        var minute = dateStruct.minute;
                        if (!(month in dictYears[dateStruct.year])) {
                            dictYears[dateStruct.year][month] = {};
                        }
                        if (!(dateStruct.day in dictYears[dateStruct.year][month])) {
                            dictYears[dateStruct.year][month][dateStruct.day] = {};
                        }
                        if (!(hour in dictYears[dateStruct.year][month][dateStruct.day])) {
                            dictYears[dateStruct.year][month][dateStruct.day][hour] = {};
                        }
                        if (!(minute in dictYears[dateStruct.year][month][dateStruct.day][hour])) {
                            dictYears[dateStruct.year][month][dateStruct.day][hour][minute] = [];//array
                        }
                        //profileEvent.locationCombination = locationCombination.name;
                        var item = {profileEvent: profileEvent, locationCombination: locationCombination};
                        dictYears[dateStruct.year][month][dateStruct.day][hour][minute].push(item);
                    });
                });
            }
            //           console.log('dictYears', Object.keys(dictYears));
            return dictYears;
        });
    }
}
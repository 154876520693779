export class CaptchaCheck {
    constructor() {
        this.hash = null;
        this.salt = '';
        this.answer = null;
    }
}

export class CaptchaResult extends CaptchaCheck {
    constructor() {
        super();
        this.success = null;
    }
}


export class Captcha {
    constructor() {
        this.image = '';// string;
        this.hash = null;//: number;
        this.salt = '';//: string;
    }

}

export class TimeUnit {
    constructor() {
        this.day = null;
        this.hour = null;
        this.minute = null;
    }

}
export class DateStruct {
    constructor() {
        this.year = null;
        this.month = 0;
        this.day = null;
        this.hour = null;
        this.minute = null;
        this.second = null;
        this.milliSecond = null;
    }

    loadFromDate(dt, setSeconds)
    {
        this.year = dt.getFullYear();
        this.month = dt.getMonth();
        this.day = dt.getDate();
        this.hour = dt.getHours();
        this.minute = dt.getMinutes();
        if (setSeconds) {
            this.second = dt.getSeconds();
        }
    }
    
    setToCurrentDate() {
        this.loadFromDate(new Date(), false);
    }

    equals(other) {
        return this.year == other.year
                && this.month == other.month
                && this.day == other.day
                && this.hour == other.hour
                && this.minute == other.minute;
    }

    // public fromJson(json): void {
    //   this.year = DateStruct.parseNullableInt(json.year);
    //   this.month = DateStruct.parseNullableInt(json.month);
    //   this.day = DateStruct.parseNullableInt(json.day);
    //   this.hour = DateStruct.parseNullableInt(json.hour);
    //   this.minute = DateStruct.parseNullableInt(json.minute);
    //   this.second = DateStruct.parseNullableInt(json.second);
    //   this.milliSecond = DateStruct.parseNullableInt(json.milliSecond);
    // }

}

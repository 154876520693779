<ion-grid>
    <ion-row class="titlerow">
        <ion-col size-md="4" size-xs="10">
            <div v-html="labels.strTitle" class="title"></div>
        </ion-col>                                    
        <ion-col size-md="2" size-xs="2" >
            <div  class="smallpager">
                <pager :pageCount='pageCount' @onPage='setPage' :currentPage="currentPage" arrowMode="true"></pager>
            </div>            
        </ion-col>        
    </ion-row>    
    <ion-row class="gridheader">
        <ion-col size-md="1.5" size-xs="4">
            <grid-column :displayName="labels.strFileName" :sortEnum="SortEnum.Reference" :sortCombination="sortCombination" @onClick="sort"></grid-column>
        </ion-col>
        <ion-col size-md="1.5" size-xs="4">
            <grid-column :displayName="labels.strDate" :sortEnum="SortEnum.Date"  :sortCombination="sortCombination"  @onClick="sort"></grid-column>
        </ion-col>
        <ion-col size-md="1.5" size-xs="4">
            <grid-column :displayName="labels.strFolder" :sortEnum="SortEnum.Folder"  :sortCombination="sortCombination"  @onClick="sort"></grid-column>
        </ion-col>        
        <ion-col size-md="1.5" class="hidden-xs">
            
        </ion-col>
    </ion-row>
    <ion-row>        
        <ion-col size-md="6 " size-xs="12" v-for="row in currentPageItems">
            <event :labels="labels" :event="events[row.eventDate]" :eventDate="row.eventDate" :selected="selectedEventKey==row.eventDate"                    
                   :optionItemWidth="optionItemWidth" :folders="folders" :isEditing="isEditing" 
                   :locationCombinationName="locationCombination.name"
                   locationCombination="locationCombination"
                   @onFoldersChanged="foldersChanged" @onSelect="select" @onChange="onChangeEvent" @onDelete="doDelete" 
                   @onGetLuckFigure="getLuckFigure" @onSelectFolder="selectFolder" @onSort="sort"
                   ></event>            
        </ion-col>        
    </ion-row>
    <ion-row class="pagerrow">
        <ion-col>
            <pager :pageCount='pageCount' @onPage='setPage' :currentPage="currentPage"></pager>
        </ion-col>        
    </ion-row>
</ion-grid>

import { IonHeader, modalController} from '@ionic/vue';
import TitleToolbar from '@/components/title-toolbar';
//import {StorageEnum} from '@/constants/enums';
import LoginComponent from '@/popups/login';
import router from '@/router';

export default {
    components: {
        IonHeader, TitleToolbar
    },
    setup() {
//        addIcons({
//            add, eye, create
//        });
    },

    props: {
        undoButtonText: String,
        showUndo: Boolean,
        showSearch: Boolean,
        showAdd: Boolean,
        isEditingParam: Boolean,
        beforeShowHistoryCallback: null,
        afterShowHistoryCallback: null,
        afterImportProfiles: null,
        showHistoryMenu: false,
        loadTrigger: null
    },
    data() {
        return {}
    },
    inject: ["licenseService", "storageService", "settingsService", "userProfileService", "importService", "configService"],
    emits: ['onUndo'],

    watch: {
        loadTrigger: function () {
            this.init();
        }
    },

    computed: {

    },
    methods: {
        init: function () {
            if (this.configService.requireLogin()) {
                this.licenseService.usingDefaultLicense().then(showLogin => {

                    if (showLogin) {
                        var props = {
                            component: LoginComponent,
                            canDismiss: false,
                            showBackdrop: true,
                            backdropDismiss: false,
                            cssClass: 'transparent-modal login-modal'
                        };
                        modalController.create(props).then(popup => {
                            popup.present();
                            popup.onDidDismiss().then(x => {
                                if (x.role == "backdrop") {//escape button or backdrop clicked, so no data passed in callback                                
                                } else {
                                    if (x.data && x.data.success) {
                                        this.showLogin = false;
                                        this.settingsService.loadSettings().then(() => {
                                            //TODO get latest locationcombinations
                                            //this.storageService.set(StorageEnum.StoreUserProfilesInDb, true).then(() => {
                                            //FN20230320 always true from now on
                                            this.userProfileService.getLatest().then(data => {
                                                if (data.succeeded && data.userProfile) {
                                                    var json = {result: data.userProfile.content};
                                                    var storageService = this.storageService;
                                                    this.importService.doImportFile(false, [json], function () {
                                                        storageService.isLoggingOut = true;
                                                        router.replace('/tabs/settings/loggedin');
                                                    });
                                                }
                                            });
                                            //});//                                     
                                        });
                                    }
                                }
                            });

                        });
                    }

                });
            }
        },

        undo: function () {
            this.$emit('onUndo');
        }
    }
}

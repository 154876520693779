export class TimeZoneDateVM
{
    constructor() {
        this.timeZone = '';
        this.year = null;
        this.month = null;
        this.day = null;
        this.hour = null;
        this.minute = null;
    }
    set date(value){
        this.year = value.year;
        this.month = value.month;
        this.day = value.day;
        this.hour = value.hour;
        this.minute = value.minute;
    }
}



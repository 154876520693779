<dialog-header :title="labels.title" :showSave="true" @onSave="save" @onClose="closeModal"></dialog-header>
<ion-content> 
    <ion-row v-if="canSeeAspects">
        <ion-col size="12">
            <div class='tabs'>
                <div class="btn" v-html="labels.general" @click="showAspectsTab=false" :class="generalClass">

                </div>
                <div class="btn" v-html="labels.topH108Aspects" @click="showAspectsTab=true" :class="aspectsClass">

                </div>
            </div>
        </ion-col>
    </ion-row>
    <div v-if="!showAspectsTab">
        <ion-row class="read-only">
            <ion-col size="4" v-html="labels.date">

            </ion-col>
            <ion-col v-html="dateParam">            
            </ion-col>
        </ion-row>
        <ion-row class="read-only">
            <ion-col size="4" v-html="labels.profile">

            </ion-col>
            <ion-col v-html="profileNameParam">

            </ion-col>
        </ion-row>
        <ion-row>            
            <ion-col offset="4">
                <luck-figure :luckFigure="luckFigure" 
                             :surfaceDeepMode="surfaceDeepMode"
                             ></luck-figure>                    
            </ion-col>
        </ion-row>        
        <ion-row>
            <ion-col size="4" v-html="labels.fileName">

            </ion-col>
            <ion-col>
                <ion-input type="text" :maxlength="maxLength" class="form-control" v-model="event.remark"></ion-input>
            </ion-col>                        
        </ion-row>
        <ion-row>
            <ion-col size="4" v-html="labels.notes">

            </ion-col>
            <ion-col>
                <ion-textarea autoGrow="true" :maxlength="maxDescriptionLength" class="form-control textarea" v-model="event.description" ></ion-textarea>
                <br />
                <div v-if="showDescriptionLength" v-html="descriptionLengthRemaining"></div>
            </ion-col>                        
        </ion-row>        
        <ion-row>
            <ion-col size="4" v-html="labels.folder">

            </ion-col>
            <ion-col>
                <div class='folder' v-html="folderText" @click="updateFolder"></div>                                            
            </ion-col>
            <ion-col>
                <icon-button class="button ion-float-left landscape" icon="folder" @click="updateFolder"></icon-button>
            </ion-col>
        </ion-row>
        <ion-row>

            <ion-col>
                <input type="button" class="button font16 float-right margin-right-10" ion-button @click="save" :value="labels.save" />
                <input type="button" v-if="showDelete" class="button font16 float-right margin-right-10" ion-button @click="deleteEvent" :value="labels.delete" />
            </ion-col>

        </ion-row>
    </div>
    <div v-if="showAspectsTab">
        <ion-row v-if="canSeeAspects && enrichedAspects">
            <ion-col>            
                <table>                
                    <thead>
                        <tr>
                            <th colspan="7" v-html="labels.topH108Aspects"></th>
                            <th colspan="1">
                                <button class="glyphicon" :class='connectingGrandStarClass' @click="toggleConnectingGrandStars" :title="labels.connectingGrandStars">
                                    <ion-icon name="twostars" size="large"  ></ion-icon>    
                                </button>
                            </th>
                        </tr>
                        <tr>    
                            <th v-html="labels.labelHarmonic"></th>                            
                            <th v-html="labels.labelPlanet"></th>                            
                            <th class='planetposition' v-html="labels.labelSign"></th>
                            <th v-html="labels.labelAspect"></th>                            
                            <th v-html="labels.labelHarmonic"></th>                            
                            <th v-html="labels.labelPlanet"></th>                            
                            <th class='planetposition' v-html="labels.labelSign"></th>
                            <th class='degree' v-html="labels.labelDistance"></th>                
                        </tr>                                            
                    </thead>
                    <tbody>
                        <tr v-for="item in enrichedAspects">
                            <td v-html="item.aspect.sourceCard"></td>
                            <td v-html="item.aspect.sourcePlanet" :class='item.cssClassLeft'></td>
                            <td class='planetposition' v-html="item.aspect.sourcePlanetLocation"></td>
                            <td v-html="item.aspect.aspect"></td>
                            <td v-html="item.aspect.matchCard"></td>
                            <td v-html="item.aspect.matchPlanet"  :class='item.cssClassRight'></td>
                            <td class='planetposition' v-html="item.aspect.matchPlanetLocation"></td>
                            <td class='degree' v-html="item.aspect.degree" ></td>
                        </tr>
                    </tbody>                
                </table>

            </ion-col>            
        </ion-row>
        <ion-row>
            <ion-col>
                <button class="button btn blue" v-html="labels.viewInStarCheck" @click="viewInStarCheck"></button>  
            </ion-col>
        </ion-row>
    </div>
</ion-content>
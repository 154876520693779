import { modalController } from '@ionic/vue';
import TimeComponent from '@/popups/time';
import TimeSelectComponent from '@/popups/time-select';
import LocationSelectComponent from '@/popups/location-select';
import LocationComponent from '@/popups/location';
import {Location} from '@/models/geo';
import {StorageEnum} from '@/constants/enums';

export default{

    init: function (locationService, utilService) {
        this.locationService = locationService;
        this.utilService = utilService;
    },

    updateLocation: function (type/*: StorageEnum*/, location, name, countAllowed, newLocations, callback) {
        return this.locationService.getLocations(newLocations).then(locations => {
            var props = {};
            if (!locations || locations.length == 0) {
                props = {
                    showBackdrop: true,
                    cssClass: 'transparent-modal',
                    component: LocationComponent,
                    componentProps: {
                        type: type,
                        locationParam: new Location(),
                        locationsParam: locations,
                        nameParam: name
                    }
                };
            } else {

                props = {
                    showBackdrop: true,
                    cssClass: 'transparent-modal',
                    component: LocationSelectComponent,
                    componentProps: {
                        type: type,
                        location: this.utilService.cloneObject(location),
                        locationsParam: locations,
                        countAllowed: countAllowed,
                        nameParam: name
                    }
                };
            }
            return modalController.create(props).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.role == "backdrop") {//escape button or backdrop clicked, so no data passed in callback
                    } else {
                        if (x.data && x.data != location) {
                            callback(x.data);
                        }
                    }
                });
            });
        });
    },
    updateDate: function (type/*: StorageEnum*/, date/*: DateStruct*/, name, locationCombinations, callback) {
//        console.log('updateTime', date, typeof date, locationCombination.birthDate);
        var componentProps = {
            type: type,
            date: this.utilService.cloneObject(date),
            nameParam: name
        };
        var component = TimeComponent;

        if (type == StorageEnum.Birth && locationCombinations.filter(x => x.birthDate).length > 0)
        {
            component = TimeSelectComponent;
            componentProps['locationCombinations'] = locationCombinations;
        }

        return modalController.create({
            showBackdrop: true,
            cssClass: 'transparent-modal',
            component: component,
            componentProps: componentProps
        }).then(popup => {
            popup.present();
            popup.onDidDismiss().then(x => {
                //console.log('ondiddismiss', x.data, date, row.birthDate);
                if (x.data && x.data != date) {
                    callback(x.data);
                }
            });
        });
    },
}
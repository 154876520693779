//import {SignatureTypeEnum, CardEnum} from '@/constants/enums';
import Constants from '@/constants/constants';

export default {
    service: "starcheck/",
    outerLimit: Constants.signaturesOuterLimit,

    init: function (httpClientService, aspectTemplateService, locationService, aspectCombinationService) {
        this.httpClientService = httpClientService;
        this.aspectTemplateService = aspectTemplateService;
        this.locationService = locationService;
        this.aspectCombinationService = aspectCombinationService;
        window.addEventListener(
                "message",
                event => {
                    console.log(event.data);
                },
                false
                );
    },

    getDetailedLocation: function (location, locations) {
        var detailedLocation = locations.find(x => {
            return x.longitude == location.longitude && x.latitude == location.latitude //first a fast check
            //&& this.selectedLocationHash == this.utilService.getObjectHash(location);//slow & thorough check            
        });

        if (detailedLocation) {
            var description = detailedLocation.country.name;
            if (detailedLocation.subCountry) {
                description += ' ' + detailedLocation.subCountry.name;
            }
            if (detailedLocation.province) {
                description += ' ' + detailedLocation.province.name;
            }
            description += ' ' + detailedLocation.name;

            location.name = detailedLocation.name;
            location.description = description;
        }
        return location;
    },
    viewInStarCheck: function (locationCombination, eventDate, signatureTarget, connectingGrandStars) {
        var birthLocation = locationCombination.birthLocation;
        var birthDate = locationCombination.birthDate;
        var eventLocation = locationCombination.eventLocation;

        return this.locationService.getLocations().then(locations => {
            return this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                return this.aspectCombinationService.getAspectCombination(aspectTemplate, null, null, eventDate,
                        false, false, birthLocation, eventLocation, birthDate).then(aspectCombination => {

                    aspectCombination.birthLocation = this.getDetailedLocation(aspectCombination.birthLocation, locations);
                    aspectCombination.currentLocation = this.getDetailedLocation(aspectCombination.currentLocation, locations);

                    aspectCombination.signatureTarget = signatureTarget;
                    aspectCombination.cardEnums = aspectTemplate.propertiesVm.cardEnums;
                    aspectCombination.locationCombinationGuid = locationCombination.guid;
                    aspectCombination.grandStarToGrandStar = connectingGrandStars;
//                    console.log('aspectCombination', aspectCombination);
                    var target = 'starcheck';
                    return this.httpClientService.post(this.service + "viewinstarcheck", aspectCombination).then(result => {
                        if (result.succeeded) {
                            window.open(result.url, target);//                                    );
                        }
                        console.log('result', result);
                        return result;
                    });

                });
            });
        });
    }
}

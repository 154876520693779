import { IonIcon } from '@ionic/vue';
//import { thumbsUp as thumbsup, thumbsDown as thumbsdown } from 'ionicons/icons';
//import { addIcons } from 'ionicons';

export default {
    props: {
        luckFigure: null
    },    
    components: {IonIcon},

//    setup() {
//        addIcons({
//            thumbsup,
//            thumbsdown
//        });
//
//    },
//    mounted() {
//    },

//    updated: function () {
//
//    },

    computed: {
        surfaceDeepMode: function () {
            return this.luckFigure.totalSurfaceWeightString; //not null and not empty
        },
    },
//    emits: ["onChange", "onFoldersChanged"],
    methods: {

    }

}

import { Storage } from '@ionic/storage';
import utilService from '@/services/utilService';
import {StorageEnum} from '@/constants/enums';
import {LicenseCombinationVM} from '@/models/license';


const TTL = 60 * 60;
const CACHE_KEY = '_cached_';
const MAX_IN_MEMORY_SIZE = 1024 * 50;

export default  {
    storage: new Storage(),
    _items: {},
    isLoggingOut: false,
    createStorage: function () {
        return this.storage.create();
    },
    storingInMemory: function (key, length) {
        //speed up by storing user-specific data in memory, 
        //make sure it doesn't get too big.
        return !key.startsWith(CACHE_KEY) && length < MAX_IN_MEMORY_SIZE;
    },
    storeInMemory: function (key, value) {
        this._items[key] = value;
    },
    storageSet: function (key, value) {
        var val = value ? JSON.stringify(value) : null;
        if (!value || this.storingInMemory(key, val.length)) {
            //console.log('STORING IN MEMORY', key);
            this._items[key] = value; //store raw data in memory
        } else if (key in this._items) {
            delete this._items[key];
        }
        return this.storage.set(key, val);
    },
    storageGet: function (key) {
        var promise = new Promise((resolve/*, reject*/) => {
            resolve(this._items[key]);
        });
        return promise.then(result => {
//            console.log('storageGet', key, result);
            if (!result) {
                result = this.storage.get(key).then(x => {
                    var res = (typeof (x) == 'string') ? JSON.parse(x) : x;
                    if (x && res && this.storingInMemory(key, x.length)) {
                        //          console.log('STORING IN MEMORY', key);
                        this._items[key] = res;
                    }
                    return res;
                });
            }
            return result;
        });
    },
    cacheRequest: function (url, data) {
        if (data) {
            const validUntil = (new Date().getTime()) + TTL * 1000;
            url = `${CACHE_KEY}${url}`;
            return this.storageSet(url, {validUntil, data});
        } else {
            return Promise.resolve(true);
        }
    },
    getCachedRequest: function (url) {
        const currentTime = new Date().getTime();
        url = `${CACHE_KEY}${url}`;
        return this.storageGet(url).then(storedValue => {
            if (!storedValue) {
                return null;
            } else if (storedValue.validUntil < currentTime) {
                return this.storage.remove(url).then(() => {
                    return null;
                });
            } else {
                return storedValue.data;
            }
        });
    },
    setLocation: function (storageEnum, location) {
        return this.storageSet(storageEnum + "Location", location);
    },
    getLocation: function (storageEnum) {
        return this.storageGet(storageEnum + "Location");
    },
    getLocations: function (storageEnum) {
        return this.storageGet(storageEnum + "Locations");
    },
    setLocations: function (storageEnum, locations) {
        return this.storageSet(storageEnum + "Locations", locations);
    },
    setDate: function (storageEnum, dateStruct) {
        return this.storageSet("date_" + storageEnum, dateStruct);
    },
    getDate: function (storageEnum) {//: Promise<DateStruct>
        return this.storageGet("date_" + storageEnum);
    },
    get: function (storageEnum) {
        return this.storageGet(storageEnum);
    },
    set: function (storageEnum, value) {
        return this.storageSet(storageEnum, value);
    },
    clear: function () {
        this._items = {};
        return this.storage.clear();
    },
    storeLicense: function (licenseSettingsVM) {
        var promises = [];
        promises.push(this.set(StorageEnum.LicenseKey, licenseSettingsVM.licenseKey));
        promises.push(this.set(StorageEnum.AuthenticationKey, licenseSettingsVM.authenticationKey));
        promises.push(this.set(StorageEnum.AspectTemplate, licenseSettingsVM.aspectTemplate));
        promises.push(this.set(StorageEnum.ShowHistoryButton, licenseSettingsVM.showHistoryButton));
        return Promise.all(promises);
    },
    getLicenseKeys: function () {
        var promises = [];
        var licenseKey = '';
        var authenticationKey = '';
        promises.push(this.get(StorageEnum.LicenseKey).then(x => {
            if (x) {
                licenseKey = utilService.removeDash(x);
            }
        }));
        promises.push(this.get(StorageEnum.AuthenticationKey).then(x => {
            if (x) {
                authenticationKey = utilService.removeDash(x);
            }
        }));
        return Promise.all(promises).then(() => {
            return {
                licenseKey: licenseKey,
                authenticationKey: authenticationKey
            }
        });
    },
    getLicenseCombination: function () {
        var promises = [];
        var licenseKey = null;
        var authenticationKey = null;
        var timeUnits = null;
        promises.push(this.get(StorageEnum.LicenseKey).then(x => licenseKey = x));
        promises.push(this.get(StorageEnum.AuthenticationKey).then(x => authenticationKey = x));
        promises.push(this.get(StorageEnum.TimeUnits).then(x => timeUnits = x));
        return Promise.all(promises).then(() => {
            return new LicenseCombinationVM(licenseKey, authenticationKey, timeUnits);
        });
    },

}

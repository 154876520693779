
import {Captcha, CaptchaCheck} from '@/models/common';
export default {
    service: "captcha/",
    width: 100,
    height: 30,
    init: function (httpClientService) {
        this.httpClientService = httpClientService;
    },
    getCaptcha(retry) {
        return this.httpClientService.post(this.service + "json?width=" + this.width + "&height=" + this.height, {}).then(x => {
            if (!x && !retry) {//maybe due to expired temporary authheader                
                return this.httpClientService.authenticate().then(() => {
                    return this.getCaptcha(true);
                });
            }
            var captcha = new Captcha();
            captcha.image = x["image"];
            captcha.hash = x["hash"];
            captcha.salt = x["salt"];
            return captcha;
        });
    },

    checkResult(result, hash, salt, retry) {
        var captaCheck = new CaptchaCheck();
        captaCheck.answer = result;
        captaCheck.hash = hash;
        captaCheck.salt = salt;
        return this.httpClientService.post(this.service + "checkresult", captaCheck).then(x => {
            if (!x && !retry) {//maybe due to expired temporary authheader                
                return this.httpClientService.authenticate().then(() => {
                    return this.checkResult(result, hash, salt, true);
                });
            }
            return x["succeeded"];
        });
    }

}

import { IonContent, IonInput, IonTextarea, IonRow, IonCol, modalController } from '@ionic/vue';
import IconButton from '@/components/icon-button';
import DialogHeader from '@/components/dialog-header';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';
import { create, add, save, create as edit, checkmarkOutline as done, trash} from 'ionicons/icons';

export default {
    components: {
        IonContent, IonInput, IonTextarea, IonRow, IonCol, DialogHeader, IconButton
    },
    setup() {
        addIcons({
            create, add, save, edit, done, trash
        });
    },
    props: {
        beforeShowHistoryCallback: null,
        afterShowHistoryCallback: null,
        afterImportProfiles: null
    },
    inject: ["languageService", "userProfileService", "dialogService", "importService", "utilService"],
    data() {
        return {
            labels: {},
            userProfileHistory: []
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
        this.languageService.getText(EnumResourceStrings.Select).then(x => this.labels.select = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.confirmDelete = x);
        this.languageService.getText(EnumResourceStrings.Save).then(x => this.labels.save = x);
        this.languageService.getText(EnumResourceStrings.ProfilesHistory).then(x => this.labels.title = x);
        this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.date = x);
        this.languageService.getText(EnumResourceStrings.Tag).then(x => this.labels.remark = x);

        this.userProfileService.getHistory().then(x => {
            this.userProfileHistory = x;
        });
        if (this.afterImportProfiles) {
            this.importService.onImportCompleted = this.afterImportProfiles;
        }
    },
    computed: {
        rows: function () {
            return this.userProfileHistory.map(row => {
                return {
                    row: row,
                    date: this.changedDate(row)
                }
            });
        }
    },
    methods: {
        edit: function (row) {
            row.isEditing = true;
        },

        select: function (row) {
            var promises = [];
            if (this.beforeShowHistoryCallback) {
                promises.push(this.beforeShowHistoryCallback());
            }
            return Promise.all(promises).then(() => {
                return this.userProfileService.getUserProfile(row.id).then(data => {
                    var json = {result: data.userProfile.content};
                    return this.importService.importLocationCombinations([json], true, false).then(() => {
                        if (this.afterShowHistoryCallback) {
                            this.afterShowHistoryCallback().then(() => {
                                this.closeModal();
                            });
                        } else {
                            this.closeModal();
                        }
                    });
                });
            });
        },
        update: function (row) {
            console.log('update', row);
            row.isEditing = false;
            return this.userProfileService.updateRemark(row.id, row.remark);
        },
        deleteProfile: function (row) {
            this.dialogService.confirm(this.labels.confirmDelete, () => {
                this.userProfileService.deleteProfile(row.id).then(() => {
                    this.userProfileService.getHistory().then(x => {
                        this.userProfileHistory = x;
                    });
                });
            });

        },
        changedDate: function (row) {
            var result = row.changedOn ? row.changedOn : row.createdOn;
            var dateFrom = new Date(result);
            var localGmtOffsetSeconds = dateFrom.getTimezoneOffset() * 60;
            dateFrom.setSeconds(dateFrom.getSeconds() - localGmtOffsetSeconds);
            return this.utilService.formatDate(dateFrom, 'MM/DD/YYYY HH:mm');
        },
        closeModal: function (date) {
            modalController.dismiss(date);
        }
    }
}
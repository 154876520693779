

export default  {

    init: function (lang, httpClientService) {
        this.service = "content/";
        this.language = lang;
        this.httpClientService = httpClientService;
    },
    getItem: function (id) {
        var url = this.service + id + "?l=" + this.language;
        return this.httpClientService.get(url);
    },
    getItems: function (tab) {
        var url = this.service + "list/" + tab + "?l=" + this.language;
        return this.httpClientService.get(url);
    }
}


import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import Constants from '@/constants/constants';
import {SortCombination, SortEnum, SortDirection} from '@/models/sortcombination';
import {TreeFolder} from '@/models/locationcombination';

export default  {

    init: function (folderService, eventFolderService, languageService, locationCombinationService, utilService) {
        this.languageService = languageService;
        this.eventFolderService = eventFolderService;
        this.locationCombinationService = locationCombinationService;
        this.folderService = folderService;
        this.utilService = utilService;
        this.uncategorizedName = null;
    },

    getEventFolders: function (locationCombinations, folderSortCombinationLookup, searchString) {
//        console.log('getEventFolders');
        var treeFolders = [];
        var promises = [];

        //var unCategorizedAdded = false;
        if (!locationCombinations || locationCombinations.length == 0) {
            promises.push(this.locationCombinationService.getLocationCombinations().then(x => {
                locationCombinations = x;
            }));
        }
        promises.push(this.folderService.getFolderTree().then(x => {
            treeFolders = x;
        }));
        if (!this.uncategorizedName) {
            promises.push(this.languageService.getText(EnumResourceStrings.Uncategorized).then(x => {
                this.uncategorizedName = x;
            }))
        }
        var defaultSort = new SortCombination(SortEnum.Date);
        //var searchDictionary = {};
        return Promise.all(promises).then(() => {
            var uncategorizedFolder = new TreeFolder();

            uncategorizedFolder.guid = Constants.GUID_NULL;
            uncategorizedFolder.name = this.uncategorizedName;
            uncategorizedFolder.lookupCode = Constants.UNCATEGORIZED_LOOKUPCODE;
            //folders[Constants.GUID_NULL] = uncategorizedFolder;
            treeFolders.push(uncategorizedFolder);
            var lookup = '';
            if (locationCombinations) {
                locationCombinations.filter(x => x.events).forEach(locationCombination => {

                    Object.keys(locationCombination.events).forEach(isoDate => {

                        var locationCombinationGuid = locationCombination.guid;
                        var key = isoDate + '_' + locationCombinationGuid;
                        var profileEvent = locationCombination.events[isoDate];
                        profileEvent.locationCombinationGuid = locationCombinationGuid;
                        profileEvent.locationCombinationName = locationCombination.name;
                        var folderGuid = profileEvent.folderGuid;
//                    console.log(profileEvent.date);
                        if (searchString) {
                            lookup = profileEvent.locationCombinationName + ' '
                                    + this.utilService.formatDate(this.utilService.getDateFromDateStruct(profileEvent.date)) + ' '
                                    + this.utilService.formatDate(this.utilService.getDateFromDateStruct(locationCombination.birthDate)) + ' '
                                    + profileEvent.remark;
                            if (profileEvent.description) {
                                lookup += ' ' + profileEvent.description;
                            }
                        }

                        //console.log(key);
                        if (!searchString || lookup.toLowerCase().indexOf(searchString.toLowerCase()) > -1) {

                            var folder = null;
                            if (folderGuid) {
                                folder = this.folderService.findFolder(treeFolders, folderGuid);
                            }
                            if (!folder) {
                                folder = this.folderService.findFolder(treeFolders, Constants.GUID_NULL);
                            }
                            folder.events[key] = profileEvent;
                        }

                    });
                });
            }
            this.deleteEmptyFolders(treeFolders, searchString);
            this.sortRecursive(treeFolders, folderSortCombinationLookup, defaultSort);
//            console.log('treeFolders', treeFolders);
            return treeFolders;
        });
    },

    sortRecursive: function (folders, folderSortCombinationLookup, defaultSort) {
        folders.forEach(folder => {
            if (folder.guid in folderSortCombinationLookup) {
                this.sortFolder(folder, folderSortCombinationLookup[folder.guid]);
            } else {
                this.sortFolder(folder, defaultSort);
            }
            if (folder.children) {
                this.sortRecursive(folder.children, folderSortCombinationLookup, defaultSort);
            }
        });
    },

    deleteEmptyFolders: function (folders, searchString) {
//        console.log('deleteEmptyFolders', folders);
//        var eventCount = 0;
        var count = folders.length;
        var eventCount = 0;
        var activeLookupCode = this.eventFolderService.activeLookupCode;
        console.log('activelookupcode', activeLookupCode);
        for (var i = 0; i < count; i++) {
            var folder = folders[i];
            eventCount = folder.events ? Object.keys(folder.events).length : 0;
            eventCount += this.deleteEmptyFolders(folder.children, searchString);
            //  console.log(eventCount);
            if ((activeLookupCode != folder.lookupCode)
                    && ((searchString && eventCount == 0)//only remove empty folder when searching, otherwise new folders can't be found                        
                            || (eventCount == 0 && folder.guid == Constants.GUID_NULL)
                            || (activeLookupCode && folder.lookupCode
                                    && !activeLookupCode.startsWith(folder.lookupCode)
                                    && !folder.lookupCode.startsWith(this.eventFolderService.activeLookupCode)
                                    )
                            )) {
                //folders.splice(i, 1);
                folders[i].isHidden = true;
            }
        }

        return folders.filter(x => !x.isHidden).length;

    },

    sortFolder: function (folder, sortCombination) {
        //      console.log('sortFolder', folder.guid, sortCombination.sortEnum, sortCombination.sortDirection);
        switch (sortCombination.sortEnum) {
            case SortEnum.Date:
                folder.events = this.utilService.sortDictionaryOnKeys(folder.events, sortCombination.sortDirection == SortDirection.Desc); //sort by date
                break;
            case SortEnum.Reference:
                folder.events = this.utilService.sortDictionaryOnStringValueField(folder.events, 'remark', sortCombination.sortDirection == SortDirection.Desc); //sort by date
                break;
            case SortEnum.Profile:
                folder.events = this.utilService.sortDictionaryOnStringValueField(folder.events, 'locationCombinationName', sortCombination.sortDirection == SortDirection.Desc); //sort by date
                break;
        }
        return folder;
    }

}
import {CaptchaResult} from '@/models/common';

export default {

    props: {
        title: String
    },
    data() {
        return {
            hash: null,
            image: '',
            salt: '',
            answer: '',
            failCount: 0
        }
    },
//@Output() onSuccess = new EventEmitter < CaptchaResult > ();
//        @Input() title: string = "GO";
//        constructor(private captchaService: CaptchaService) {
//}
    inject: ["captchaService"],
    mounted() {
        this.getCaptcha();
    },
    emits: ['onSuccess'],
    methods: {
        getCaptcha() {
            this.failCount = 0;
            this.answer = null;
            this.captchaService.getCaptcha().then(x => {                
                this.hash = x.hash;
                this.salt = x.salt;
                this.image = "data:image/jpeg;base64," + x.image;
            });
        },
        checkResult() {
            this.captchaService.checkResult(parseInt(this.answer), this.hash, this.salt).then(
                    succeeded => {
                        console.log('checkresult');
                        if (!succeeded) {
                            if (++this.failCount > 2) {
                                this.getCaptcha();
                            }
                        }
                        if (succeeded) {
                            console.log('success');
                            var result = new CaptchaResult();
                            result.hash = this.hash;
                            result.salt = this.salt;
                            result.answer = parseInt(this.answer);
                            result.success = succeeded;
                            this.$emit('onSuccess', result);
                        }
                    }
            );
        }

    }
}

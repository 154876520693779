
import { IonIcon } from '@ionic/vue';
//import { addIcons } from 'ionicons';
//import { chevronBackOutline as chevronback, thumbsUp as thumbsup, thumbsDown as thumbsdown, trash, folderOutline as folder, save, create} from 'ionicons/icons';
export default {

    components: {
        IonIcon
    },
    setup() {

    },
    props: {
        luckFigure: null,
        surfaceDeepMode: false
    },
    data()
    {
        return   {
            
        }
    },    
//    emits: ["onSelect", "onDelete", "onChange", "onFoldersChanged", "onSelectProfile", "onSelectFolder"],
//    inject: ["utilService"],
//    mounted: function () {
//        this.editMode = this.isEditing;
//    },
    computed:{
        
    },
    methods: {
  
    }

}
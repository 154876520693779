<dialog-header :title="dialogTitle" :showSave="false"  @onClose="closeModal(null)" ></dialog-header>
<ion-content>    
    <ion-grid v-for="folder in visibleFolders">
        <folder-component :guidToExclude="guidToExclude" :folder="folder" :labels="labels" @onSelect="select"></folder-component>
    </ion-grid>
    <ion-row>
        <ion-col size="9">

        </ion-col>                        
        <ion-col size="3">
            <icon-button class="button ion-float-right" :title="labels.add" @click="add" icon="add" />
        </ion-col>                        
    </ion-row>
</ion-content>
import { IonIcon} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { caretDown as down, caretUp as up } from 'ionicons/icons';
import {SortEnum, SortDirection} from '@/models/sortcombination';

export default {
    components: {
        IonIcon
    },
    setup() {
        addIcons({
            up,
            down
        });
    },
    emits: ['onClick'],
    props: {
        displayName: String,
        sortEnum: SortEnum,
        sortCombination: null
    },
    data() {
        return {
        }
    },
    computed: {
        showUp: function () {            
            return this.sortCombination && this.sortCombination.sortEnum == this.sortEnum && this.sortCombination.sortDirection == SortDirection.Asc;
        },
        showDown: function () {
            return this.sortCombination && this.sortCombination.sortEnum == this.sortEnum && this.sortCombination.sortDirection == SortDirection.Desc;
        },
    },

    methods: {
        click: function () {
            this.$emit('onClick', this.sortEnum);
        }
    }
}

import {StorageEnum} from '@/constants/enums';
import {AspectCombination} from '@/models/geo';

export default {
    init: function (storageService, licenseService) {
        this.storageService = storageService;
        this.licenseService = licenseService;
    },

    getAspectCombination: function (aspectTemplate, outerLimit, timeUnits, eventDate, useAverages, roundMinutes, birthLocation, eventLocation, birthDate) {
        var aspectTemplateId = aspectTemplate.id;
        var licenseCombination;

        var promises = [];
        var intervalEnum;
        if (!outerLimit) {
            promises.push(this.licenseService.getLicense().then(x => {
                outerLimit = x.license.propertiesVm.aspectSecondaryDegreeAsDouble;
            }));
        }

        promises.push(this.storageService.getLicenseCombination().then(x => {
            licenseCombination = x;
            if (timeUnits) {
                licenseCombination.timeUnits = timeUnits;
            }
        }));
        promises.push(this.storageService.get(StorageEnum.IntervalEnum).then(x => intervalEnum = x));
        if (!birthDate) {
            promises.push(this.storageService.getDate(StorageEnum.Birth).then(x => birthDate = x));
        }
        if (!eventDate) {
            promises.push(this.storageService.getDate(StorageEnum.Event).then(x => eventDate = x));
        }
        if (!birthLocation) {
            promises.push(this.storageService.getLocation(StorageEnum.Birth).then(x => birthLocation = x));
        }
        if (!eventLocation) {
            promises.push(this.storageService.getLocation(StorageEnum.Event).then(x => eventLocation = x));
        }

        return Promise.all(promises).then(() => {
            if (birthDate && birthLocation && eventLocation) {
                return new AspectCombination(
                        birthLocation,
                        eventLocation,
                        birthDate,
                        aspectTemplateId,
                        outerLimit,
                        eventDate,
                        licenseCombination,
                        intervalEnum,
                        useAverages,
                        roundMinutes
                        );
            }
        });

    },

}

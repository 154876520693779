import { IonContent, IonGrid, IonRow, IonCol, modalController} from '@ionic/vue';
import DialogHeader from '@/components/dialog-header';
import {DateStruct} from '@/models/common'

import {StorageEnum} from '@/constants/enums';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
//import { addIcons } from 'ionicons';
//import { save } from 'ionicons/icons';


export default {
    components: {
        IonContent, IonGrid, IonRow, IonCol, DialogHeader
    },
//    setup() {
//        addIcons({
//            save
//        });
//    },
    props: {
        type: StorageEnum,
        date: Object, //DateStruct,
        nameParam: null
    },
    inject: ["storageService", "languageService", "calendarService", "utilService"],
    data() {
        return {
            labels: {},
            dateStruct: new DateStruct(),
            months: {},
            monthsCounter: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            maxDay: 31
        }

    },
    computed: {
        isValid: function () {
            return this.utilService.isValidNumber(this.dateStruct.year)
                    && parseInt(this.dateStruct.year) > 0
                    && this.dateStruct.month > -1
                    && this.utilService.isValidNumber(this.dateStruct.day)
                    && parseInt(this.dateStruct.day) > 0
                    && this.utilService.isValidNumber(this.dateStruct.hour)
                    && this.utilService.isValidNumber(this.dateStruct.minute);
        },
    },

    mounted() {
        //console.log('mounted', this.date, this.type);
        if (this.date) {
            this.dateStruct = this.date;
        }

        if (this.date == null && this.type == StorageEnum.Event) {
            this.storageService.getLocation(StorageEnum.Event).then(location => {
                if (location && location.timeZone) {
                    //if event location is selected, set it to the current time of that location                
                    var dt = new Date(new Date().toLocaleString("en-US", {timeZone: location.timeZone}));
                    this.dateStruct.year = dt.getFullYear();
                    this.dateStruct.month = dt.getMonth();
                    this.dateStruct.day = dt.getDate();
                    this.dateStruct.hour = dt.getHours();
                    this.dateStruct.minute = dt.getMinutes();
                } else {
                    this.dateStruct.setToCurrentDate();
                }
            });
        }

        this.languageService.getText(this.type == StorageEnum.Birth ? EnumResourceStrings.BirthTime : EnumResourceStrings.EventTime).then(x => {
            this.labels.title = x;
        });
        this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.date = x);
        this.languageService.getText(EnumResourceStrings.Time).then(x => this.labels.time = x);
        this.languageService.getText(EnumResourceStrings.yyyy).then(x => this.labels.YYYY = x);
        this.languageService.getText(EnumResourceStrings.dd).then(x => this.labels.DD = x);
        this.languageService.getText(EnumResourceStrings.hh).then(x => this.labels.HH = x);
        this.languageService.getText(EnumResourceStrings.mm).then(x => this.labels.MM = x);
        this.languageService.getText(EnumResourceStrings.ss).then(x => this.labels.SS = x);
        this.languageService.getText(EnumResourceStrings.ms).then(x => this.labels.Ms = x);
        this.languageService.getMonths().then(x => this.months = x);
    },

    methods: {
        keyUpYear(event) {
            this.checkIsNumber(event);
            this.onYearChange(event);
        },

        checkIsNumber(number, representing, max) {

            //console.log('max', max);            
            if (isNaN(number) || (max > 0 && number > max)) {
                //  console.log('representing', representing)
                switch (representing)
                {
                    case 'year':
                        this.dateStruct.year = '';
                        break;
                    case 'day':
                        this.dateStruct.day = '';
                        break;
                    case 'hour':
                        this.dateStruct.hour = '';
                        break;
                    case 'minute':
                        this.dateStruct.minute = '';
                        break;
                    case 'second':
                        this.dateStruct.second = '';
                        break;
                    case 'milliSecond':
                        this.dateStruct.milliSecond = '';
                        break;
                }
            }

        },
        onYearChange() {
            if (this.dateStruct.year != null) {
                this.setDayMax();
            }
        },
        setDayMax() {
            var month = Number(this.dateStruct.month);
            var year = Number(this.dateStruct.year);

            this.maxDay = this.calendarService.getDaysInMonth(month, year);
            var day = this.utilService.parseNullableInt(this.dateStruct.day);
            if (day > this.maxDay) {
                this.dateStruct.day = '';
            }
        },
        close() {
            this.closeModal(null);
        },
        doSave() {
            if (this.isValid) {
                this.storageService.setDate(this.type, this.dateStruct).then(() => {
                    this.closeModal(this.isValid ? this.dateStruct : null);
                });
            }
        },
        closeModal(result/*: DateStruct*/) {
            modalController.dismiss(result);
        }
    }

}
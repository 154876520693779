import { IonList, IonRow, IonCol, IonInput} from '@ionic/vue';
import IconButton from '@/components/icon-button';
import {StorageEnum} from '@/constants/enums';
import EventTimeEdit from '@/components/eventtime-edit';
import ProfileLuckfigure from '@/profiles-page/profile/profile-luckfigure';
import { addIcons } from 'ionicons';
import { copy, trash, add, create, lockClosed as eye} from 'ionicons/icons';

export default {

    props: {
        locationCombination: null,
        locationCombinations: null,
        luckFigure: null,
        countAllowed: null,
        labels: {},
        isReadOnly: null,
        eventDate: null,
        showEventTime: null
    },
    components: {
        IonList, IonRow, IonCol, IonInput, IconButton, EventTimeEdit, ProfileLuckfigure
    },
    setup() {
        addIcons({
            copy,
            trash,
            add,
            create,
            eye
        });
    },
    data() {
        return{
            StorageEnum: StorageEnum,
            maxLength: 64,
            newLocations: []
        }
    },
    watch: {
//        locationCombination: function (val, oldVal) {
//            if (val && oldVal) {
//                this.loadLuckfigure();
//            }
//        },
//        showFigures: function () {
//            this.loadLuckfigure();
//        },
//        selectedEvent: function (val) {
//            this.setEventDate(val);
//        }
    },
    inject: ["locationCombinationEditService","utilService"],
    emits: ["onChangeEventTime", "onClearEventTime", "onSelect", "onCopy", "onUpdateLocation", "onUpdateDate", "onDelete"],
    mounted() {
        //this.setEventDate();
    },
    computed: {
        hasBirthLocation: function () {
            return this.locationCombination.birthLocation && this.locationCombination.birthLocation.name;
        },
        hasEventLocation: function () {
            return this.locationCombination.eventLocation && this.locationCombination.eventLocation.name;
        },
        hasBirthDate: function () {
            return this.locationCombination.birthDate != null;
        },
        eventTimeLabels: function () {
            return {
                eventTime: this.labels.strEventTime,
                delete: this.labels.strDelete
            }
        },
        rowClass: function () {
            var result = 'row border-default';
            if (this.locationCombination.isSelected) {
                result += ' selected';
            }
            if (!this.locationCombination.isEditing) {
                result += " pointer";
            }
            return result;
        },
        birthRowClass: function () {
            return this.locationCombination.isEditing ? 'edit-birth-row' : 'visible';
        },
        eventRowClass: function () {
            return this.locationCombination.isEditing ? 'edit-event-row' : 'visible';
        },
//        isReadyForLuck: function () {
//            return this.locationCombination.birthLocation && this.locationCombination.eventLocation && this.locationCombination.birthDate;
//        },
        eventLabels: function () {
            return {
                strTitle: this.labels.strEvents,
                strSelectFolder: this.labels.strSelectFolder,
                strFolder: this.labels.strFolder,
                strEdit: this.labels.strEdit,
                strFileName: this.labels.strFileName,
                strDelete: this.labels.strDelete,
                strDate: this.labels.strDate
            };
        },
        showCopy: function () {
            return (!this.locationCombination.eventLocation || !this.locationCombination.eventLocation.name) && this.locationCombinations.length > 1;
        },
        birthDate: function () {
            return this.formatDate(this.getDate(this.locationCombination.birthDate));
        },
        birthLocation: function () {
            return this.wrapName(this.locationCombination.birthLocation.name);
        },
        eventLocation: function () {
            return this.wrapName(this.locationCombination.eventLocation.name);
        }
    },
    methods: {
//        setEventDate: function () {
//            this.eventDate = this.selectedEvent ? this.selectedEvent.date : null;
//        },
        select: function () {
            if (this.hasBirthLocation && this.hasEventLocation && this.hasBirthDate) {
                this.$emit("onSelect", this.locationCombination);
            }
        },
        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        getDate: function (dateStruct)/*: Date */ {
            //  console.log('getDate', time);
            return this.utilService.getDateFromDateStruct(dateStruct);
        },
        edit: function () {
            this.locationCombination.isEditing = true;

        },
        isEditing: function () {
            return this.locationCombination.isEditing;
        },
        readOnly: function () {
            this.locationCombination.isEditing = false;
        },

        copy: function () {
            this.$emit("onCopy", this.locationCombination);
        },

        updateLocation: function (/*: number*/type/*: StorageEnum*/, location/*: Location*/, name) {
            this.locationCombinationEditService.updateLocation(type/*: StorageEnum*/, location/*: Location*/, name, this.countAllowed, this.newLocations, newLocation => {
                this.newLocations.push(newLocation);
                this.$emit("onUpdateLocation", this.locationCombination, type, newLocation);
            });
        },
        updateDate: function (type/*: StorageEnum*/, date/*: DateStruct*/, name) {
            this.locationCombinationEditService.updateDate(type, date, name, this.locationCombinations, (newDate) => {
                this.$emit("onUpdateDate", this.locationCombination, type, newDate);
            });

        },
        
        doDelete: function () {
            this.$emit("onDelete", this.locationCombination);
        },
        wrapName: function (name) {
            return this.utilService.truncateString(name, 15);
        },
//        loadLuckfigure: function () {
//            this.luckFigurePanel.getLuckFigure();
//        },
        clearEventTime: function (evt) {
            //this.eventDate = null;
            //this.eventDateStruct = null;            
            if (evt) {
                evt.stopPropagation();
            }
            this.$emit('onClearEventTime');
        },
        onChangeEventTime: function (dateStruct) {
            console.log('onChangeEventTime', dateStruct);
            //this.eventDateStruct = dateStruct;
            //this.eventDate = dateStruct;// ? new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second, dateStruct.milliSecond) : null;
            this.$emit('onChangeEventTime', dateStruct);
        },

    }

}

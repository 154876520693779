import { IonInput, IonGrid, IonRow, IonItem, IonCol} from '@ionic/vue';
import Events from '@/profiles-page/events';
import IconButton from '@/components/icon-button';

import {StorageEnum} from '@/constants/enums';
import ProfileEdit from '@/components/profile-edit';
import LuckFigurePanel from '@/dashboard-page/luckfigure-panel';


import { addIcons } from 'ionicons';
import { copy, trash, add, create, lockClosed as eye} from 'ionicons/icons';

export default {

    props: {
        profileCount: null,
        countAllowed: null,
        locationCombination: null,
        locationCombinations: null,
        luckFigure: null,
        selectedEvent: null,
        folders: {},
        optionItemWidth: null,
        labels: {},
        aspectTemplate: null,
        outerLimit: null,
        isReadOnly: null,
        showFigures: null,
        showEvents: null
    },
    components: {
        IonInput, IonGrid, IonRow, IonItem, IonCol, IconButton, Events, LuckFigurePanel, ProfileEdit
    },
    inject: ["storageService", "locationCombinationService", "utilService"],
    emits: ["onAddToHistory", "onDelete", "onSelect", "onCopy", "onFoldersChanged", "onSelectFolder", "onUpdateDate", "onUpdateLocation"],
    setup() {
        addIcons({
            copy,
            trash,
            add,
            create,
            eye
        });
    },
    data() {
        return{
            StorageEnum: StorageEnum,
            maxLength: 64,
            eventDate: null
        }
    },
    watch: {
        locationCombination: function (val, oldVal) {
            if (val && oldVal) {
                this.loadLuckFigure();
            }
        },
        showFigures: function () {
            this.loadLuckFigure();
        },
        selectedEvent: function (val) {
            this.setEventDate(val);
        }
    },
    mounted() {
        this.setEventDate();
        if (!this.isReadOnly) {
            this.loadLuckFigure();
        }
    },
    computed: {
        rowClass: function () {
            var result = 'row border-default';
            if (this.locationCombination.isSelected) {
                result += ' selected';
            }
            if (!this.locationCombination.isEditing) {
                result += " pointer";
            }
            return result;
        },
        birthRowClass: function () {
            return this.locationCombination.isEditing ? 'edit-birth-row' : 'visible';
        },
        eventRowClass: function () {
            return this.locationCombination.isEditing ? 'edit-event-row' : 'visible';
        },
        isReadyForLuck: function () {
            return this.locationCombination.birthLocation && this.locationCombination.eventLocation && this.locationCombination.birthDate;
        },
        eventLabels: function () {
            return {
                strTitle: this.labels.strEvents,
                strSelectFolder: this.labels.strSelectFolder,
                strFolder: this.labels.strFolder,
                strEdit: this.labels.strEdit,
                strFileName: this.labels.strFileName,
                strDelete: this.labels.strDelete,
                strDate: this.labels.strDate
            };
        },
        showCopy: function () {
            return (!this.locationCombination.eventLocation || !this.locationCombination.eventLocation.name) && this.profileCount > 1;
        },
        selectedEventKey: function () {
            return this.locationCombination.isSelected && this.selectedEvent ? this.utilService.formatISODate(this.selectedEvent.date) : null;
        },
        birthDate: function () {
            return this.formatDate(this.getDate(this.locationCombination.birthDate));
        },
        birthLocation: function () {
            return this.wrapName(this.locationCombination.birthLocation.name);
        },
        eventLocation: function () {
            return this.wrapName(this.locationCombination.eventLocation.name);
        },

        figuresStyle: function () {
            return this.showFigures && this.locationCombinationService.isComplete(this.locationCombination) ? "" : "display:none";
        },
        luckFigurePanel: function () {
            return this.$refs.luckFigurePanel;
        }
    },
    methods: {
        setEventDate: function () {
            this.eventDate = this.selectedEvent ? this.selectedEvent.date : null;
        },
        select: function () {
            this.doSelect(this.locationCombination);
        },
        selectEvent: function (eventDate) {
            this.doSelect(this.locationCombination, eventDate);
        },
        selectFolder: function (event) {
            this.$emit('onSelectFolder', this.locationCombination, event);
        },
        foldersChanged: function () {
            this.$emit("onFoldersChanged");
        },

        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        getDate: function (dateStruct)/*: Date */ {
            //  console.log('getDate', time);
            return this.utilService.getDateFromDateStruct(dateStruct);
        },

        edit: function () {
            this.locationCombination.isEditing = true;

        },
        isEditing: function () {
            return this.locationCombination.isEditing;
        },
        readOnly: function () {
            this.locationCombination.isEditing = false;
        },
        expand: function () {
            //          this.setOptionsWidth();
        },
        contract: function () {
            this.locationCombination.isEditing = false;
        },

        copy: function () {
            this.doCopy(this.locationCombination);
        },

        updateDate: function (locationCombination, type, newDate) {
            this.$emit("onUpdateDate", this.locationCombination, type, newDate, this.loadLuckFigure);
        },
        clearEventDate: function () {
            this.addToHistory();
            this.locationCombination.eventDate = null;
        },
        deleteEvent: function (eventDate) {
            console.log('deleteEvent', eventDate, this.locationCombination.events);
            this.addToHistory();
            delete this.locationCombination.events[eventDate];
        },
        updateLocation: function (locationCombination, type, newLocation) {
            this.$emit("onUpdateLocation", locationCombination, type, newLocation, this.loadLuckFigure);
        },
        addToHistory: function (locationCombination) {
            if (!locationCombination) {
                locationCombination = this.locationCombination;
            }
            this.onAddToHistory(locationCombination);
        },

        doDelete: function (locationCombination) {
            this.$emit('onDelete', locationCombination);
        },
        doSelect: function (locationCombination, eventDate) {
            this.$emit("onSelect", locationCombination, eventDate);
        },
        doCopy: function (locationCombination) {
            this.$emit("onCopy", locationCombination);
        },
        wrapName: function (name) {
            return this.utilService.truncateString(name, 15);
        },
        loadLuckFigure: function () {
            if (this.showFigures) {
                this.storageService.setDate(StorageEnum.Event, this.eventDate).then(() => {
                    this.luckFigurePanel.getLuckFigure();
                });
            }
        },
        clearEventTime: function (evt) {
            this.eventDate = null;
            //this.eventDateStruct = null;
            this.loadLuckFigure();
//
            if (evt) {
                evt.stopPropagation();
            }
        },
        onChangeEventTime: function (dateStruct) {
            console.log('onChangeEventTime', dateStruct);
            //this.eventDateStruct = dateStruct;
            this.eventDate = dateStruct;// ? new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second, dateStruct.milliSecond) : null;           
            this.loadLuckFigure();
        },
        onAddToHistory: function (row) {
            this.$emit('onAddToHistory', row);
        }
    }

}



export default  {

    init: function (lang, storageService, httpClientService) {
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.language = lang;
        this.service = "geonames/";
        this.countriesUrl = this.service + "countries?l=" + this.language;
        this.provincesUrl = this.service + "provinces?l=" + this.language;
        this.provinces2Url = this.service + "provinces2?l=" + this.language;
        this.placesUrl = this.service + "places?l=" + this.language;
        this.places2Url = this.service + "places2?l=" + this.language;
        this.provinceFromPlaceUrl = this.service + "provincefromplace?l=" + this.language;
        this.provinceFromPlace2Url = this.service + "provincefromplace2?l=" + this.language;
        this.geoLocationUrl = this.service + "geolocation";
        this.timezonesUrl = this.service + "timezones";
    },

    getProvinceFromPlace2: function (countryCode, a1, name) {
        if (a1 == null) {
            a1 = "";
        }
        var url = this.provinceFromPlace2Url + "&c=" + countryCode;
        url += "&a1=" + a1 + "&n=" + name;
        return this.httpClientService.get(url);
    },

    getProvinceFromPlace: function (countryCode, name) {
        var url = this.provinceFromPlaceUrl + "&c=" + countryCode;
        url += "&n=" + name;
        return this.httpClientService.get(url);
    },

    getPlaces: function (countryCode, a1, name)/*: Observable < Place[] >*/ {
        if (a1 == null) {
            a1 = "";
        }
        var url = this.placesUrl + "&c=" + countryCode;
        url += "&a1=" + a1 + "&n=" + name;
        return this.httpClientService.get(url);
    },

    getPlaces2: function (countryCode, a1, a2, name)/*: Observable < Place[] >*/ {
        if (a1 == null) {
            a1 = "";
        }
        if (a2 == null) {
            a2 = "";
        }
        var url = this.places2Url + "&c=" + countryCode;
        url += "&a1=" + a1 + "&a2=" + a2 + "&n=" + name;
        return this.httpClientService.get(url);
    },

    getCountries: function ()/*: Observable < Country[] >*/ {
        return this.getFromCache(this.countriesUrl);
    },

    getFromCache: function (url) {
        return this.storageService.getCachedRequest(url).then(result => {
            if (!result) {
                return this.httpClientService.get(url).then(x => {
                    return this.storageService.cacheRequest(url, x).then(() => {
                        return x;
                    });
                });
            }
            return result;
        });
    },

    getProvinces: function (countryCode)/*: Observable < Province[] >*/ {
        return this.getFromCache(this.provincesUrl + "&c=" + countryCode);
    },

    getProvinces2: function (countryCode, a1)/*: Observable < Province[] >*/ {
        var url = this.provinces2Url + "&c=" + countryCode + "&a1=" + a1;
        return this.getFromCache(url);
    },

    getCountry: function (code)/*: Observable < Country >*/ {
        var url = this.provincesUrl + "&c=" + code;
        return this.httpClientService.get(url);
    },

    getGeoLocation: function (id/*: number*/)/*: Observable < GeoLocation >*/ {
        var url = this.geoLocationUrl + "?g=" + id;
        return this.httpClientService.get(url);
    },

    getTimezones: function ()/*: Observable < string[] >*/ {
        var url = this.timezonesUrl;
        return this.getFromCache(url);
    }
}

<ion-row   :class="rowClass">
    <ion-col size="8" v-if="!folder.isEditing" @click="select">
        <div v-html="folder.name" ></div>            
    </ion-col>
    <ion-col size="8" v-if="folder.isEditing">        
        <ion-input type="text" :maxlength="maxLength" class="form-control ion-input"  v-model="folder.name" ></ion-input>                                    
    </ion-col>                        
    <ion-col size="4">                                    
        <icon-button class="button ion-float-right" :title="labels.add" @click="add" icon="add" />
        <icon-button v-if="isEditable && !isEditing" class="button ion-float-right" :title="labels.edit" @click="update" icon="create"/>                                    
        <icon-button v-if="isEditable && isEditing" class="button ion-float-right" :title="labels.save" @click="endUpdate" icon="save"/>                                    
        <icon-button class="button ion-float-right" :title="labels.select" @click="select" icon="done"/> 
    </ion-col>           
</ion-row>
<ion-grid v-for="child in visibleChildren">
    <Folder :folder="child" :guidToExclude="guidToExclude" :labels="labels" @onSelect="doSelect"></Folder>
</ion-grid>
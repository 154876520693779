import { IonContent, IonInput, IonGrid, IonRow, IonCol, modalController } from '@ionic/vue';
import IconButton from '@/components/icon-button';
import DialogHeader from '@/components/dialog-header';
import FolderComponent from '@/popups/folderselect/folder';
//import {DateStruct} from '@/models/common'
import { TreeFolder} from '@/models/locationcombination'
import utilService from '@/services/utilService';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
//import Constants from '@/constants/constants';
import { addIcons } from 'ionicons';
import { checkmarkOutline as done, add, create, trash, save } from 'ionicons/icons';

export default {
    components: {
        IonContent, IonInput, IonGrid, IonRow, IonCol, DialogHeader, IconButton, FolderComponent
    },
    setup() {
        addIcons({            
            add, done, create, trash, save
        });
    },
    props: {
        folderGuid: null,
        readOnly: Boolean,
        title: null,
        guidToExclude: null
    },
    inject: ["folderService", "languageService"],
    data() {
        return {
            labels: {},
            maxLength: 32,
            folders: []
        }
    },
    computed: {
        isEditable: function () {
            return !this.readOnly;
        },
        visibleFolders: function () {
            var result = this.folders;
            if (this.guidToExclude) {
                result = result.filter(x => x.guid != this.guidToExclude);
            }
            return result;
        },
        dialogTitle: function () {
            return this.title ? this.title : this.labels.title;
        }
    },
    mounted() {
        this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.add = x);
        this.languageService.getText(EnumResourceStrings.Save).then(x => this.labels.save = x);
        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
        this.languageService.getText(EnumResourceStrings.SelectFolder).then(x => this.labels.title = x);
        this.languageService.getText(EnumResourceStrings.Select).then(x => this.labels.select = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.confirmDelete = x);
        this.loadFolders();
    },
    methods: {
        sortOnKeys: function (dict) {
            return utilService.sortDictionaryOnKeys(dict);
        },

        setItemData: function (folder) {
            folder.selected = (folder.guid == this.folderGuid);
            folder.isEditing = false;
            if (folder.children) {
                folder.children.forEach(child => {
                    this.setItemData(child);
                });
            }
        },

        loadFolders: function () {
            this.folderService.getFolderTree().then(folders => {
                folders = folders.map(folder => {
                    this.setItemData(folder);
                    return folder;
                });
                if (this.guidToExclude) { //select parent mode, also show root folder to allow unparenting a folder
                    var rootFolder = new TreeFolder();
                    rootFolder.name = 'NO PARENT';
                    rootFolder.guid = null;
                    rootFolder.children = folders ? folders : [];
                    this.folders = [rootFolder];
                } else {
                    this.folders = folders ? folders : [];
                }
            });
        },

        update: function (folder) {
            folder.isEditing = true;
        },
        add: function () {
            var folder = new TreeFolder();
            folder.isEditing = true;
            this.folders.push(folder);
        },
        removeItemData: function (folder) {
            //delete locationCombination.lookup;                    
            delete folder.isEditing;
            delete folder.selected;
            if (folder.children) {
                folder.children.forEach(child => {
                    this.removeItemData(child);
                });
            }
        },

        closeModal: function (folder) {
            console.log('closeModal', folder);
            var promises = [];
            if (folder)
            {
                var folders = this.folders.map(x => {
                    this.removeItemData(x);
                    return x;
                });
                if (this.guidToExclude && folders.length > 0) { //remove root folder
                    folders = folders[0].children;
                }
                promises.push(this.folderService.setFolderTree(folders));
            }
            Promise.all(promises).then(() => {
                modalController.dismiss(folder);
            });
        },

        select: function (folder) {
            this.closeModal(folder);
        }

    }
}
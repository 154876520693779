export class DegreeVM {
    constructor() {
        this._degree = null;
        this._minute = null;
        this._second = null;
        this._totalSeconds = null;
    }
    padValue(value) {
        value = value.toString();
        if (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    set degree(value) {
        this._degree = this.padValue(value);
    }

    get degree() {
        return this._degree;
    }
    get minute() {
        return this._minute;
    }
    set minute(value) {
        this._minute = this.padValue(value);
    }

    set second(value) {
        this._second = this.padValue(value);
    }

    get second() {
        return this._second;
    }

    set totalSeconds(value) {
        this._totalSeconds = value;
    }

    get totalSeconds() {
        return this._totalSeconds;
    }
}
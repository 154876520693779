import { DateStruct } from "./common";
//import { Location } from "./geo";
export class AspectTemplatePropertiesVM {
    constructor() {
        this.surfaceDeepMode = false; //: boolean;
        this.positiveDeepThreshold = null; //: number;
        this.negativeDeepThreshold = null; // number;
        this.positiveSurfaceThreshold = null; //number;
        this.negativeSurfaceThreshold = null;// number;
    }
}

export class AspectTemplate {
    constructor() {
        this.id = null; //: number;
        this.name = ''; //: string;
        this.description = ''; //: string;
        this.positiveThreshold = null; //number;
        this.negativeThreshold = null; //: number;
        this.multiplicationFactor = null; //: number;
        this.bits = 0; //: number;
        this.propertiesVm = null; //: AspectTemplatePropertiesVM;
    }
}

export class LuckFigureVM {
    constructor(totalWeight, surfaceWeight, deepWeight, danger, eventDate, eventLocation, birthLocation, birthDate) {
        this.totalWeight = totalWeight;
        this.totalSurfaceWeight = surfaceWeight;
        this.totalDeepWeight = deepWeight;
        this.totalWeightString = (Math.round(this.totalWeight * 100) / 100).toFixed(2);
        this.totalSurfaceWeightString = (Math.round(this.totalSurfaceWeight * 100) / 100).toFixed(2);
        this.totalDeepWeightString = (Math.round(this.totalDeepWeight * 100) / 100).toFixed(2);
        this.danger = danger;
        this.luckClass = '';
        this.deepLuckClass = '';
        this.surfaceLuckClass = '';
        this.eventDateStruct = new DateStruct();
        this.eventDateStruct.year = eventDate.year;
        this.eventDateStruct.month = eventDate.month;
        this.eventDateStruct.day = eventDate.dayOfMonth;
        this.eventDateStruct.hour = eventDate.hourOfDay;
        this.eventDateStruct.minute = eventDate.minute;
        this.eventDateStruct.second = eventDate.second;
        this.eventDateStruct.milliSecond = eventDate.milliSecond;
        this.eventDate = new Date(eventDate.year, eventDate.month, eventDate.dayOfMonth, eventDate.hourOfDay, eventDate.minute, eventDate.second, eventDate.milliSecond ? eventDate.milliSecond: 0);
        this.birthDate = new Date(birthDate.year, birthDate.month, birthDate.day, birthDate.hour, birthDate.minute, birthDate.second, birthDate.milliSecond);
        this.eventLocation = eventLocation;
        this.birthLocation = birthLocation;
    }
    
    
}
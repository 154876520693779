import { IonGrid, IonRow, IonCol, IonInput, IonButtons, IonButton} from '@ionic/vue';
import IconButton from '@/components/icon-button';
import {LocationCombination} from '@/models/locationcombination';
import Event from '@/components/event';
import Pager from '@/components/pager';
import GridColumn from '@/components/grid-column';
//import luckService from '@/services/luckservice';
import { addIcons } from 'ionicons';
import { trash} from 'ionicons/icons';
import folder from '/public/assets/icon/folder-closed.svg';
import {SortCombination, SortDirection, SortEnum} from '@/models/sortcombination';
import utilService from '@/services/utilService';

export default {

    components: {
        IonGrid, IonRow, IonCol, IonInput, IconButton, Event, IonButtons, IonButton, Pager, GridColumn
    },
    setup() {
        addIcons({
            trash,
            folder
        });
    },
    props: {
        labels: {},
        folders: null,
        selectedEventKey: null,
        locationCombination: null,
        aspectTemplate: null,
        outerLimit: null,
        events: null,
        optionItemWidth: null
    },
    emits: ['onSelect', "onDelete", "onChange", "onFoldersChanged", "onSelectFolder"],
    inject: ["luckService", "aspectTemplateService", "locationCombinationService"],
    data()
    {
        return   {
            maxLength: 64,
            pageSize: 10,
            currentPage: 0,
            _oldRow: null,
            SortEnum: SortEnum,
            _sortCombination: SortCombination,
            _events: null,
            sorted: false,
            aspectTemplateHash: ''
                    //eventsArray: []
        }
    },
    watch: {
        locationCombination: function (/*val, oldVal*/) { //undo action changes data in row, oldrow needs to be synced as well
            console.log('locationCombination set');
            this._oldRow.copy(this.locationCombination);
        },
        events: function () {
            this.init();
        }
    },

    mounted() {
        this._oldRow = new LocationCombination();
        this._oldRow.copy(this.locationCombination);
        this.init();
    },
    computed: {
        sortCombination: function () {
            return this._sortCombination;
        },
        isEditing: function () {
            return this.locationCombination.isEditing;
        },
        surfaceDeepMode: function () {
            return this.aspectTemplate && this.aspectTemplate.propertiesVm && this.aspectTemplate.propertiesVm.surfaceDeepMode;
        },
        pageCount: function () {
            return Math.ceil(this.itemCount / this.pageSize);
        },
        itemCount: function () {
            return Object.keys(this.events).length;
        },
        eventsArray: function () {
            var result = [];
            console.log('eventsArray');
            if (this.selectedEventKey && !this.sorted) {
                var selectedEvent = this._events[this.selectedEventKey];
                if (selectedEvent) {
                    result.push({eventDate: this.selectedEventKey, profileEvent: selectedEvent});
                }
            }
            Object.keys(this._events).forEach(eventDate => {
                if (this.sorted || !this.selectedEventKey || eventDate != this.selectedEventKey) {
                    result.push({eventDate: eventDate, profileEvent: this._events[eventDate]});
                }
            });
            return result;
        },
        currentPageItems: function () {
            if (this._events) {
                var items = this.eventsArray;
                var start = this.currentPage * this.pageSize;
                var end = Math.min(items.length, start + this.pageSize);
                var result = [];
                for (var i = start; i < end; i++) {
                    result.push(items[i]);
                }
                return result;
            }
        }
    },
    methods: {
        init: function () {
            this._events = this.events;
            this._sortCombination = new SortCombination(SortEnum.Date);
            if (this.aspectTemplate && !this.aspectTemplateHash) {
                this.aspectTemplateHash = this.aspectTemplate.id + '_' + (this.aspectTemplate.changedOn ? this.aspectTemplate.changedOn : '-');
            }
            this.setPage(0);
        },
        getLuckFigure: function (event, callback) {
            var eventDate = event.date;
            var locationCombinationHash = this.locationCombinationService.getLocationCombinationHash(this.locationCombination);
            if (!event.luckFigure 
                    || (event.luckFigure.aspectTemplateHash != this.aspectTemplateHash)                    
                    || (event.luckFigure.locationCombinationHash != locationCombinationHash)
                    ) {
                this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, [], eventDate, false, false,
                        this.locationCombination.birthLocation, this.locationCombination.eventLocation, this.locationCombination.birthDate).then(x => {
                    var luckFigure = x.luckFigures[0];
                    luckFigure.aspectTemplateHash = this.aspectTemplateHash;
                    luckFigure.surfaceDeepMode = this.surfaceDeepMode;
                    luckFigure.locationCombinationHash = locationCombinationHash;
                    callback(luckFigure);
                });
            }
        },
        onChangeEvent: function (eventDate) {
            console.log('change', this.events[eventDate]);
            if (JSON.stringify(this.events[eventDate]) != JSON.stringify(this._oldRow.events[eventDate])) {
                this.$emit('onChange', this._oldRow);
                this._oldRow.copy(this.locationCombination);
            }
        },
        select: function (event) {
            this.$emit('onSelect', event.date);
        },
        doDelete: function (key) {
            this.$emit('onDelete', key);
            //after  : this.setPage(0);
        },
        foldersChanged: function () {
            this.$emit('onFoldersChanged');
        },
        selectFolder: function (event) {
            console.log('selectFolder');
            this.$emit("onSelectFolder", event)
        },
        getPagerClass: function (page) {
            return  'pager' + ((page == this.currentPage) ? ' selected' : '');
        },
        setPage: function (page) {
            this.currentPage = page;
        },
        sort: function (eventSortEnum) {
            var eventSort = this._sortCombination;
            if (eventSort.sortEnum == eventSortEnum) {
                eventSort.sortDirection = eventSort.sortDirection == SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
            } else {
                eventSort.sortEnum = eventSortEnum;
                eventSort.sortDirection = SortDirection.Asc;
            }

            this._sortCombination = eventSort;
//            console.log('events', this._events, this._sortCombination.sortEnum);
//            console.log('folders', this.folders);
            switch (this._sortCombination.sortEnum) {
                case SortEnum.Date:
                    this._events = utilService.sortDictionaryOnKeys(this._events, this._sortCombination.sortDirection == SortDirection.Desc);
                    break;
                case SortEnum.Folder:
                    this._events = utilService.sortDictionaryOnStringValueField(this._events, 'folderGuid', this._sortCombination.sortDirection == SortDirection.Desc, this.folders, 'name');
                    break;
                case SortEnum.Reference:
                default:
                    this._events = utilService.sortDictionaryOnStringValueField(this._events, 'remark', this._sortCombination.sortDirection == SortDirection.Desc);
                    break;
            }
            this.sorted = true;
            this.setPage(0);
        },
    }
}
//import { stringify } from 'querystring';
//import { CaptchaCheck, DateStruct } from './common';
//import { LicenseCombinationVM } from './license';
//import utilService from '@/services/utilService';

export class CodeNameVM {
    constructor() {
        this.code = '';
        this.name = '';
    }
}

export class Country extends CodeNameVM {
    constructor() {
        super();
        this.needsAdmin2 = false;
    }
}

export class Place {
    constructor() {
        this.id = null;
        this.name = '';
        this.orgName = '';
        this.duplicateCount = 0;
    }

}

export class Province extends CodeNameVM {

}

export class GeoLocation {
    constructor() {
        this.latitude = null;
        this.longitude = 0;
        this.timeZone = '';
    }

}

export class Location extends GeoLocation {
    constructor() {
        super();
        this.country = null;
        this.subCountry = null;
        this.province = null;
        this.place = null;
        this.name = '';
        this.geoId = null;
        this.dateStruct = null;
    }

}


export class AspectCombination {

    constructor(birthPlace, //: Location,
            currentPlace, //: Location,
            birthDate, //: DateStruct,
            aspectTemplateId, //: number,
            outerLimit, //: number,
            targetDate, //: DateStruct,
            licenseCombination, //: LicenseCombinationVM
            intervalEnum,
            useAverages,
            roundMinutes
            ) {
        this.birthLocation = new GeoLocation();
        this.birthLocation.latitude = birthPlace.latitude;
        this.birthLocation.longitude = birthPlace.longitude;
        this.birthLocation.timeZone = birthPlace.timeZone;
        this.birthLocation.name = birthPlace.name;
        this.birthLocation.description = birthPlace.description;
        this.currentLocation = new GeoLocation();
        this.currentLocation.latitude = currentPlace.latitude;
        this.currentLocation.longitude = currentPlace.longitude;
        this.currentLocation.timeZone = currentPlace.timeZone;
        this.currentLocation.name = currentPlace.name;
        this.currentLocation.description = currentPlace.description;
        
        this.aspectTemplateId = aspectTemplateId;
        this.birthDate = birthDate;
        this.targetDate = targetDate;
        this.outerLimit = outerLimit;
        this.licenseCombination = licenseCombination;
        //TODO 20220418 REFACTORING: remove these next 3 lines soon... here for a little while for backwards compatibility
        if (intervalEnum) {
            intervalEnum = intervalEnum.substr(0, 1).toUpperCase() + intervalEnum.substr(1);
        }        
        this.intervalEnum = intervalEnum;
        this.useAverages = useAverages;
        this.roundMinutes = roundMinutes;
    }
}

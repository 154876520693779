<div v-if="luckFigure">
    <span v-if="!surfaceDeepMode" >
        <span :class="luckFigure.luckClass" v-html="luckFigure.totalWeightString"></span>&nbsp;
        <ion-icon name="thumbsup" v-if="luckFigure.luckClass =='positive'"></ion-icon>
        <ion-icon name="thumbsdown" v-if="luckFigure.luckClass =='negative'"></ion-icon>
    </span>

    <span v-if="surfaceDeepMode" >
        <span :class="luckFigure.surfaceLuckClass">
            {{luckFigure.totalSurfaceWeightString}}
            <ion-icon name="thumbsup" v-if="luckFigure.surfaceLuckClass && luckFigure.surfaceLuckClass =='positive'"></ion-icon>
            <ion-icon name="thumbsdown" v-if="luckFigure.surfaceLuckClass && luckFigure.surfaceLuckClass =='negative'"></ion-icon>
        </span>
        ***
        <span :class="luckFigure.deepLuckClass">
            {{luckFigure.totalDeepWeightString}}
            <ion-icon name="thumbsup" v-if="luckFigure.deepLuckClass && luckFigure.deepLuckClass =='positive'"></ion-icon>
            <ion-icon name="thumbsdown" v-if="luckFigure.deepLuckClass && luckFigure.deepLuckClass =='negative'"></ion-icon>
        </span>
    </span>

</div>                                    

<dialog-header :title="labels.title" :nameParam="nameParam" :showSave="isValid" @onSave="save" @onClose="close" ></dialog-header>
<ion-content class="dialog">
    <ion-grid class="main">
        <ion-row>
            <ion-col size-md="3" size-xs="12">
                <label v-html='labels.country'></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">
                <div v-if="showUsedCountries" class="picklist">
                    <div v-for="row in usedCountries" @click="selectUsedCountry(row.country.code)" :class="row.class" v-html="row.country.name"></div>
                </div>
                <select class="form-control" v-model="selectedCountryCode" @change="onSelectCountry()" :compareWith="compareCountry">
                    <option v-for="country in countries" :value="country.code" :selected="country.code == selectedCountryCode" :text="country.name"></option>
                </select>
            </ion-col>
        </ion-row>
        <ion-row v-if="this.subCountries != null && this.subCountries.length > 0">
            <ion-col size-md="3" size-xs="12"></ion-col>
            <ion-col size-md="9" size-xs="12">
                <div v-if="showUsedSubCountries" class="picklist"> 
                    <div v-for="row in usedSubCountries" @click="selectSubUsedCountry(row.subcountry.code)" :class="row.class" v-html="row.subcountry.name"></div>            
                </div>
                <select class="form-control" v-model="location.subCountry" @change="onSelectSubCountry()"  :compareWith="compareSubCountry">
                    <option v-for="country in subCountries" :value="country" :text="country.name"></option>
                </select>
            </ion-col>
        </ion-row>  
        <ion-row v-if="this.provinces != null && this.provinces.length > 0">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.state"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">                        
                <div v-if="showUsedProvinces" class="picklist">
                    <div v-for="row in usedProvinces" @click="selectUsedProvince(row.province.code)" :class="row.class" v-html="row.province.name"></div>            
                </div>
                <select  class="form-control" v-model="location.province" @change="onSelectProvince()" ref='province' :compareWith="compareProvince">
                    <option v-for="province in provinces" :value="province" :text="province.name"></option>
                </select>
            </ion-col>
        </ion-row>        
        <ion-row v-if="this.provinces != null && this.provinces.length > 0">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.place"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">
                <ion-input type="text" v-model="place" class="form-control ion-input" @keydown="onPlaceKeyDown($event)" @keyup="onPlaceKeyUp($event)" @ionChange="onPlaceChange"/>

                <div id='lstPlace' v-if="this.places != null && this.places.length > 0 && !this.isPlaceSelected" class="picklist">
                    <div v-for="row in enrichedPlaces" @click="selectPlace(row.place)" :class="row.class">
                        <span>
                            {{row.place.name}} <span v-if="row.place.orgName">({{row.place.orgName}})</span>
                        </span>
                    </div>                
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12" v-if="location.geoId == null">
                <input type="checkbox" v-model="manual" class="form-control"/>                
                {{labels.manual}}
            </ion-col>
        </ion-row>
        <ion-row v-if="this.manual || (this.location.geoId != null && this.location.timeZone != null)">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.timezone"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">        
                <select class="form-control" v-model="location.timeZone" :compareWith="compareTimezone">
                    <option v-for="timezone in timezones" :value="timezone" :text="timezone">                        
                    </option>
                </select>
            </ion-col>
        </ion-row>
        <ion-row v-if="this.manual || (this.location.geoId  != null && this.location.latitude != null)">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.latitude"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">        
                <ion-input type="text" class="form-control ion-input" v-model="location.latitude"></ion-input>
            </ion-col>
        </ion-row>
        <ion-row v-if="this.manual || (this.location.geoId != null && this.location.longitude != null)">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.longitude"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">        
                <ion-input type="text" class="form-control ion-input" v-model="location.longitude"></ion-input>
            </ion-col>
        </ion-row>
        <ion-row v-if="this.manual || (this.location.geoId != null && this.location.longitude != null)">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.name"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">        
                <ion-input type="text" v-model="location.name" class="form-control ion-input" />
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/vue';

import { EnumResourceStrings } from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';
import { saveOutline as save, closeOutline as close } from 'ionicons/icons';

export default {
    components: {
        IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon
    },
    setup() {
        addIcons({
            save,
            close
        });
    },

    props: {
        title: String,
        nameParam: String,
        showSave: Boolean,
        hideClose: Boolean
    },
    data() {
        return {            
            labels:{},
            showClose:true
        }
    },
    inject: ["languageService"],
    mounted() {
        this.languageService.getText(EnumResourceStrings.Save).then(x => this.labels.save = x);
        this.languageService.getText(EnumResourceStrings.Close).then(x => this.labels.close = x);
        
        this.showClose = !this.hideClose;
    },
    emits: ['onSave', "onClose"],
    methods: {
        save: function () {
            this.$emit('onSave');
        },
        close: function () {
            this.$emit('onClose');
        }
    }
}

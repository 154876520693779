import { createRouter, createWebHistory } from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';
//import configService from '@/services/configService';
import Tabs from '../tabs'
import ProfilesPage from '@/profiles-page';
//import {LocationCombinationEnum} from '@/constants/enums';
import configService from '@/services/configService';

var routes = [];
if (configService.isWwwApp()) {
    routes.push({
        path: '/',
        redirect: '/information'
    },
    {
        path: '/information',
        component: () => import('@/information-page')
    }
    );
}

var appRoutes =
        {
            path: '/tabs/',
            component: Tabs,
            children: [
                {
                    path: '',
                    redirect: '/tabs/dashboard'
                },
                {
                    path: 'dashboard',
//                        component: () => import('@/' + configService.getConfigSetting('dashboard'))
                    component: () => import('@/dashboard-page')
                },
                {
                    path: 'calendar',
                    component: () => import('@/calendar-page'),
                    props: {
                    }
                },
                {
                    path: 'events',
                    component: () => import('@/events-page'),
                    props: {
                    }
                },
                {
                    path: 'profiles',
                    component: ProfilesPage,
                    props: {
                        //locationCombinationEnum: LocationCombinationEnum.Profile
                    }
                },
                {
                    path: 'settings',
                    component: () => import('@/settings-page')
                },
                {
                    path: 'settings/loggedin',
                    component: () => import('@/settings-page'),
                    props: {
                        state: 'loggedin'
                    }
                },
                {
                    path: 'settings/loggedout',
                    component: () => import('@/settings-page'),
                    props: {
                        state: 'loggedout'
                    }
                },
                {
                    path: 'information',
                    component: () => import('@/information-page')
                }
            ]
        };

if (configService.isMainApp()) {
    routes.push({
        path: '/',
        redirect: '/tabs/dashboard'
    });
    routes.push(appRoutes);
}


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

export const StorageEnum = {
    Birth: 'birth',
    Event: 'event',
    AspectTemplate: 'aspectTemplate',
    AuthenticationKey: "authenticationKey",
    LicenseKey: "licenseKey",
    TimeUnitsInterval: "timeUnits_interval",
    TimeUnitsAdvanced: "timeUnits_advanced",
    TimeUnits: "timeUnits",
    TempDate: "tempDate",
    IntervalEnum: "intervalenum",
    CalendarIntervalEnum: "calendarIntervalenum",
    ShowHistoryButton: "showHistoryButton",
    DarkMode: "darkMode",
    Folders: "Folders",
    DrillToSelectedEvent: 'drillToSelectedEvent',
    CalendarTimeUnits: "calendarTimeUnits",
    ShowLuckFiguresOnProfileScreen: "showLuckFiguresOnProfileScreen",
    StoreUserProfilesInDb: "StoreUserProfilesInDb",
    AuthHeader: 'Lic',
}

export const IntervalEnum = {
    Day: "Day",
    Hour: "Hour",
    Minute: "Minute",
    Week: "Week",
    Month: "Month",
    TwoHours: "TwoHours",
    Year: "Year",
    MultiYear: "MultiYear"
}

export const SignatureTargetEnum = {
    ChartX_ChartY: "ChartX_ChartY",
    ChartY_ChartY: "ChartY_ChartY",
    ChartX_ChartX: "ChartX_ChartX",
    ChartY_ChartX: "ChartY_ChartX"
}

export const SignatureTargetEnumValues = {
    ChartX_ChartY: 1,
    ChartY_ChartY: 2,
    ChartX_ChartX: 3,
    ChartY_ChartX: 4
}


export const SignatureTypeEnum = {

}

export const SignatureTypeEnumValues = {

}

export const SignatureTypeEnumValuesReverse = {

}

export const CardEnum = {

}

import { modalController, IonToggle } from '@ionic/vue';
import {StorageEnum} from '@/constants/enums';
//import { addIcons } from 'ionicons';
//import { trash, create} from 'ionicons/icons';
import TimeComponent from '@/popups/time';

export default {

    components: {
        IonToggle
    },
//    setup() {
//        addIcons({
//            trash, create
//        });
//    },
    props: {
        labels: {},
        eventDate: null
    },
    data()
    {
        return   {
            overrideToggle: false
        }
    },
    watch: {
        eventDate: function (val) {
            this.setToggleChecked(val != null, true);
        }
    },
    emits: ["onChange", "onDelete"],
    inject: ["storageService", "locationCombinationService", "utilService"],
//    mounted: function () {
        //  this.editMode = this.isEditing;
        //this.date = this.eventDate;
//    },

    computed: {
        eventTime: function () {
            return this.eventDate ? this.formatDate(this.eventDate) : this.labels.eventTime;
        },
        hasEventTime: function () {
            return this.eventDate != null;
        }
    },
    methods: {

        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        clearEventTime: function () {
            this.storageService.setDate(StorageEnum.Event, null).then(() => {
                this.$emit('onDelete');
            });
        },

        toggleEventTime: function () {
            if (!this.overrideToggle) {
                if (this.hasEventTime) {
                    this.clearEventTime();
                } else {
                    this.editEventTime();
                }
            }
        },

        eventTimeClicked: function () {
            if (this.eventDate) {
                this.editEventTime();
            } else {
                this.setToggleChecked(true, false);
            }
        },

        setToggleChecked: function (checked, overrideToggle) {
            if (overrideToggle) {
                this.overrideToggle = true;
            }
            this.$refs.toggle.$el.checked = checked;
            if (overrideToggle) {
                this.overrideToggle = false;
            }
        },
        editEventTime: function () {

            return this.storageService.getDate(StorageEnum.Event).then(date => {
                return modalController.create({
                    showBackdrop: true,
                    cssClass: 'transparent-modal eventtime-modal',
                    component: TimeComponent,
                    componentProps: {
                        type: StorageEnum.Event,
                        date: date
                    }
                }).then(popup => {
                    popup.present();
                    popup.onDidDismiss().then(x => {
                        var time = x.data;
                        var timeSelected = time != null;
                        //console.log('editEventTime', time);
                        if (timeSelected) {
                            this.$emit('onChange', time);
                        } else if (!this.eventDate) {
                            this.setToggleChecked(false, true);
                            this.clearEventTime();
                        }

                    });
                });
            });
        },
    },

}

//import { StorageEnum } from '@/constants/enums';

export default  {

    init(storageService, httpClientService) {
        this.service = "userprofile/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },
    getHistory: function () {
        return this.storageService.getLicenseKeys().then(data => {
            if (data.licenseKey) {
                return this.httpClientService.post(this.service + "list", {}).then(x => {
                    return x.succeeded ? x.userProfiles : [];
                });
            } else {
                return Promise.resolve(true);
            }
        });
    },
    update: function (data) {
        //return this.storageService.get(StorageEnum.StoreUserProfilesInDb).then(x => {
        //var storeUserProfilesInDb = x;
        var storeUserProfilesInDb = true;
        return this.storageService.getLicenseKeys().then(licenseData => {
            if (licenseData.licenseKey && storeUserProfilesInDb) {
                var userProfile = {
                    content: JSON.stringify(data),
                    //remark: remark,
                    licenseKey: licenseData.licenseKey//,
                            //authenticationKey: licenseData.authenticationKey
                }
                return this.httpClientService.post(this.service + "update", userProfile);
            } else {
                return Promise.resolve(true);
            }
        });
        //});
    },
    deleteProfile: function (id) {
        return this.storageService.getLicenseKeys().then(licenseData => {
            if (licenseData.authenticationKey && licenseData.licenseKey) {
                var userProfile = {
                    id: id,
                    //licenseKey: licenseData.licenseKey//,
                    //authenticationKey: licenseData.authenticationKey
                }
                return this.httpClientService.post(this.service + "delete", userProfile);
            } else {
                return Promise.resolve(true);
            }
        });
    },
    getUserProfile: function (id) {
        return this.storageService.getLicenseKeys().then(licenseData => {
            if (licenseData.authenticationKey && licenseData.licenseKey) {
                var userProfile = {
                    id: id,
                    //licenseKey: licenseData.licenseKey//,
                    //authenticationKey: licenseData.authenticationKey
                }
                return this.httpClientService.post(this.service, userProfile);
            } else {
                return Promise.resolve(true);
            }
        });

    },
    getLatest: function () {
        var userProfile = {
            //licenseKey: licenseData.licenseKey//,
            //authenticationKey: licenseData.authenticationKey
        }
        return this.httpClientService.post(this.service + "latest", userProfile);
    },
    updateRemark: function (id, remark) {
        //return this.storageService.get(StorageEnum.StoreUserProfilesInDb).then(x => {
        var storeUserProfilesInDb = true;
        return this.storageService.getLicenseKeys().then(licenseData => {
            if (licenseData.licenseKey && storeUserProfilesInDb) {
                var userProfile = {
                    id: id,
                    remark: remark,
                    licenseKey: licenseData.licenseKey//,
                            //    authenticationKey: licenseData.authenticationKey
                }
                return this.httpClientService.post(this.service + "updateremark", userProfile);
            } else {
                return Promise.resolve(true);
            }
        });
        //});
    },
}

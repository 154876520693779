<ion-page>
    <page-header :loadTrigger="loadTrigger" :showUndo="showUndo" :undoButtonText="undoButtonText" @onUndo="undo" 
                 :beforeShowHistoryCallback="beforeShowHistoryCallback" :afterImportProfiles="load"
                 :showHistoryMenu="storeUserProfilesInDb"></page-header>
    <ion-content>                
        <ion-row class="nomargin">
            <ion-col class="nomargin">
                <ion-buttons class="nomargin buttons" color='tertiary'>      
                    <ion-button class="button btn width60"  @click="goUp" v-if="selectedLocationCombination">
                        <ion-icon  name="arrowup" size="large" class="minwidth50  panelicon" ></ion-icon>
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="goPrevious" v-if="profileCount > 1 && detailMode">
                        <ion-icon  name="left" size="large" class="minwidth50 panelicon"></ion-icon>
                    </ion-button>                    
                    <ion-button class="button btn btn-large center fullwidth"  @click="go" v-if="showGo">
                        <ion-icon  name="go" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn btn-large center fullwidth" :class="figuresSelected"  @click="setShowFigures" v-if="showLuckFiguresAndEventButtons">
                        <ion-icon  name="luckbutton" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn btn-large center fullwidth"  :class="eventsSelected" @click="setShowEvents" v-if="showLuckFiguresAndEventButtons">
                        <ion-icon  name="events" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="add" v-if="!detailMode">
                        <ion-icon  name="plus" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="editSelected" v-if="detailMode && !editing" :title="labels.strEdit">
                        <ion-icon  name="create" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="lockSelected" v-if="detailMode && editing" :title="labels.strReadOnly">
                        <ion-icon  name="lockclosed" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"   @click="goNext" v-if="profileCount > 1 && detailMode">
                        <ion-icon  name="right" size="large" class="minwidth50  panelicon" ></ion-icon>
                    </ion-button>                                    
                </ion-buttons>
            </ion-col>
        </ion-row>        
        <ion-row v-if="selectedLocationCombination">
            <profile :profileCount="profileCount" 
                     :countAllowed="countAllowed"
                     :locationCombination="selectedProfile" :selectedEvent="selectedEvent"
                     :labels="labels" :folders="folders"
                     :locationCombinations="locationCombinations"
                     :optionItemWidth="optionItemWidth" :aspectTemplate="aspectTemplate"  :outerLimit="outerLimit"
                     :isReadOnly="false" :showEvents="showEvents" :showFigures="showFigures"
                     @onAddToHistory="addToHistory" 
                     @onDelete="doDelete"
                     @onSelect="select"
                     @onCopy="copy"                          
                     @onSelectFolder="selectFolder"
                     @onFoldersChanged="loadFolders"
                     @onUpdateDate="updateDate"
                     @onUpdateLocation="updateLocation"                                          
                     >
            </profile>                
        </ion-row>
        <ion-row v-for="row in locationCombinations"  v-if="!selectedLocationCombination">
            <profile :profileCount="profileCount" :countAllowed="countAllowed" 
                     :locationCombination="row" :selectedEvent="selectedEvent"  :labels="labels" :folders="folders" 
                     :optionItemWidth="optionItemWidth" :aspectTemplate="aspectTemplate" :outerLimit="outerLimit"
                     :isReadOnly="true" :luckFigure="getLuckFigure(row)"
                     @onAddToHistory="addToHistory" 
                     @onDelete="doDelete"
                     @onSelect="select"
                     @onCopy="copy"                          
                     @onSelectFolder="selectFolder"
                     @onFoldersChanged="loadFolders"
                     @onUpdateDate="updateDate"
                     @onUpdateLocation="updateLocation"
                     >

            </profile>                
        </ion-row>                   
    </ion-content>
</ion-page>
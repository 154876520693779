import {StorageEnum} from '@/constants/enums';

export default  {
    service: "aspecttemplate/",
    currentAspectTemplate: null,

    init: function (storageService, licenseService, httpClientService) {
        this.storageService = storageService;
        this.licenseService = licenseService;
        this.httpClientService = httpClientService;
    },

    getAspectTemplates: function () {
        return this.httpClientService.get(this.service + "list");
    },
    getAspectTemplate: function (id) {
        return this.httpClientService.get(this.service + id);
    },
    clearCurrentAspectTemplate: function () {
        this.currentAspectTemplate = null;
    },
    getCurrentAspectTemplateHash: function () {
        var result = '';
        if (this.currentAspectTemplate) {
            result += this.currentAspectTemplate.id + '_' + (this.currentAspectTemplate.changedOn ? this.currentAspectTemplate.changedOn : '-');
        }
        return result;
    },
    getCurrentAspectTemplate: function () {
        var promises = [];
        if (!this.currentAspectTemplate) {
            promises.push(this.storageService.get(StorageEnum.AspectTemplate).then(aspectTemplateId => {
                return this.licenseService.getLicense().then(x => {
                    var aspectTemplateIds = x.license.aspectTemplateIds;
                    return this.getAspectTemplates().then(x => {
                        //make sure only aspecttemplates are used that have not been removed serverside
                        var aspectTemplates = aspectTemplateIds.map(aspectTemplateId => x.find(y => y.id == aspectTemplateId)).filter(exists => exists);
                        if (aspectTemplateId == null || aspectTemplateIds.indexOf(aspectTemplateId) < 0) {
                            aspectTemplateId = aspectTemplates[0].id;
                            this.storageService.set(StorageEnum.AspectTemplate, aspectTemplateId);
                        }
                        return this.getAspectTemplate(aspectTemplateId);
                    });
                });
            }).then(aspectTemplate => {
                console.log('aspectTemplate.changedOn', aspectTemplate.changedOn);                
                this.currentAspectTemplate = aspectTemplate;                
                return aspectTemplate;
            }));
        }
        return Promise.all(promises).then(() => {
            return new Promise((resolve/*, reject*/) => {
                resolve(this.currentAspectTemplate);
            });
        })
    }

}
import { IonButtons, IonButton, IonGrid, IonRow, IonItem, IonCol, IonIcon } from '@ionic/vue';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {StorageEnum, IntervalEnum} from '@/constants/enums';
import {TimeUnit, DateStruct} from '@/models/common';

import LuckFigures from '@/dashboard-page/luckfigures';
import { stopwatch as go, addCircle as arrowright, caretUpOutline as arrowup, removeCircle as arrowleft   } from 'ionicons/icons';
import { addIcons } from 'ionicons';


export default {
    setup() {
        addIcons({
            arrowleft,
            arrowright,
            arrowup,
            go
        });
    },
    components: {
        IonButtons,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonIcon,
        IonItem,
        LuckFigures
    },
    inject: ["storageService", "locationCombinationService", "languageService", "aspectTemplateService", "luckService", "licenseService", "utilService"],
    props: {
        isReadyParam: false,
        useAverages: false,
        locationCombination: null,
        eventDateStructParam: null,
        roundMinutes: false
    },
    watch: {
        isReadyParam: function (val) {
            if (val) {
                this.getLuckFigure();
            }
        }
    },

    data() {//needed for 2-way and late binding
        return{
            IntervalEnum: IntervalEnum,
            labels: {},
            aspectTemplates: [],
            aspectTemplate: null,
            outerLimit: 1.333333333,
            luckFigures: [],
            //maxConcurrentEvents: 0,
            timeUnits: [],
            timeUnitsArray: [],
            eventDateStructArray: [],
            stepInterval: new TimeUnit(),
            intervalEnum: IntervalEnum,
            eventDateStruct: new DateStruct(),
            showHistoryButton: false,
            optionItemWidth: null,
            initialized: false
        }
    },

    computed: {
        isReady: function () {
            console.log('isReady', this.isReadyParam);
            return this.isReadyParam && this.aspectTemplate;
        },
        aspectTemplateName: function () {
            return this.aspectTemplate ? this.aspectTemplate.name : '';
        },
        surfaceDeepMode: function () {
            return this.aspectTemplate && this.aspectTemplate.propertiesVm && this.aspectTemplate.propertiesVm.surfaceDeepMode;
        },
        luckFigure: function () {
            return this.luckFigures && this.luckFigures.length > 0 ? this.luckFigures[0] : null;
        },
        currentTimeUnits: function () {
            return this.timeUnitsArray.length > 0 ? this.timeUnitsArray[this.timeUnitsArray.length - 1] : this.timeUnits;
        },
        previousTimeUnits: function () {
            return this.timeUnitsArray.length > 1 ? this.timeUnitsArray[this.timeUnitsArray.length - 2] :
                    this.timeUnitsArray.length > 0 ? this.timeUnits : null;
        },
        currentEventDateStruct: function () {
            return this.eventDateStructArray.length > 0 ? this.eventDateStructArray[this.eventDateStructArray.length - 1] : this.eventDateStruct;
        },
        previousEventDateStruct: function () {
            return this.eventDateStructArray.length > 1 ? this.eventDateStructArray[this.eventDateStructArray.length - 2] :
                    this.eventDateStructArray.length > 0 ? this.eventDateStruct : null;
        },

        stepIntervalSet: function () {
            return this.stepInterval && (this.stepInterval.day || this.stepInterval.hour || this.stepInterval.minute);
        }
    },

    mounted() {
        this.languageService.getText(EnumResourceStrings.LuckFigure).then(x => this.labels.strLuckFigure = x);
        this.languageService.getText(EnumResourceStrings.LuckFigures).then(x => this.labels.strLuckFigures = x);
        this.languageService.getText(EnumResourceStrings.GetLuckFigures).then(x => this.labels.strGO = x);
        this.languageService.getText(EnumResourceStrings.Back).then(x => this.labels.strBack = x);
        this.languageService.getText(EnumResourceStrings.Days).then(x => this.labels.strDays = x);
        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
        this.init();
    },

    ionViewDidEnter() {
//        console.log('ionViewDidEnter');
        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.init();
        }

    },
    methods: {
        init: function () {
            var promises = [];

            promises.push(this.licenseService.getLicense().then(x => {
                this.outerLimit = x.license.propertiesVm.aspectSecondaryDegreeAsDouble;
            }));
            promises.push(this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                //console.log('aspectTemplate', aspectTemplate);
                this.aspectTemplate = aspectTemplate;
            }));
            promises.push(this.storageService.get(StorageEnum.IntervalEnum).then(x => {
                this.intervalEnum = x;
                var promises2 = [];
                if (!this.intervalEnum) {
                    this.intervalEnum = IntervalEnum.Minute;
                    promises2.push(this.storageService.set(StorageEnum.IntervalEnum, this.intervalEnum));
                }
                this.timeUnits = this.luckService.generateTimeUnitsForInterval(this.intervalEnum);
                promises2.push(this.storageService.set(StorageEnum.TimeUnits, this.timeUnits));
                return Promise.all(promises2);

            }));
            return Promise.all(promises).then(() => {
                this.initialized = true;
            })
        },

        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        clear: function () {
            this.luckFigures = [];
        },
        setOptionsWidth: function () {
            var width = this.$el.offsetWidth;
            //IonItemOption expandable doesn't work (yet), so pass required width along
            if (width) {
                if (width > 500) {
                    width /= 2;
                }
                width -= 50;
            }
            this.optionItemWidth = width;
            //console.log('luckfigurepanel width', this.$el.offsetWidth);
        },
        getLuckFigure: function () {
            this.childLuckFigure = null;
            this.childFutureLuckFigures = null;

            this.setOptionsWidth();
            var promises = [];
            if (!this.initialized) {
                promises.push(this.init());
            }
            promises.push(this.storageService.get(StorageEnum.ShowHistoryButton).then(x => {
                this.showHistoryButton = x;
            }));

            var timeUnits = [];
            promises.push(this.storageService.get(StorageEnum.TimeUnits).then(x => {
                if (x) {
                    timeUnits = x;
                }
            }));

            Promise.all(promises).then(() => {
                this.timeUnits = timeUnits;
                if (this.timeUnits && this.timeUnits.length > 0) {
                    this.stepInterval = this.timeUnits[this.timeUnits.length - 1];
//                    console.log('step', this.stepInterval);
                }
                this.timeUnitsArray = [];
                this.timeUnitsArray.push(timeUnits);
                this.eventDateStructArray = [];
                //birthLocation, eventLocation, birthDate

                console.log('eventDateStructParam', this.eventDateStructParam ? this.eventDateStructParam.year : null);
                return this.doGetLuckFigures(null, this.eventDateStructParam && this.eventDateStructParam.year ? this.eventDateStructParam : null).then(x => {
                    this.luckFigures = x.luckFigures;
                    this.eventDateStruct = new DateStruct();
                    this.eventDateStruct.loadFromDate(this.luckFigure.eventDate, true);
                    this.eventDateStructArray.push(this.eventDateStruct);
                });

            });
        },

        doGetLuckFigures: function (currentTimeUnits, currentEventDateStruct) {
            var birthLocation = this.locationCombination ? this.locationCombination.birthLocation : null;
            var eventLocation = this.locationCombination ? this.locationCombination.eventLocation : null;
            var birthDate = this.locationCombination ? this.locationCombination.birthDate : null;
            //console.log('getLuckFigure', this.aspectTemplate, this.outerLimit, currentTimeUnits, currentEventDateStruct, birthLocation, birthDate, eventLocation);
            return this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, currentTimeUnits, currentEventDateStruct, 
                this.useAverages, this.roundMinutes, birthLocation, eventLocation, birthDate);
        },

        loadLuckFigures(dateStruct, timeUnits) {
            if (timeUnits || dateStruct) {
                this.timeUnitsArray.push(timeUnits || this.currentTimeUnits);
                this.eventDateStructArray.push(dateStruct || this.currentEventDateStruct);
            }
            return this.doGetLuckFigures(this.currentTimeUnits, this.currentEventDateStruct).then(x => {
                this.luckFigures = x.luckFigures;
            });
        },

        drillDown: function (luckFigure) {
            this.loadLuckFigures(luckFigure.eventDateStruct, null, false, false);//                          
        },
        popTimeUnit: function (pop) {
            if (pop) {
                this.timeUnitsArray.pop();
                this.eventDateStructArray.pop();
            } else {
                var firstTime = this.timeUnitsArray[0];
                this.timeUnitsArray = [];
                this.timeUnitsArray.push(firstTime);
                var firstDate = this.eventDateStructArray[0];
                this.eventDateStructArray = [];
                this.eventDateStructArray.push(firstDate);
            }
            var timeUnits = this.currentTimeUnits;
            if (timeUnits && timeUnits.length > 0) {

                if (timeUnits[0].day == 1) {
                    this.intervalEnum = IntervalEnum.Day;
                } else if (timeUnits[0].day == 7) {
                    this.intervalEnum = IntervalEnum.Week;
                } else if (timeUnits[0].hour == 1) {
                    this.intervalEnum = IntervalEnum.Hour;
                } else if (timeUnits[0].minute == 1) {
                    this.intervalEnum = IntervalEnum.Minute;
                }
            }
            return this.storageService.set(StorageEnum.IntervalEnum, this.intervalEnum).then(() => {
                return this.doGetLuckFigures(this.currentTimeUnits, this.currentEventDateStruct).then(x => {
                    this.luckFigures = x.luckFigures;
                });
            });
        },
        goNext: function () {
            this.move(true);
        },
        goPrevious: function () {
            this.move(false);
        },
        move: function (next) {
            var dateStruct = this.luckService.getNextStepDate(this.stepInterval, this.luckFigure.eventDate, next);
            if (this.previousEventDateStruct && dateStruct.equals(this.previousEventDateStruct)) {
                return this.popTimeUnit(true);
            } else {
                return this.loadLuckFigures(dateStruct, null, false, false)
            }
        },
        setInterval: function (intervalEnum) {
            //var date = this.luckFigure.eventDate;
            var promises = [];

            promises.push(this.storageService.set(StorageEnum.IntervalEnum, intervalEnum));
            this.intervalEnum = intervalEnum;
            var timeUnits = this.luckService.generateTimeUnitsForInterval(intervalEnum);
            promises.push(this.storageService.set(StorageEnum.TimeUnits, timeUnits));

            var previousTimeUnits = this.previousTimeUnits;

            return Promise.all(promises).then(() => {
                var promises2 = [];
                if (previousTimeUnits && timeUnits
                        && ((!previousTimeUnits[0].day && !timeUnits[0].day) || (previousTimeUnits[0].day == timeUnits[0].day))
                        && ((!previousTimeUnits[0].hour && !timeUnits[0].hour) || (previousTimeUnits[0].hour == timeUnits[0].hour))
                        && ((!previousTimeUnits[0].minute && !timeUnits[0].minute) || (previousTimeUnits[0].minute == timeUnits[0].minute))
                        ) {
                    promises2.push(this.popTimeUnit(true));
                } else {
                    promises2.push(this.loadLuckFigures(this.luckFigure.eventDateStruct, timeUnits, true, true));//              
                }
                return Promise.all(promises2).then(() => {
                    this.stepInterval = timeUnits[timeUnits.length - 1];
                });
            });


        },
        getIntervalButtonClass: function (intervalEnum) {
            var result = 'button btn width25pct';
            if (intervalEnum == this.intervalEnum) {
                result += ' selected';
            }
            return result;
        }

    },
}
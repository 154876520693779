<dialog-header :title="labels.title" :nameParam="nameParam" :showSave="isValid" @onSave="doSave" @onClose="close" ></dialog-header>
<ion-content class="dialog">
    <ion-grid class="main">
        <ion-row>
            <ion-col size="3" size-xs="12" v-html="labels.date"></ion-col>
            <ion-col>            
                <input v-model="dateStruct.year" class="form-control input-4ch number" maxlength="4" type='text'
                       :placeholder="labels.YYYY" @keyup="keyUpYear(dateStruct.year,'year',3000)"  />
                <select v-model="dateStruct.month" class="form-control months inline" @change="setDayMax">
                    <option v-for="index in monthsCounter" :value="index" v-html="months[index]"></option>
                </select>
                <input v-model="dateStruct.day"  class="form-control input-2ch number" maxlength="2" 
                       :placeholder="labels.DD" @keyup="checkIsNumber(dateStruct.day, 'day',maxDay)"  />        
            </ion-col>        
        </ion-row>
        <ion-row>
            <ion-col size="3" size-xs="12" v-html="labels.time">            
            </ion-col>
            <ion-col>
                <input v-model="dateStruct.hour" class="form-control input-2ch number" maxlength="2" type='text'
                       :placeholder="labels.HH" @keyup="checkIsNumber(dateStruct.hour,'hour',23)"  />
                <input v-model="dateStruct.minute" class="form-control input-2ch number" maxlength="2" :placeholder="labels.MM"
                       @keyup="checkIsNumber(dateStruct.minute,'minute',59)" />
                <input v-model="dateStruct.second" class="form-control input-2ch number" maxlength="2" :placeholder="labels.SS"
                       @keyup="checkIsNumber(dateStruct.second,'second',59)"/>
                <input v-model="dateStruct.milliSecond" class="form-control input-3ch number" maxlength="3"
                       :placeholder="labels.Ms" @keyup="checkIsNumber(dateStruct.milliSecond,'milliSecond',1000)"/>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
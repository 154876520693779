<div class="pointer" v-if="luckFigure">
    <ion-item lines="none">       
        <ion-grid>
            <ion-row>
                <ion-col size="10" @click="onClick">
                    <div>
                        <luck-figure :luckFigure="luckFigure" 
                                     :surfaceDeepMode="surfaceDeepMode"
                                     ></luck-figure>                    

                    </div>                                    
                    <div v-html="eventDate"></div>    
                </ion-col>
                <ion-col size="2" @click="showEdit">                                                                                                
                    <ion-icon v-if="profileEvent" name="star" size="small" :class="starClass"></ion-icon>
                    <ion-icon v-if="!profileEvent" name="staroutline" size="small" :class="starClass"></ion-icon>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>
</div>
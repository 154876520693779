import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
//import configService from './services/configService.js';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/global.scss';

import {StorageEnum} from '@/constants/enums';
import storageService from '@/services/storageService';
import luckService from '@/services/luckService';
import languageService from '@/services/languageService';
import dialogService from '@/services/dialogService';
import cryptService from '@/services/cryptService';
import importService from '@/services/importService';
import exportService from '@/services/exportService';
import locationCombinationService from '@/services/locationCombinationService';
import locationService from '@/services/locationService';
import locationCombinationEditService from '@/services/locationCombinationEditService';
import aspectTemplateService from '@/services/aspectTemplateService';
import eventService from '@/services/eventService';
import folderService from '@/services/folderService';
import utilService from '@/services/utilService';
import configService from '@/services/configService';
import geoService from '@/services/geoService';
import eventFolderService from '@/services/eventFolderService';
import calendarService from '@/services/calendarService';
import contentService from '@/services/contentService';
import licenseService from '@/services/licenseService';
import transportService from '@/services/transportService';
import userProfileService from '@/services/userProfileService';
import settingsService from '@/services/settingsService';
import httpClientService from '@/services/httpclientService';
import captchaService from '@/services/captchaService';
import authenticationService from '@/services/authenticationService';
import enumService from '@/services/enumService';
import aspectCombinationService from '@/services/aspectCombinationService';
import starcheckService from '@/services/starcheckService';

const app = createApp(App)
        .use(IonicVue)
        .use(router);

storageService.createStorage().then(() => {
    var language = configService.selectedLanguage;
    httpClientService.init(storageService);
    httpClientService.authenticate().then(() => {
        enumService.init(httpClientService).then(() => {
            licenseService.init(storageService, httpClientService).then(() => {
                aspectCombinationService.init(storageService, licenseService);
                languageService.init(configService.selectedLanguage, storageService, httpClientService);

                authenticationService.init(storageService, httpClientService);
                locationCombinationService.init(storageService, httpClientService, utilService);
                locationService.init(locationCombinationService, utilService);
                locationCombinationEditService.init(locationService, utilService);
                folderService.init(storageService, utilService);
                eventFolderService.init();
                eventService.init(folderService, eventFolderService, languageService, locationCombinationService, utilService);

                dialogService.init(languageService);
                geoService.init(language, storageService, httpClientService);
                cryptService.init(storageService, httpClientService);
                calendarService.init(locationCombinationService);
                importService.init(storageService, cryptService, locationCombinationService,
                        languageService, dialogService, folderService, licenseService, utilService);
                aspectTemplateService.init(storageService, licenseService, httpClientService);
                exportService.init(storageService, locationCombinationService, cryptService, folderService, utilService);
                contentService.init(language, httpClientService);
                transportService.init(storageService, httpClientService, utilService);
                userProfileService.init(storageService, httpClientService);
                settingsService.init(storageService, httpClientService);
                captchaService.init(httpClientService);

                luckService.init(httpClientService, aspectTemplateService, utilService, locationService, aspectCombinationService);
                starcheckService.init(httpClientService, aspectTemplateService, locationService, aspectCombinationService);
                //upgrade script...
//            locationCombinationService.fixAllEventsGmtOffset(null, false, true);//FN20220605 you can remove this line by 2023
                app.provide("configService", configService);
                app.provide("storageService", storageService);
                app.provide("languageService", languageService);
                app.provide("locationCombinationService", locationCombinationService);
                app.provide("locationService", locationService);
                app.provide("locationCombinationEditService", locationCombinationEditService);
                app.provide("eventService", eventService);
                app.provide("folderService", folderService);
                app.provide("dialogService", dialogService);
                app.provide("geoService", geoService);
                app.provide("cryptService", cryptService);
                app.provide("importService", importService);
                app.provide("aspectTemplateService", aspectTemplateService);
                app.provide("exportService", exportService);
                app.provide("eventFolderService", eventFolderService);
                app.provide("contentService", contentService);
                app.provide("calendarService", calendarService);
                app.provide("luckService", luckService);
                app.provide("licenseService", licenseService);
                app.provide("transportService", transportService);
                app.provide("userProfileService", userProfileService);
                app.provide("settingsService", settingsService);
                app.provide("utilService", utilService);
                app.provide("captchaService", captchaService);
                app.provide("authenticationService", authenticationService);
                app.provide("httpClientService", httpClientService);
                app.provide("aspectCombinationService", aspectCombinationService);
                app.provide("starcheckService", starcheckService);

                const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
                storageService.get(StorageEnum.DarkMode).then(x => {
                    var dark = prefersDark.matches;
                    if (x != null) {
                        dark = x;
                    }
                    utilService.toggleDarkTheme(dark);
                });

// Listen for changes to the prefers-color-scheme media query
                prefersDark.addListener((mediaQuery) => utilService.toggleDarkTheme(mediaQuery.matches));

                router.isReady().then(() => {
                    app.mount('#app');
                });
            });
        });
    });

//  console.log('env.name',process.env.VUE_APP_name);
//  console.log('env.name',configService.getConfigSetting('name'));
//  console.log('ApiRootUrl',configService.getApiRootUrl());
});
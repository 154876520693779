import {StorageEnum} from '@/constants/enums';
import Constants from '@/constants/constants';

export default  {
    service: "license/",
    init: function (storageService, httpClientService) {
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.license = null;
        return this.getLicense();
    },
    usingDefaultLicense: function () {
        return this.storageService.get(StorageEnum.LicenseKey).then(x => {
            return !x || x == Constants.DEFAULT_LICENSE_KEY;
        });
    },
    clear:function(){
        this.license = null;
    },
    getLicense: function () {
        var promises = [];
        if (!this.license) {
            promises.push(this.httpClientService.post(this.service, {}).then(license => {
                this.license = license;
                return license;
            }));
        }
        return Promise.all(promises).then(() => {
            return new Promise((resolve/*, reject*/) => {
                resolve(this.license);
            });
        });
    }
}

import { IonInput, IonGrid, IonRow, IonCol} from '@ionic/vue';
import IconButton from '@/components/icon-button';
//import {DateStruct} from '@/models/common'
import { TreeFolder} from '@/models/locationcombination'

export default {
    name: 'Folder',
    components: {
        IonInput,
        IonGrid,
        IonRow,
        IonCol,
        IconButton
    },
    
    props: {
        folder: null,
        readOnly: Boolean,
        labels: {},
        guidToExclude: null
    },
    emits: ["onSelect"],
    data() {
        return {
            maxLength: 32
        }
    },
    computed: {
        isEditing: function () {
            return this.folder.isEditing;
        },
        isEditable: function () {
            return !this.readOnly;
        },
        rowClass: function () {
            var selected = this.folder.selected;
            return selected
                    //&& this.selectedDate.second == other.second
                    ? 'selected row border-default pointer' : 'row pointer';
        },
        visibleChildren: function () {
            var result = this.folder.children;
            if (this.guidToExclude) {
                result = result.filter(x => x.guid != this.guidToExclude);
            }
            return result;
        }
    },
    mounted() {
        //       console.log('mounted folder');
//        this.languageService.getText(EnumResourceStrings.Add).then(x => this.strAdd = x);
//        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.strEdit = x);
//        this.languageService.getText(EnumResourceStrings.Select).then(x => this.strSelect = x);
    },
    methods: {
        endUpdate: function () {
            this.folder.isEditing = false;
        },
        update: function () {
            this.folder.isEditing = true;
        },
        add: function () {
            var folder = new TreeFolder();
            folder.parentGuid = this.folder.guid;
            folder.isEditing = true;
            this.folder.children.push(folder);
        },
        doSelect: function (folder) {
            this.$emit('onSelect', folder);
        },
        select: function () {
            this.doSelect(this.folder);
        }
    }
}
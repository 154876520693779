<ion-list :class='rowClass' class="pad0">
    <ion-row v-if="locationCombination.isEditing">                        
        <ion-col size-md="10" size-xs="8">                                    
            <ion-input type="text" class="form-control ion-input" :maxlength="maxLength" v-model="locationCombination.name" :placeholder="labels.strDisplayName"/>
        </ion-col>
        <ion-col size-md="2" size-xs="4">
            <icon-button class="button ion-float-right" :title="labels.strDelete" @click="doDelete" icon="trash" v-if="isReadOnly"/>                                                            
            <icon-button class="button ion-float-right" :title="labels.strCopy" @click="copy" v-if="showCopy" icon="copy" />                                                                            
        </ion-col>                        
    </ion-row>
    <ion-row v-if="!locationCombination.isEditing">                            
        <ion-col size="8" @click="select" class="pointer" >
            <b v-html="locationCombination.name"></b>
        </ion-col>        
        <ion-col size="4">                                                              
            <icon-button class="button absolute-right" :title="labels.strDelete"  @click="doDelete" icon="trash" v-if="isReadOnly"/>                                            
        </ion-col>                        
    </ion-row>
    <ion-row :class='birthRowClass'>                
        <ion-col size="5" @click="select" class="location">
            <div v-if="locationCombination.isEditing" v-html="labels.strBirth"></div>
            <span v-if="hasBirthLocation" class="pointer spanname" :title="locationCombination.birthLocation.name" v-html="birthLocation"></span>              
            <div v-if="!hasBirthLocation" v-html="labels.strBirthPlace"></div>              
        </ion-col>            
        <ion-col size="1">
            <icon-button class="button ion-float-right" :title="labels.strEdit" v-if="locationCombination.isEditing" @click="updateLocation(StorageEnum.Birth, locationCombination.birthLocation, locationCombination.name)" icon="create" />                                                            
        </ion-col>
        <ion-col size="5" @click="select" class="date">
            <div v-if="locationCombination.birthDate != null" class="pointer" v-html="birthDate"></div>                                          
            <div v-if="locationCombination.birthDate == null" v-html="labels.strBirthTime"></div>                                          
        </ion-col>
        <ion-col size="1">
            <icon-button class="button ion-float-right" :title="labels.strEdit" v-if="locationCombination.isEditing" @click="updateDate(StorageEnum.Birth ,locationCombination.birthDate, locationCombination.name)" icon="create" />                                                            
        </ion-col>                
    </ion-row>    
    <ion-row :class='eventRowClass'>
        <ion-col size="5" @click="select" class="location">
            <div v-if="locationCombination.isEditing" v-html="labels.strEvent"></div>
            <span v-if="hasEventLocation" class="pointer spanname" :title="locationCombination.eventLocation.name" v-html="eventLocation"></span>              
            <div v-if="!hasEventLocation" v-html="labels.strEventPlace"></div>              
        </ion-col>            
        <ion-col size="1">                                    
            <icon-button class="button ion-float-right" :title="labels.strEdit" v-if="locationCombination.isEditing" @click="updateLocation(StorageEnum.Event,locationCombination.eventLocation, locationCombination.name)" icon="create" />                                                            
        </ion-col>                                                       
        <ion-col size="5" v-if="luckFigure && isReadOnly">
            <profile-luckfigure :luckFigure="luckFigure"></profile-luckfigure>
        </ion-col>
        <ion-col size="5" v-if="!isReadOnly && !locationCombination.isEditing && showEventTime">
            <event-time-edit
                :eventDate="eventDate"
                :labels="eventTimeLabels"
                @onChange="onChangeEventTime"
                @onDelete="clearEventTime"                
                ></event-time-edit>
        </ion-col>
    </ion-row>
</ion-list>
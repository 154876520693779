import {StorageEnum} from '@/constants/enums';
import {TreeFolder} from '@/models/locationcombination';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export default  {
    init: function (storageService, utilService) {
        this.storageService = storageService;
        this.utilService = utilService;
    },

    setFolders: function (folders) {
        //MAKE THEM A FLAT ARRAY
        console.log('setFolders', folders);
        if (folders) {
            folders.forEach(folder => {
                folder.events = this.utilService.sortDictionaryOnKeys(folder.events, false); //sort by date to avoid growing history infinitely
                delete folder.children;
            });
        }
        folders = folders.filter(x => x.name);
        return this.storageService.set(StorageEnum.Folders, folders);
    },

    flattenTree: function (treeFolder) {
        var result = [];
        result.push(treeFolder.folder);
        if (treeFolder.children) {
            treeFolder.children.filter(x => x.name).forEach(child => {
//                if(!child.parentGuid){
                child.events = this.utilService.sortDictionaryOnKeys(child.events, false); //sort by date to avoid growing history infinitely
                child.parentGuid = treeFolder.guid;
                //              }                
                result = result.concat(this.flattenTree(child));
            });
        }
        return result;
    },

    sortTreeFolders: function (treeFolders, parentCode) {
        var i = 0;
        treeFolders.forEach(treeFolder => {
            var firstLetter = parseInt(Math.floor(i / 26));
            var secondLetter = i % 26;
            treeFolder.lookupCode = parentCode + alphabet.substring(firstLetter, firstLetter + 1) + alphabet.substring(secondLetter, secondLetter + 1);
            treeFolder.children = this.sortTreeFolders(treeFolder.children, treeFolder.lookupCode);
            i++;
        });

        return treeFolders.sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase();
        });
    },

    setFolderTree: function (treeFolders) {
        var result = [];
        if (treeFolders) {
            treeFolders = this.sortTreeFolders(treeFolders, '');
            treeFolders.forEach(treeFolder => {
                result = result.concat(this.flattenTree(treeFolder));
            });
        }
        return this.setFolders(result);
    },

    getFolders: function () {
        return this.storageService.get(StorageEnum.Folders);
    },

    getFolderTree: function () {
        return this.getAllChildFolders(null);
    },

    getFolderDictionary: function () {
        return this.storageService.get(StorageEnum.Folders).then(x => {
            var folderLookup = {};
            if (x) {
                x.forEach(folder => {
                    folderLookup[folder.guid] = folder;
                });
            }
            return folderLookup;
        });
    },

    getAllChildFolders: function (folderGuid) {
        return this.storageService.get(StorageEnum.Folders).then(x => {
            var folderLookup = {};
            if (x) {
                x.forEach(folder => {
                    folderLookup[folder.guid] = new TreeFolder(folder);
                });
            }
            return this.getChildFolders(folderLookup, folderGuid);
        });
    },
    getChildFolders: function (folderLookup, folderGuid) {
        var result = [];
        for (var guid in folderLookup) {
            var folder = folderLookup[guid];

            //folder.children = [];
            if (folder.parentGuid == folderGuid) {
                folder.children = this.getChildFolders(folderLookup, folder.guid);
                result.push(folder);
            }
        }
        return result;
    },

    findFolder: function (folders, guid) {
        var result = null;
        var count = folders.length;
        for (var i = 0; i < count; i++) {
            var folder = folders[i];
            if (folder.guid == guid) {
                result = folder;
            }
            if (!result && folder.children) {
                result = this.findFolder(folder.children, guid);
            }
            if (result)
                break;
        }
        return result;
    }
}
import Constants from '@/constants/constants';

export default {
    init: function () {
        this.expandedFolders = {};
        this.editingFolders = {};
        this.expandedEvents = {};
        this.folderLookup = {};
        this.activeLookupCode = null;
    },

    clearState: function () {
        this.expandedFolders = {};
        this.expandedEvents = {};
        this.editingFolders = {};
    },
    syncState: function (eventFolders) {
        eventFolders.forEach(folder => { //between requests state needs to be preserved.
            folder.isExpanded = folder.guid in this.expandedFolders;
            folder.isEventsExpanded = folder.guid in this.expandedEvents;
            folder.isEditing = folder.guid in this.editingFolders;
            this.folderLookup[folder.guid] = folder;
            if (folder.children) {
                this.syncState(folder.children);
            }
        });
    },
    getFolder: function (guid) {
        return this.folderLookup[guid];
    },
    isExpanded: function (folder) {
        return folder.isExpanded || folder.lookupCode == this.activeLookupCode;
    },
    isEventsExpanded: function (folder) {
        return folder.isEventsExpanded || folder.lookupCode == this.activeLookupCode;
    },
    isEditing: function (folder) {
        return folder.isEditing;
    },
    isUncategorizedFolder: function (folder) {
        return folder.guid == Constants.GUID_NULL;
    },
    isFolderEditing: function (folder) {
        return this.isEditing(folder) && !this.isUncategorizedFolder(folder);
    },

    expand: function (folder, onlyFolder, setLookupCode) {
        if (folder) {
            this.expandedFolders[folder.guid] = true;
            if (!onlyFolder) {
                this.expandedEvents[folder.guid] = true;
            }
            folder.isExpanded = true;
            folder.isEventsExpanded = !onlyFolder;

            if (folder.parentGuid) {
                var parentFolder = this.getFolder(folder.parentGuid);
                if (!parentFolder.isExpanded) {
                    this.expand(parentFolder, false);
                }
            }
            if (setLookupCode) {
                this.activeLookupCode = folder.lookupCode;
            }
        }
    },

    contract: function (folder, setLookupCode) {
        if (!setLookupCode && !folder.isEventsExpanded && folder.isExpanded && Object.keys(folder.events).length > 0) { //three way state
            folder.isEventsExpanded = true;
        } else {
            if (setLookupCode) {
                this.activeLookupCode = folder.lookupCode ? folder.lookupCode.substring(0, folder.lookupCode.length - 2) : null;
            }
            delete this.expandedFolders[folder.guid];
            delete this.expandedEvents[folder.guid];
            folder.isExpanded = false;
            folder.isEventsExpanded = false;
            folder.isEditing = false;
        }
//        if(folder.parentGuid){
//            this.activeLookupCode = this.folderLookup[folder.parentGuid].lookupCode;
//        }
        //this.activeLookupCode = null; //or parent lookupcode
    },
    expandAll: function () {
        //this.folderLookup[folder.guid] = folder;
        console.log('expandAll', this.activeLookupCode);
        var lookupCodeLength = this.activeLookupCode ? this.activeLookupCode.length : 0;
        for (var guid in this.folderLookup) {
            //console.log(this.folderLookup[guid].lookupCode);
            if (this.folderLookup[guid].lookupCode.length <= lookupCodeLength + 2) {
                this.expandedFolders[guid] = true;
                this.expandedEvents[guid] = true;
            }
        }

    },
    contractAll: function () {
        this.expandedFolders = {};
        this.expandedEvents = {};
        this.folderLookup = {};
    },
    toggle: function (folder) {
        if (folder.isExpanded) {
            this.contract(folder);
        } else {
            this.expand(folder);
        }
    },

    setReadOnly: function (folder) {
        folder.isEditing = false;
        delete this.editingFolders[folder.guid];
    },

    edit: function (folder) {
        folder.isEditing = true;
        this.editingFolders[folder.guid] = true;
    }

}
<ion-grid :class='rowClass' class="pad0">              
    <ion-row>
        <ion-col class="pad0">
            <profile-edit :locationCombination="locationCombination" :isReadOnly="isReadOnly" :labels="labels"
                          :luckFigure="luckFigure" :eventDate="eventDate"
                          :locationCombinations="locationCombinations"
                          :countAllowed="countAllowed"
                          :showEventTime="showFigures"                          
                          @onChangeEventTime="onChangeEventTime" @onClearEventTime="clearEventTime"
                          @onUpdateLocation="updateLocation" 
                          @onUpdateDate="updateDate"
                          @onDelete="doDelete"
                          @onSelect="doSelect" 
                          @onCopy="doCopy"                          
                          ></profile-edit>
        </ion-col>
    </ion-row>
    <ion-row v-if="!isReadOnly && showEvents && locationCombination.events && Object.keys(locationCombination.events).length > 0" >                            
        <ion-col class="pad0">
            <events :locationCombination="locationCombination" :events="locationCombination.events" :labels="eventLabels" :folders="folders"  
                    :selectedEventKey="selectedEventKey" 
                    :optionItemWidth="optionItemWidth" :outerLimit="outerLimit" :aspectTemplate="aspectTemplate"
                    @onDelete="deleteEvent" 
                    @onSelect="selectEvent" 
                    @onChange="addToHistory" 
                    @onFoldersChanged="foldersChanged"
                    @onSelectFolder="selectFolder"
                    >                                
            </events>                            
        </ion-col>
    </ion-row>
    <ion-row :style='figuresStyle'>
        <ion-col class="pad0">            
            <luck-figure-panel ref="luckFigurePanel" :isReadyParam="isReadyForLuck" :useAverages="false" v-if="!isReadOnly" 
                               :locationCombination="locationCombination" :eventDateStructParam="eventDate">

            </luck-figure-panel>
        </ion-col>
    </ion-row>
</ion-grid>          

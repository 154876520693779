import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonIcon, modalController } from '@ionic/vue';
import LuckFigure from '@/components/luck-figure';
import IconButton from '@/components/icon-button';
import FolderSelectComponent from '@/popups/folderselect';
import EventEditComponent from '@/popups/event-edit';

export default {

    components: {
        IonGrid, IonRow, IonCol, IonInput, IonItem, IonIcon, IconButton, LuckFigure
    },
    setup() {

    },
    props: {
        labels: {},
        eventDate: null,
        event: null,
        folders: null,
        selected: false,
        optionItemWidth: null,
        eventFolderView: false,
        locationCombinationName: '',
        locationCombination: null
    },
    data()
    {
        return   {
            maxLength: 64,
            loadingLuckFigure: false
                    //eventsArray: []
        }
    },
    emits: ["onSelect", "onDelete", "onChange", "onFoldersChanged", "onSelectProfile", "onSelectFolder","onGetLuckFigure"],
    inject: ["utilService", "locationCombinationService"],
    mounted: function () {
        this.getLuckFigure();
    },
    computed: {        
        remark: function () {
            return this.event.remark ? this.event.remark : '&nbsp;';
        },
        class: function () {
            return "pointer border-default event" + (this.selected ? " selected " : "");
        },
        luckFigure: function () {
            return this.event.luckFigure;
        },
        surfaceDeepMode: function () {
            return this.luckFigure ? this.luckFigure.surfaceDeepMode : false;
        },
        folderName: function () {
            var folderGuid = this.event.folderGuid
            var result = null;
            if (folderGuid && this.folders) {
                var folder = this.folders[folderGuid];
                if (folder) {
                    result = folder.name;
                }
            }
            return result;
        },
        date: function () {
            return this.formatDate(this.getDate(this.event.date))
        },        
        optionStyle: function () {
            //this.optionItemWidth = this.$el.offsetWidth;
            //console.log('this.optionItemWidth', this.optionItemWidth);                        
            return this.optionItemWidth ? 'width:' + this.optionItemWidth + 'px;min-width:' + this.optionItemWidth + 'px;' : 'min-width:180px;';
        }
    },
    methods: {
        edit: function () {
            this.showEdit();
        },
        formatDate: function (value) {
            return this.utilService.formatDate(value);
        },
        getDate: function (dateStruct)/*: Date */ {
            return this.utilService.getDateFromDateStruct(dateStruct);
        },
        onChangeEvent: function () {
            //console.log('change');            
            this.$emit('onChange', this.eventDate);
        },
        select: function () {
            this.$emit('onSelect', this.event);
        },
        selectProfile: function () {
            this.$emit('onSelectProfile', this.event);
        },
        selectFolder: function () {
            this.$emit('onSelectFolder', this.event);
        },
        doDelete: function () {
            this.$emit('onDelete', this.eventDate);
        },
        getLuckFigure: function () {
            //console.log('getLuckFigure', this.event, this.locationCombination);
//            if (!this.luckFigure) {
                this.$emit('onGetLuckFigure', this.event, this.setLuckFigure);
  //          }
        },
        setLuckFigure: function (luckFigure) {
            delete luckFigure.birthDate;
            delete luckFigure.birthLocation;
            delete luckFigure.eventDate;
            delete luckFigure.eventLocation;
            delete luckFigure.eventDateStruct;
            console.log('luckFigure', luckFigure);
            this.event.luckFigure = luckFigure;
        },
        updateFolder: function () {
//            console.log('updateFolder', this.event.folderGuid);
            modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: FolderSelectComponent,
                componentProps: {
                    folderGuid: this.event.folderGuid,
                    readOnly: this.eventFolderView
                }
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    //console.log('ondiddismiss', x.data, date, locationCombination.birthDate);
                    if (x.data) {
                        if ((this.event.folderGuid == null || this.event.folderGuid != x.data.guid)) {
                            this.event.folderGuid = x.data.guid;
                            this.onChangeEvent(this.eventDate);
                        }
                        this.$emit('onFoldersChanged');
                    }
                });
            });
        },
        foldersChanged: function () {
            this.$emit('onFoldersChanged');
        },

        save: function (changed) {
            console.log('save');
            if (changed) {
                this.onChangeEvent();
            }
        },
        showEdit: function () {
            var folderGuid = this.event.folderGuid;
            var eventJson = JSON.stringify(this.event);
            var profileName = this.locationCombinationName;
            var promises = [];
            var locationCombination = this.locationCombination;
            if (!locationCombination) {
                promises.push(this.locationCombinationService.getLocationCombination(this.event.locationCombinationGuid).then(x => {
                    locationCombination = x;
                }));
            }
            Promise.all(promises).then(() => {
//                console.log('locationCombinationGuid', this.event.locationCombinationGuid, locationCombination);

                modalController.create({
                    showBackdrop: true,
                    cssClass: 'transparent-modal',
                    component: EventEditComponent,
                    componentProps: {
                        event: this.event,
                        locationCombination: locationCombination,
                        eventFolderView: this.eventFolderView,
                        folderNameParam: this.folderName,
                        profileNameParam: profileName,
                        dateParam: this.date,
                        showDelete: true
                    }
                }).then(popup => {
                    popup.present();
                    popup.onDidDismiss().then(x => {
                        var event = x.data;
                        var role = x.role;
                        if (role === 'deleteEvent') {
                            this.doDelete();
                        } else if (event) {
                            if (eventJson != JSON.stringify(event)) {
                                this.onChangeEvent(this.eventDate);
                            }
                            if (event.folderGuid != folderGuid) {
                                this.$emit('onFoldersChanged');
                            }
                        }
                    });
                });
            });
        }
    },

}
<ion-header>
    <title-toolbar  :loadTrigger='loadTrigger'
                    :undoButtonText="undoButtonText" :showUndo="showUndo" 
                    :beforeShowHistoryCallback="beforeShowHistoryCallback" 
                    :afterShowHistoryCallback="afterShowHistoryCallback" 
                    :afterImportProfiles="afterImportProfiles"                    
                    :showHistoryMenu="showHistoryMenu" 
                    @onUndo="undo"
                    ></title-toolbar>    
    
</ion-header>
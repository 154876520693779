<ion-row v-if="luckFigures">
    <ion-col class="luckFigureresult" v-for="(item,index) in enrichedLuckFigures" size-xs="12" size-md="6">
        <luck-figure :parent="parent" :luckFigure="item.luckFigure" :aspectTemplate="aspectTemplate" 
        :outerLimit="outerLimit" :startTimeUnit="index > 0 ? timeUnits[index - 1]: null" :endTimeUnit="(index < timeUnits.length)?timeUnits[index]:null" 
        :optionItemWidth="optionItemWidth"  :profileEvent="item.profileEvent" :folders="folders"
        @onChange="loadEvents" @onFoldersChanged="foldersChanged"
        ></luck-figure>
    </ion-col>
</ion-row>            


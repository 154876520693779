import { IonContent, IonInput, IonGrid, IonRow, IonCol, modalController } from '@ionic/vue';
import { Province, Location } from '../../models/geo';
import DialogHeader from '@/components/dialog-header';
import { EnumResourceStrings } from '@/constants/enumresourcestrings';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageEnum } from '@/constants/enums';


export default {

    components: {
        IonContent, IonInput, IonGrid, IonRow, IonCol, DialogHeader
    },
    props: {
        type: StorageEnum,
        locationParam: null,
        locationsParam: null,
        nameParam: null
    },
    data()
    {
        return   {
            labels: {},
            subject: /*Subject < string > = */ new Subject(),
            isPlaceSelected: false,
            _isProvinceManuallySelected: false,
            _currentSearch: "",
            _someProvincesHidden: false,
            //@ViewChild('lstProvince', { static: false }) provinceElement: ElementRef,  
            place: null,
            subCountry: null,
            countries: [], //Country[],
            subCountries: [], // Province[],
            places: null, //Place[] = null,
            timezones: null, // string[] = null,
            provinces: null, // Province[] = null,
            provincesBackup: null, //Province[] = null,
            needsAdmin2: false,
            manual: false,
            locationType: null,
            location: new Location(),
            selectedCountryCode: null,
            navigationSubscription: null,
            keyDownWorking: false
        }
    },
    inject: ["storageService", "locationCombinationService", "languageService", "dialogService", "geoService", "locationService", "utilService"],
    mounted() {
        this.subject.pipe(
                debounceTime(200)
                ).subscribe(searchTextValue => {
            this.searchPlace(searchTextValue);
        });

        this.locationType = this.type;
        this.location = this.utilService.cloneObject(this.locationParam);

        this.languageService.getText(this.locationType == StorageEnum.Birth ? EnumResourceStrings.BirthPlace : EnumResourceStrings.CurrentLocation).then(x => {
            this.labels.title = x;
        });
        this.languageService.getText(EnumResourceStrings.Country).then(x => this.labels.country = x);
        this.languageService.getText(EnumResourceStrings.StateOrProvince).then(x => this.labels.state = x);
        this.languageService.getText(EnumResourceStrings.Place).then(x => this.labels.place = x);
        this.languageService.getText(EnumResourceStrings.TimeZone).then(x => this.labels.timezone = x);
        this.languageService.getText(EnumResourceStrings.Latitude).then(x => this.labels.latitude = x);
        this.languageService.getText(EnumResourceStrings.Longitude).then(x => this.labels.longitude = x);
        this.languageService.getText(EnumResourceStrings.DisplayName).then(x => this.labels.name = x);
        this.languageService.getText(EnumResourceStrings.Manual).then(x => this.labels.manual = x);

        if (this.location && this.location.country) {
            this.selectedCountryCode = this.location.country.code;
        }
        this.getCountries();
        this.loadProvinces();
        this.getTimezones();
        this.place = this.location.place == null ? this.location.name : this.location.place.name;
    },
    computed: {
        isValid: function ()/*: boolean*/ {
            return this.location != null
                    && this.utilService.isValidNumber(this.location.longitude)
                    && this.utilService.isValidNumber(this.location.latitude)
                    && this.utilService.isValidString(this.location.timeZone)
                    && this.utilService.isValidString(this.location.name)
                    && (this.utilService.isValidNumber(this.location.geoId)
                            || this.manual
                            );
        },
        showUsedCountries: function () {
            return !this.location.country;
        },
        showUsedProvinces: function () {
            return !this.location.province;
        },
        showUsedSubCountries: function () {
            //console.log('this.location.subCountry',this.location.subCountry);
            return !this.location.subCountry;
        },
        usedCountries: function () {
            var index = 0;
            return this.locationService.getUsedCountries(this.locationsParam).map(row => {
                return {
                    country: row,
                    class: this.getRowClass(index++)
                }
            });
        },
        usedProvinces: function () {
            var index = 0;
            return this.locationService.getUsedProvinces(this.locationsParam, this.location.country).map(row => {
                return {
                    province: row,
                    class: this.getRowClass(index++)
                }
            });
        },
        usedSubCountries: function () {
            var index = 0;
            return this.locationService.getUsedSubCountries(this.locationsParam, this.location.country).map(row => {
                return {
                    subcountry: row,
                    class: this.getRowClass(index++)
                }
            });
        },
        enrichedPlaces: function () {
            var index = 0;
            return this.places.map(row => {
                return {
                    place: row,
                    class: this.getRowClass(index++)
                }
            });
        }

    },
    methods: {
        loadProvinces: function () {
            //console.log('loadProvinces', this.location.country, this.location.country._needsAdmin2);
            this.needsAdmin2 = this.location.country != null && this.location.country.needsAdmin2;
            if (this.location.country != null) {
                this.geoService.getProvinces(this.location.country.code).then(provinces => {
//                    console.log('loadProvinces callback');
                    if (this.needsAdmin2) {
                        var province = new Province();
                        province.code = "";
                        province.name = "";
                        provinces.unshift(province);
                        this.subCountries = provinces;
                    } else {
                        this.setProvinces(provinces);
                    }
                });

            }
        },
        onSelectCountry: function () {
            this.location.country = this.countriesLookup[this.selectedCountryCode];

            this.clearPlaces();
            this.provinces = null;
            this.location.province = null;
            this.subCountries = null;
            this.subCountry = null;
            this.loadProvinces();
        },
        selectUsedCountry: function (code) {
            //this.$refs.lstCountry.value = code;            
            this.selectedCountryCode = code;
            this.onSelectCountry();
        },
        selectSubUsedCountry: function (code) {
            this.location.subCountry = this.subCountries.find(x => {
                return x.code == code
            });
            this.onSelectSubCountry();
        },
        selectUsedProvince: function (code) {
            this.location.province = this.provinces.find(x => {
                return x.code == code
            });
            this.onSelectProvince();
        },
        onSelectSubCountry: function ()/*: void*/ {
            this.clearPlaces();
            this.provinces = null;

            //console.log('onSelectSubCountry', this.location.subCountry);
            if (this.location.subCountry != null) {
                this.subCountry = this.getValueAfterDot(this.location.subCountry.code);
                this.geoService.getProvinces2(this.location.country.code, this.subCountry).then(provinces => {
                    this.setProvinces(provinces);
                });
            }
        },
        setProvinces: function (provinces/*: Province[]*/)/*: void*/ {
            //console.log('setProvinces');
            var province = new Province();
            province.code = "";
            province.name = "";
            provinces.unshift(province);
            this.provinces = provinces;
            this.provincesBackup = provinces;
        },
        getProvinceCode: function ()/*: string*/ {
            return this.location.province ? this.getValueAfterDot(this.location.province.code) : null;
        },
        getRowClass: function (index) {
            return index % 2 == 0 ? 'even' : '';
        },
        clearPlaces: function () {
            this.places = null;
            this.location.place = null;
            this.place = null;
            this.location.geoId = null;
        },
        onSelectProvince: function () {
            if (!this._someProvincesHidden) {
                this.clearPlaces();
            }
            if (this.location.province != null) {
                if (this._someProvincesHidden) { //search again
                    var search = this.place;
                    if (search != null && search != "") {
                        this.searchPlace(search);
                    }
                }
            }
        },
        selectPlace: function (place/*: Place*/)/*: void*/ {
            if (place != null && place.name != this.place) {
                this.place = place.name;
                this.location.place = place;
                this.onPlaceSelected();
                this.isPlaceSelected = true;
            }
        },
        searchPlace: function (search/*: string*/)/*: void */ {
            var promises = [];
            if (search == null || search == "") {
                this.places = null;
                this.location.province = null;
                return;
            }
            this._currentSearch = search.toLowerCase();
            this.location.place = null;
            var provinceCode = this.getProvinceCode();
            //TODO debounce calls
            if (this.needsAdmin2) {
                promises.push(this.geoService.getPlaces2(this.location.country.code, this.subCountry, provinceCode, search)
                        .then(places => {
                            promises.push(this.setPlaces(places, search));
                        }));
            } else {
                promises.push(this.geoService.getPlaces(this.location.country.code, provinceCode, search)
                        .then(places => {
                            promises.push(this.setPlaces(places, search));
                        }));
            }
            return Promise.all(promises);
        },
        setPlaces: function (places/*: Place[]*/, search/*: string*/)/*: void*/ {
            console.log('setPlaces');
            var promises = [];
            search = search.toLowerCase();
            if (places != null) {
                var size = places.length;
                var found = false;
                if (!found && this._someProvincesHidden && size > 0) {   //this is a search that originally had multiple provinces and now has been triggered by a province selection        
                    var firstPlace = places[0];
                    if (firstPlace.name.toLowerCase() == search) {
                        this.location.geoId = firstPlace.id;
                        this.location.place = firstPlace;
                        promises.push(this.getGeoLocation());
//                        console.log('found', this.location.geoId);
                        found = true;
                    }
                }
                if (!found && this.isNewResultMorePrecise(places)) {
                    this.isPlaceSelected = false;
                    this.places = places;
                    if (size == 1 && this.location.place != null && this.location.place.name.toLowerCase() == this.place.toLowerCase()) {
                        this.place = this.location.place.name;
                        this.onPlaceSelected();
                    } else {
                        this.location.geoId = null;
                    }
                }
            }
            return Promise.all(promises);
        },
        isNewResultMorePrecise: function (places/*: Place[]*/)/*: boolean*/ {
            if (places != null && places.length > 0 && this.places != null && this.places.length > 0) {
                if (this.places[0] === places[0] && this.places.length == places.length)
                    return false;
                var firstPlaceInList = this.places[0].name.toLowerCase().replace('-', ' ');
                //only continue if the result has a closer match to _currentSearch then firstPlaceInList
                var firstPlaceInNewResult = places[0].name.toLowerCase().replace('-', ' ');
                var presentMatchPos = 0;
                var newMatchPos = 0;
                var length = Math.min(firstPlaceInList.length, this.place.length);
                while (presentMatchPos < length && firstPlaceInList.charAt(presentMatchPos) == this.place.charAt(presentMatchPos)) {
                    presentMatchPos++;
                }
                length = Math.min(firstPlaceInNewResult.length, this.place.length);
                while (newMatchPos < length
                        && (newMatchPos <= presentMatchPos)
                        && firstPlaceInNewResult.charAt(newMatchPos) == this.place.charAt(newMatchPos)) {
                    newMatchPos++;
                }
                return (newMatchPos >= presentMatchPos);
            }
            return true;
        },
        getValueAfterDot: function (value/*: string*/)/*: string*/ {
            if (value != null) {
                var dot = value.indexOf('.');
                if (dot > 0) {
                    value = value.substr(dot + 1);
                }
            }
            return value;
        },
        getCountries: function () {
            this.geoService.getCountries().then(countries => {
                this.countriesLookup = {};
                countries.forEach(x => {
                    this.countriesLookup[x.code] = x;
                });
                this.countries = countries;
            });
        },
        getTimezones: function () {
            this.geoService.getTimezones().then(timezones => {
                this.timezones = timezones;
            });
        },
        autoCompletePlace: function () {
            var selected = this.location.place;
            if (selected == null && this.places.length > 0) {
                this.location.place = this.places[0];
                this.place = this.location.place.name;
            }
            this.onPlaceSelected();
            this.isPlaceSelected = true;
        },
        onPlaceSelected: function () {
            console.log('onPlaceSelected 1')

            this.filterProvincesFromPlace().then(() => {
                if (this.location.province != null && this.location.place != null) {
                    this.location.geoId = this.location.place.id;
                    this.getGeoLocation();
                }
            });
        },
        filterProvincesFromPlace: function () {
            var search = this.place;
            var promises = [];
            if (!this.location.province) {
                if (this.needsAdmin2) {
                    promises.push(this.geoService.getProvinceFromPlace2(this.location.country.code, this.subCountry, search)
                            .then(provinces => {
                                promises.push(this.showProvincesForPlace(provinces));
                            }));
                } else {
                    promises.push(this.geoService.getProvinceFromPlace(this.location.country.code, search)
                            .then(provinces => {
                                promises.push(this.showProvincesForPlace(provinces));
                            }));
                }
            }
            return Promise.all(promises);

        },
        showProvincesForPlace: function (provinces/*: string[]*/) {
            var promises = [];
            this._someProvincesHidden = true;
            var code = '';
            this.provinces = [];
            //console.log('provinces', provinces, this.provincesBackup);
            provinces.forEach(value => {
                code = value;
                this.provincesBackup.forEach(province => {
                    if (province.code == code) {
                        this.provinces.push(province);
                    }
                });
            });
            if (provinces.length == 1) {
                this.$refs.province.value = code;
                this._isProvinceManuallySelected = false;
                this.location.province = this.provinces[0];
                promises.push(this.searchPlace(this.place));
            } else {
                this.location.province = null;
                this.unselectProvince();
            }
            return Promise.all(promises);
        },
        unselectProvince: function () {
            if (!this._isProvinceManuallySelected) {
                this.location.province = null;
                this.$refs.province.value = '';
            }
        },
        showAllProvinces: function () {
            if (this._someProvincesHidden) {
                this.provinces = this.provincesBackup;
                this._someProvincesHidden = false;
            }
        },
        onPlaceChange: function () {
            if (!this.keyDownWorking && !this.manual) {
                this.showAllProvinces();
                //this.subject.next(this.place);
                this.subject.next(this.place);
            }
        },
        onPlaceKeyDown: function (e) {
            this.keyDownWorking = true;
            if (!this.manual) {
                switch (e.keyCode) {
                    case 9: //tab
                    case 13://_keyCode.ENTER:        
                        if (this.places != null && this.places.length > 0) {
                            this.autoCompletePlace();
//                          e.stopPropagation();
//                          return false;
                        }
                        break;
                }
            }
        },
        onPlaceKeyUp: function (e) {
            if (!this.manual) {
                switch (e.keyCode) {
                    case 9://tab                        
                    case 13://_keyCode.ENTER:                                
                        return;
//                    case 38://_keyCode.DOWN:
                        // case 40://_keyCode.UP:
                        //   var elem = this.lstPlaceElement.nativeElement;
                        //   if (elem) {
                        //     elem.focus();
                        //     if (elem.options && elem.options.length > 0 && this.places.length > 0) {
                        //       this.location.place = this.places[0];
                        //       elem.selectedIndex = 0;
                        //       if (elem.options.length == 1) {
                        //         this.onListPlaceChange();
                        //       }
                        //     }
                        //   }
                        //   return;
                    default:
                        break;
                }
                this.showAllProvinces();
                //this.subject.next(this.place);
                this.subject.next(e.target != null ? e.target.value : this.place);
            }
        },

        getGeoLocation: function () {
            return this.geoService.getGeoLocation(this.location.geoId).then(geoLocation => {
                this.location.timeZone = geoLocation.timeZone;
                this.location.latitude = geoLocation.latitude;
                this.location.longitude = geoLocation.longitude;
                this.location.name = this.place;
                                                
                console.log('getGeoLocation finished', this.location.geoId);
            });
        },

        close: function () {
            this.closeModal(null);
        },
        save: function () {
            this.closeModal(this.isValid ? this.location : null);
        },
        compareCountry: function (country/*: Country*/, country2/*: Country*/)/*: boolean*/ {
            return country != null && country2 != null && country.code == country2.code;
        },
        compareSubCountry: function (country/*: Country*/, country2/*: Country*/)/*: boolean*/ {
            return country != null && country2 != null && country.code == country2.code;
        },
        compareProvince: function (province/*: Province*/, province2/*: Province*/)/*: boolean*/ {
            return province != null && province2 != null && province.code == province2.code;
        },
        comparePlace: function (place/*: Place*/, place2/*: Place*/)/*: boolean*/ {
            return place != null && place2 != null && place.id == place2.id;
        },
        compareTimezone: function (timezone/*: string*/, timezone2/*: string*/)/*: boolean*/ {
            return timezone == timezone2;
        },
        closeModal: function (location/*: Location*/) {
            modalController.dismiss(location);
        }

    },

}

import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { alertController } from '@ionic/vue';


export default  {

    init: function (storageService, cryptService, locationCombinationService, languageService, dialogService, folderService, licenseService, utilService) {
        this.storageService = storageService;
        this.cryptService = cryptService;
        this.locationCombinationService = locationCombinationService;
        this.languageService = languageService;
        this.dialogService = dialogService;
        this.folderService = folderService;
        this.licenseService = licenseService;
        this.utilService = utilService;
        this.labels = {};
        return this.initMessages();
    },

    initMessages: function () {
        var promises = [];
        promises.push(this.languageService.getText(EnumResourceStrings.TooManyCharts).then(x => this.labels.tooManyCharts = x));
        promises.push(this.languageService.getText(EnumResourceStrings.Overwrite).then(x => this.labels.overwrite = x));
        promises.push(this.languageService.getText(EnumResourceStrings.Merge).then(x => this.labels.merge = x));
        promises.push(this.languageService.getText(EnumResourceStrings.OverwriteOrMerge).then(x => this.labels.overwriteOrMerge = x));
        return Promise.all(promises);
    },

    loadLicense: function () {
        return this.licenseService.getLicense().then(x => {//             
            this.countAllowed = x.license.maxConcurrentEvents;
        });
    },
    getObjectHash: function (item)/*: number*/ {
        return this.utilService.getHash(JSON.stringify(item));
    },
    onImportCompleted: function (locationCombinations) {
        console.log(locationCombinations);
    },
    importLocationCombinations: function (contents/*: (string | ArrayBuffer)[]*/, skipDecrypt, allowMerge) {

        var promises = [];
        var decryptedContents = [];
        var errors = [];
        if (skipDecrypt) {
            decryptedContents = contents;
        } else {
            contents.forEach(content => {
                promises.push(this.cryptService.decrypt(content).then(x => {
                    var error = x["error"];
                    if (error) {
                        if (errors.indexOf(error) < 0) {
                            errors.push(error);
                        }
                    } else {
                        decryptedContents.push(x);
                    }
                }));
            });
        }
        return Promise.all(promises).then(() => {
            var message = '';
            if (errors && errors.length > 0) {
                errors.forEach(function (error) {
                    //console.log('error', error);
                    if (message) {
                        message += '<br />';
                    }
                    message += error;
                })
                return this.dialogService.alert(message);
            }
            if (decryptedContents && decryptedContents.length > 0) {
                if (allowMerge) {
                    message = this.labels.overwriteOrMerge;
                    alertController.create({
                        //header: 'Confirm',
                        message: message,
                        buttons: [
                            {
                                text: this.labels.overwrite,
                                cssClass: 'secondary',
                                handler: () => {
                                    this.doImportFile(false, decryptedContents, this.onImportCompleted);
                                }
                            }, {
                                text: this.labels.merge,
                                handler: () => {
                                    this.doImportFile(true, decryptedContents, this.onImportCompleted);
                                },
                                cssClass: 'primary',
                            }
                        ]
                    }).then(alert => {
                        return alert.present();
                    });
                } else {
                    return this.doImportFile(false, decryptedContents, this.onImportCompleted);
                }
            }
        });

    },
    doImportFile: function (merge/*: boolean*/, decryptedContents/*: (string | ArrayBuffer)[]*/, onImportCompleted) {

        var locationCombinationsMap = {};
        var foldersMap = {};
        //ensure unique items
        var promises = [];

        promises.push(this.loadLicense());
        if (merge) {
            promises.push(this.locationCombinationService.getLocationCombinations().then(locationCombinations => {
                locationCombinations.forEach(locationCombination => {
                    locationCombinationsMap[this.getObjectHash(locationCombination)] = locationCombination;
                });

                return this.folderService.getFolders().then(folders => {
                    folders.forEach(folder => {
                        foldersMap[folder.guid] = folder;
                    });
                });
            }));

        }

        //console.log('contents length=', contents.length);

        return Promise.all(promises).then(() => {

            decryptedContents.forEach(x => { //errors are already filtered out, so need to chech here anymore
                var result = x.result;
                var json = JSON.parse(result);
                json.locationCombinations.forEach(locationCombination => {
                    locationCombinationsMap[this.getObjectHash(locationCombination)] = locationCombination;
                });
                if (json.folders) {
                    json.folders.forEach(folder => {
                        foldersMap[folder.guid] = folder;
                    });
                }
            });
            var newLocationCombinations = Object.values(locationCombinationsMap);

            var newFolders = Object.values(foldersMap);

            if (newLocationCombinations.length <= this.countAllowed) {
                this.folderService.setFolders(newFolders);
                this.locationCombinationService.setLocationCombinations(newLocationCombinations).then(() => {
                    if (onImportCompleted) {
                        onImportCompleted(newLocationCombinations);
                    }
                });
            } else {
                var message = this.utilService.formatString(this.labels.tooManyCharts, [newLocationCombinations.length, this.countAllowed]);
                this.dialogService.alert(message, () => {
                });
            }
        });
    }

}


export default {
    subDomain: null,
    _isMainApp: null,
    _isWwwApp: null,
    _domainInitialized: false,
    initDomain: function () {
        var url = window.location.href;
        var rx = /^https?:\/\/(?<subdomain>[^.]*)\.luckfigures\.com/;
        var result = rx.exec(url);
        if (result) {
            var groups = result.groups;
            this.subDomain = groups['subdomain'];
            console.log('subDomain', this.subDomain);
        }
    },
    get selectedLanguage() {
        return "en";//temporary constant
    },
    getConfigSetting: function (setting) {
        if (!setting.startsWith('VUE_APP_'))
        {
            setting = 'VUE_APP_' + setting;
        }
        return process.env[setting];
    },
    getApiRootUrl: function () {
        return this.getConfigSetting('ApiRootUrl');
    },
    getAppKey: function () {
        return this.getConfigSetting('AppKey');
    },

    isSubDomain(subDomain) {
        if (!this._domainInitialized) {
            this.initDomain();
            this._domainInitialized = true;
        }
        return this.subDomain &&
                ((this.subDomain == subDomain || this.subDomain == subDomain + '-staging')
                        || (this.subDomain == 'staging' && subDomain == 'www')
                        );
    },
    requireLogin: function () {
        return !this.isWwwApp();
    },
    isMainApp: function () {
        if (this._isMainApp == null) {
            this._isMainApp = this._isApp('app');
            console.log('isMainApp', this._isMainApp);
        }
        return this._isMainApp;
    },
    isWwwApp: function () {
        if (this._isWwwApp == null) {
            this._isWwwApp = this._isApp('www');
        }
        return this._isWwwApp;
    },
    _isApp: function (app) {
        return this.getAppKey() == app || this.isSubDomain(app);
    }
}

import { StorageEnum } from '@/constants/enums';


export default  {

    init(storageService, httpClientService, utilService) {
        this.service = "transport/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.utilService = utilService;
    },
    createSharingCode: function () {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            return this.httpClientService.post(this.service + "createsharingcode", licenseKey);
        });
    },

    getSharingCode: function () {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            return this.httpClientService.post(this.service + "getsharingcode", licenseKey);
        });
    },

    import: function (sharingCode) {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            var importData = {
                sharingCode: sharingCode,
                encryptionData: {
                    //data: data.split(''),
                    licenseKey: this.utilService.removeDash(licenseKey)
                }
            };
            return this.httpClientService.post(this.service + "import", importData);
        });
    },
    export: function (sharingCode, data) {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            var importData = {
                sharingCode: sharingCode,
                encryptionData: {
                    data: data.split(''),
                    licenseKey: this.utilService.removeDash(licenseKey)
                }
            };
            return this.httpClientService.post(this.service + "export", importData);
        });
    },

}

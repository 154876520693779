import {SignatureTypeEnum, CardEnum} from '@/constants/enums';
import Constants from '@/constants/constants';
import {LuckFigureVM} from '@/models/luck';
import {IntervalEnum} from '@/constants/enums';
import {DateStruct} from '@/models/common';
export default {
    service: "luckfigures/",
    outerLimit: Constants.signaturesOuterLimit,
    weightsUrl: "luckfigures/weights",

    init: function (httpClientService, aspectTemplateService, utilService, locationService, aspectCombinationService) {
        this.httpClientService = httpClientService;
        this.aspectTemplateService = aspectTemplateService;
        this.utilService = utilService;
        this.locationService = locationService;
        this.aspectCombinationService = aspectCombinationService;
    },

    getWeight: function (aspectCombination) {
        if (aspectCombination.licenseCombination && aspectCombination.licenseCombination.timeUnits) {
            aspectCombination.licenseCombination.timeUnits = aspectCombination.licenseCombination.timeUnits.map(x => {
                x.day = x.day ? x.day : 0;
                x.hour = x.hour ? x.hour : 0;
                x.minute = x.minute ? x.minute : 0;
                return x;
            });
        }
        return this.httpClientService.post(this.weightsUrl, aspectCombination);
    },

    getNextStepDate: function (stepInterval, eventDate, next) {
        var direction = next ? 1 : -1;
        if (stepInterval.day) {
            eventDate.setDate(eventDate.getDate() + ((stepInterval.day + (stepInterval.day > 7 ? 7 : 1)) * direction));
        }
        if (stepInterval.hour) {
            eventDate.setHours(eventDate.getHours() + ((parseInt(stepInterval.hour) + 1) * direction));
        }
        if (stepInterval.minute) {
            eventDate.setMinutes(eventDate.getMinutes() + ((parseInt(stepInterval.minute) + 1) * direction));
        }
        var dateStruct = new DateStruct();
        dateStruct.loadFromDate(eventDate, true);
        return dateStruct;
    },

    generateTimeUnitsForInterval: function (intervalEnum) {
        var minutes = 0;
        var steps = 0;
        switch (intervalEnum)
        {
            case IntervalEnum.Day:
                steps = 7;
                minutes = 1440;
                break;
            case IntervalEnum.Hour:
                steps = 7;
                minutes = 60;
                break;
            case IntervalEnum.Minute:
                steps = 9;
                minutes = 1;
                break;
            case IntervalEnum.Week:
                steps = 7;
                minutes = 1440 * 7;
                break;
        }

        //minutes /= steps;//create 6 parts.
        console.log('generateTimeUnitsForInterval', minutes);
        var hours = parseInt(Math.floor(minutes / 60));
        minutes %= 60;
        var days = parseInt(Math.floor(hours / 24));
        hours %= 24;
        var timeUnits = [];
        for (var i = 0; i < steps; i++)
        {
            var minute = parseInt((i + 1) * (minutes || 0));
            var hour = parseInt((i + 1) * (hours || 0));
            var day = parseInt((i + 1) * (days || 0));
            if (minute > 59) {
                hour += Math.floor(minute / 60);
                minute %= 60;
            }
            if (hour > 24) {
                day += Math.floor(hour / 24);
                hour %= 24;
            }
            timeUnits.push({day: day, hour: hour, minute: minute});
        }
        return timeUnits;
    },

    getLuckFigures: function (aspectTemplate, outerLimit, timeUnits, eventDate, useAverages, roundMinutes, birthLocation, eventLocation, birthDate) {

        return this.aspectCombinationService.getAspectCombination(aspectTemplate, outerLimit, timeUnits, eventDate,
                useAverages, roundMinutes, birthLocation, eventLocation, birthDate).then(combination => {
            return this.getWeight(combination).then(x => {
                //var luckfigure = x["luckfigure"];
                var result = {};
                //this.positiveDeepThreshold
                var futureLuckFigures = x["luckFigures"];
                result.luckFigures = [];
                if (futureLuckFigures != null) {
                    result.luckFigures = futureLuckFigures.map(x => {
                        var futureLuckFigure = new LuckFigureVM(x.totalWeight, x.totalSurfaceWeight, x.totalDeepWeight, x.danger, x.eventDate, eventLocation, birthLocation, birthDate);
                        if (aspectTemplate.positiveThreshold) {
                            if (futureLuckFigure.totalWeight > aspectTemplate.positiveThreshold) {
                                futureLuckFigure.luckClass = "positive";
                            } else if (futureLuckFigure.totalWeight < aspectTemplate.negativeThreshold) {
                                futureLuckFigure.luckClass = "negative";
                            }
                        }
                        if (aspectTemplate.propertiesVm && aspectTemplate.propertiesVm.positiveDeepThreshold) {
                            if (futureLuckFigure.totalDeepWeight > aspectTemplate.propertiesVm.positiveDeepThreshold) {
                                futureLuckFigure.deepLuckClass = "positive";
                            } else if (futureLuckFigure.totalDeepWeight < aspectTemplate.propertiesVm.negativeDeepThreshold) {
                                futureLuckFigure.deepLuckClass = "negative";
                            }
                        }

                        if (aspectTemplate.propertiesVm && aspectTemplate.propertiesVm.positiveSurfaceThreshold) {
                            if (futureLuckFigure.totalSurfaceWeight > aspectTemplate.propertiesVm.positiveSurfaceThreshold) {
                                futureLuckFigure.surfaceLuckClass = "positive";
                            } else if (futureLuckFigure.totalSurfaceWeight < aspectTemplate.propertiesVm.negativeSurfaceThreshold) {
                                futureLuckFigure.surfaceLuckClass = "negative";
                            }
                        }
                        return futureLuckFigure;
                    });
                }
                return result;
            });

        });

    },

    getTopAspects: function (birthLocation, birthDate, eventLocation, eventDate, signatureTarget, connectingGrandStars) {
        return this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
            //var outerLimit = aspectTemplate.propertiesVm.aspectSecondaryDegreeAsDouble;
            //console.log('aspectTemplate',aspectTemplate);
            return this.aspectCombinationService.getAspectCombination(aspectTemplate, null, null, eventDate,
                    false, false, birthLocation, eventLocation, birthDate).then(aspectCombination => {

                var signature = aspectTemplate.signatures.find(x => x.signatureType == SignatureTypeEnum.Quintile && x.target == signatureTarget);
                aspectCombination.signatureId = signature.id;
                aspectCombination.signatureTarget = signatureTarget;
                aspectCombination.cardEnums = aspectTemplate.propertiesVm.cardEnums;
                aspectCombination.grandStarToGrandStar = connectingGrandStars;
                //reduce traffic
//                if (signatureTarget == SignatureTargetEnum.ChartX_ChartX || signatureTarget == SignatureTargetEnum.ChartY_ChartY) {
//                    aspectCombination.currentLocation = null;
//                    aspectCombination.targetDate = null;
//                }
                return this.httpClientService.post(this.service + "topaspects", aspectCombination).then(result => {
                    if (result) {
                        result.forEach(aspect => {
                            aspect.degreeVM = this.utilService.getDegreeVM(aspect.distance);
                            //console.log('aspect.distance', aspect.distance);
                            aspect.degree = this.utilService.getDegree(aspect.degreeVM);

                            aspect.normalizedDistance = aspect.distance / (CardEnum[aspect.sourceCard] * CardEnum[aspect.matchCard]);
                            aspect.normalizedOrb = this.utilService.getNormalizedOrb(aspect.normalizedDistance);
                        })
                    }
                    return result;
                });
            });
        });
    },

}

<ion-header>  
    <ion-toolbar class="ion-toolbar">
        <ion-title color="primary" v-html="title"></ion-title>
        <ion-buttons slot="primary">      
            <button class="button" :title="labels.save" @click="save" v-if="showSave">
                <ion-icon name="save" color="tertiary"></ion-icon>
            </button>
            <button class="button" :title="labels.close" @click="close" v-if="showClose">
                <ion-icon name="close"  color="tertiary"></ion-icon>
            </button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar class="name-header" v-if="nameParam">
        <ion-title size='small' v-html="nameParam"></ion-title>
    </ion-toolbar>
</ion-header>
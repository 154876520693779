

export class LicensePropertiesVM {
    constructor() {
        this.aspectSecondaryDegree = ''; // string;
        this.aspectSecondaryDegreeAsDouble = null; // number;
        this.aspectSecondaryMinute = ''; // string;
    }

}

export class LicenseVM {
    constructor() {
        this.maxConcurrentEvents = 0; // number = 0;
        this.maxFutureEvents = 0; // number = 0;
        this.licenseKey = ''; // string;
        this.endDate = null;
        this.aspectTemplateIds = []; // number[];
        this.propertiesVm = null; // LicensePropertiesVM;
        this.drillDownDepth = 0;
    }

}


export class LicenseWrapVM {
    constructor() {
        this.license = null; // LicenseVM;
        this.message = ''; // string;
        this.succeeded = null; // boolean;
    }
}

export class LicenseCheckVM {
    constructor() {
        this.licenseKey = ''; // string;
        this.authenticationCode = null; // number;
        this.authenticationKey = ''; // string;
        this.salt = ''; // string;
        this.encLic = ''; // string;    
    }

}

export class LicenseValidateVM {
    constructor() {
        this.license = null; // LicenseCheckVM;
        this.message = ''; // string;
        this.succeeded = null; // boolean;
    }
}
export class LicenseCreateVM {
    constructor() {
        this.email = ''; // string;
        this.captchaCheck = null; // CaptchaCheck;
    }
}

export class LicenseCreateResultVM {
    constructor() {
        this.succeeded = null; // boolean;
        this.message = ''; // string;
        this.encLic = ''; // string;
        this.salt = ''; // string;
    }

}

export class LicenseCombinationVM {
//    licenseKey; // string;
//    authenticationKey; // string;
//    timeUnits; // TimeUnit[];

    constructor(licenseKey, authenticationKey, timeUnits) {
        this.licenseKey = licenseKey;
        this.timeUnits = timeUnits;
        this.authenticationKey = authenticationKey;
    }

}

export class LicenseSettingsVM extends LicenseCombinationVM {
    constructor(licenseKey, authenticationKey, timeUnits, interval, showHistoryButton, aspectTemplate) {
        super(licenseKey, authenticationKey, timeUnits);
        this.interval = interval;        
        this.showHistoryButton = showHistoryButton;
        this.aspectTemplate = aspectTemplate;
    }

}
<dialog-header :title="labels.login" :showSave="false"  @onClose="closeModal(null)" :hideClose="true"></dialog-header>
<ion-content class="dialog">    
    <ion-row v-if="!licenseKey">
        <ion-col size-xs="12">
            <label v-html="labels.email"></label>
        </ion-col>
        <ion-col size-xs="12">
            <ion-input type="text" class="form-control"  v-model="licenseEmail"/>                
        </ion-col>            
    </ion-row>    
    <ion-row v-if="showCaptcha && isValidEmail">
        <ion-col size-xs="12">                
        </ion-col>
        <ion-col size-xs="12">                
            <captcha @onSuccess="onEmailCaptchaSuccess" :title="labels.OK"></captcha>
        </ion-col>            
    </ion-row>    
    <ion-row v-if="showMfaCode">
        <ion-col size-xs="12">
            <label v-html="labels.mfaCode"></label>
        </ion-col>
        <ion-col size-xs="12">
            <ion-input v-if="showMfaCode" v-model="authenticationCode" class="form-control float-left margin-right-5 input-6ch" type="text" maxlength="6" />                        
            <input type="button" v-if="authenticationCode != null" @click="save" :value="labels.login" />
        </ion-col>      
    </ion-row>
    <ion-row v-if="showMfaCode && mfaFeedback">
        <ion-col size-xs="12">
            <span class="error">{{mfaFeedback}}</span>
        </ion-col>
    </ion-row>    
</ion-content>

import { StorageEnum } from '@/constants/enums';
//import StorageService from '@/services/storageservice';

export default  {

    init(storageService, httpClientService) {
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.service = "crypt/";
    },
    removeDash: function (licenseKey) {
        return licenseKey ? licenseKey.replace(/-/g, '') : '';
    },
    encrypt: function (data) {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            var encryptionData = {
                data: data.split(''),
                licenseKey: this.removeDash(licenseKey)
            };
            return this.httpClientService.post(this.service + "encrypt", encryptionData);
        });
    },

    encryptDownload: function (data) {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            var encryptionData = {
                data: data.split(''),
                licenseKey: this.removeDash(licenseKey)
            };
            return this.httpClientService.post(this.service + "encryptdownload", encryptionData);
        });
    },

    decrypt: function (data) {
        return this.storageService.get(StorageEnum.LicenseKey).then(licenseKey => {
            var encryptionData = {
                data: data.split(''),
                licenseKey: this.removeDash(licenseKey)
            };
            return this.httpClientService.post(this.service + "decrypt", encryptionData);
        });
    }
}

import { IonIcon } from '@ionic/vue';
export default {
    props: {
        name: String
    },
    components: {
        IonIcon
    },
//    data() {
//        return {
//            accept,
//            title,
//            icon
//        }
//    },

//    mounted() {
//
//    },
//
//    methods: {
//    }



}

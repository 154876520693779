export const SortEnum = {
    NotSet: 0,
    Reference: 1,
    Date: 2,
    Profile: 3,
    Folder: 4
}
export const SortDirection = {
    Asc: 1,
    Desc: 2
}

export class SortCombination
{
    constructor(sortEnum) {
        this.sortEnum = sortEnum;
        this.sortDirection = SortDirection.Asc;
    }    
}

<ion-toolbar class="ion-toolbar">
    <ion-title color="primary" v-text="strTitle"></ion-title>
    <ion-buttons slot="primary">                    
        <ion-button type="button" class="button font16 float-right" @click="undo" v-text="undoButtonText" v-if="showUndo"></ion-button>
        <ion-button ion-button class="square-button float-right" @click="showLoginPopup" v-if="showLogin && requireLogin">            
            <span :title="strLogin">
                <ion-icon name="login" />
            </span>
        </ion-button>    
        <ion-button ion-button class="square-button float-right" @click="logout" v-if="!showLogin && requireLogin">            
            <span :title="strLogout">
                <ion-icon name="logout" />
            </span>
        </ion-button>    
        <ion-button ion-button class="square-button float-right" @click="showHistoryPopup" v-if="showHistory && isMainApp">
            <ion-icon name="ellipsis" />
        </ion-button>    
        <ion-button ion-button @click="clickMenu" class="square-button float-right" v-if="isMainApp">
            <ion-icon name="hamburger" />
        </ion-button>            
    </ion-buttons>
</ion-toolbar>

import { IonContent, IonInput, IonButtons, IonButton, IonRow, IonCol, modalController, IonDatetime } from '@ionic/vue';
import { Location } from '../../models/geo';
import DialogHeader from '@/components/dialog-header';
import IconButton from '@/components/icon-button';
import LocationComponent from '@/popups/location';
import {StorageEnum} from '@/constants/enums';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {addIcons } from 'ionicons';
import { trash, create, add } from 'ionicons/icons';


export default {
    components: {
        IonContent, IonInput, IonButtons, IonButton, IonRow, IonCol, IonDatetime, DialogHeader, IconButton
    },
    props: {
        nameParam: null,
        type: StorageEnum,
        countAllowed: null,
        location: null,
        locationsParam: null
    },
    inject: ["storageService", "languageService", "dialogService", "utilService"],
    setup() {
        addIcons({
            trash, create, add
        });
    },
    data() {
        return {
            labels: {},
            locations: [],
            selectedLocation: null,
            selectedLocationHash: null,
            //type: StorageEnum,
            //countAllowed: null,
            currentLocationIndex: null
        }

    },

    mounted() {

        this.selectedLocation = this.location;
        if (this.selectedLocation) {
            this.selectedLocationHash = this.utilService.getObjectHash(this.selectedLocation);
        }
        this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.confirmDelete = x);
        this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.add = x);
        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
        this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
        this.languageService.getText(this.type == StorageEnum.Birth ? EnumResourceStrings.BirthPlace : EnumResourceStrings.CurrentLocation).then(x => {
            this.labels.title = x;
        });
        this.loadLocations();
    },
    computed: {
        rows: function () {
            var index = 0;
            return this.locations.map(row => {
                return {
                    location: row,
                    class: this.getRowClass(index++)
                };
            });
        }
    },
    methods: {
        isSelectedLocation: function (location) {
            return this.selectedLocation
                    && location
                    && location.longitude == this.selectedLocation.longitude && location.latitude == this.selectedLocation.latitude //first a fast check
                    && this.selectedLocationHash == this.utilService.getObjectHash(location);//slow & thorough check            
        },
        loadLocations: function () {
            //this.storageService.getLocations(this.type).then(locations => {
            //this.storageService.getLocation(this.type).then(location => {
            //    if (locations) {
            //      this.locations = locations;
            //}
            this.locations = this.locationsParam;
            //console.log(this.locations.length);
            if (this.location && this.location.name && !this.locations.find(x => x.longitude == this.location.longitude && x.latitude == this.location.latitude)) {
                this.locations.push(this.location);
            }
            if (this.locations) {
                this.locations.sort((first, second) => {
                    return first.name < second.name ? -1 : 1;
                });
            }

            var i = 0;
            if (this.selectedLocation != null && this.locations) {
                this.locations.forEach(x => {

                    if (this.isSelectedLocation(x)) {
                        this.currentLocationIndex = i;
                        return false;
                    }
                    i++;
                });
            }
            if (this.selectedLocation && this.currentLocationIndex > 0) {
                var locs = [];
                locs.push(this.selectedLocation);
                this.locations.splice(this.currentLocationIndex, 1);
                this.locations = locs.concat(this.locations);
                //this.locations = locs;
                this.currentLocationIndex = 0;
            }

            if (this.countAllowed > 0 && this.locations && this.locations.length > this.countAllowed) {
                this.locations = this.locations.slice(0, this.countAllowed);
            }
            //  });
            //});
        },
        closeModal: function (location) {
            this.storageService.setLocations(this.type, this.locations);
            modalController.dismiss(location);
        },

        select: function (location/*: Location*/) {
            this.selectedLocation = location;
            //this.storageService.setLocations(this.type, this.locations);
            this.closeModal(this.selectedLocation);
        },
        add: function () {
            modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: LocationComponent,
                componentProps: {
                    type: null,
                    locationParam: new Location(),
                    locationsParam: this.locationsParam
                }
                //componentProps: {type: this.type}
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.data) {
                        this.locations.push(x.data);
                    }
                });

            });

        },
        update: function (location/*: Location*/) {
            console.log('editLocation', location);
            modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: LocationComponent,
                componentProps: {
                    type: this.type,
                    locationParam: location,
                    locationsParam: this.locationsParam,
                    nameParam: this.nameParam
                }
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.data) {
                        this.locations[this.locations.indexOf(location)] = x.data;
                    }
                });
            });
        },
        getRowClass: function (i/*: number*/) {
            return i == this.currentLocationIndex ? 'selected row border-default pointer' : 'row pointer';
        },

    }

}

import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet} from '@ionic/vue';
//import SettingsComponent from '@/settings-page';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';
import { flash, settings, bookmarksOutline as send, apps, peopleOutline as people, calendar } from 'ionicons/icons';
export default {
//name: 'Tabs',
    components: {
        IonLabel,
        IonTabs,
        IonTabBar,
        IonTabButton,
        IonIcon,
        IonPage,
        IonRouterOutlet
    },
    data() {
        return {
            labels: {}
        }
    },
    setup() {
        addIcons({
            flash,
            settings,
            send,
            apps,
            people,
            calendar
        });
    },
    inject: ["languageService", "configService"],
    mounted() {
        console.log('tabs');
        this.languageService.getText(EnumResourceStrings.Dashboard).then(x => this.labels.dashboard = x);
        this.languageService.getText(EnumResourceStrings.Settings).then(x => this.labels.settings = x);
        this.languageService.getText(EnumResourceStrings.Information).then(x => this.labels.information = x);
        this.languageService.getText(EnumResourceStrings.Events).then(x => this.labels.events = x);
        this.languageService.getText(EnumResourceStrings.Profiles).then(x => this.labels.profiles = x);
        this.languageService.getText(EnumResourceStrings.Calendar).then(x => this.labels.calendar = x);
    },
    computed: {
        isMainApp: function () {
            return this.configService.isMainApp();
        },
        isWwwApp: function () {
            return this.configService.isWwwApp();
        }
    }
}
import { IonPage, IonContent, IonRow, IonCol, modalController, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import PageHeader from '@/components/page-header';
import Profile from './profile';
import router from '@/router';
import Events from '@/profiles-page/events';
import LocationCombinationSelectComponent from '@/popups/locationcombination-select';
import IconButton from '@/components/icon-button';
import {StorageEnum} from '@/constants/enums';
import { Location} from '@/models/geo'
import {LocationCombination} from '@/models/locationcombination';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';
import { copy, trash, create, lockClosed as lockclosed,
        stopwatch as go, caretForwardOutline as right, caretBackOutline as left, caretUpOutline as arrowup, addCircle as plus,
        starOutline as staroutline, star,
        bookmarksOutline as events, flash as luckbutton,
        thumbsUp as thumbsup, thumbsDown as thumbsdown,
        documentTextOutline as details
} from 'ionicons/icons';


export default {

    components: {
        IonPage, PageHeader, IonContent, IonRow, IonCol, IconButton, Events, Profile, IonButton, IonButtons, IonIcon
    },
    setup() {
        addIcons({
            copy,
            trash,
            create,
            left,
            right,
            arrowup,
            go,
            plus,
            lockclosed,
            events,
            luckbutton,
            thumbsup,
            thumbsdown,
            staroutline,
            star,
            details
        });
    },
    data() {
        return{
            license: null,
            StorageEnum: StorageEnum,
            locationCombinations: [],
            luckFigures: {},
            selectedLocationCombination: null,
            countAllowed: null,
            type: StorageEnum,
            firstMount: true,
            redirecting: false,
            locationCombinationHistory: [],
            selectedEvent: null,
            locationCombinationLookup: {},
            folders: {},
            optionItemWidth: null,
            aspectTemplate: null,
            outerLimit: null,
            showFigures: false,
            showEvents: true,
            showLuckFiguresOnProfileScreen: false,
            storeUserProfilesInDb: true,
            _userProfileHash: '',
            labels: {},
            loadTrigger: 0,
            aspectTemplateHash: '',
            surfaceDeepMode: false
        }
    },
    inject: ["storageService", "locationCombinationService", "languageService", "dialogService", "utilService",
        "aspectTemplateService", "folderService", "locationService", "licenseService", "luckService",
        "locationCombinationEditService", "userProfileService", "exportService"],
    computed: {
        showGo: function () {
            return !this.showLuckFiguresAndEventButtons;
        },
        showLuckFiguresAndEventButtons: function () {
            return this.detailMode && this.showLuckFiguresOnProfileScreen;
        },
        showAdd: function () {
            return this.locationCombinations.length < this.countAllowed;
        },
        showUndo: function () {
            return this.locationCombinationHistory.length > 0;
        },
        undoButtonText: function () {
            var result = "";
            if (this.labels.strUndo) {
                result = this.utilService.formatString(this.labels.strUndo, [this.locationCombinationHistory.length]);
            }
            return result;
        },
        eventLabels: function () {
            return {
                strTitle: this.labels.strEvents,
                strSelectFolder: this.labels.strSelectFolder,
                strFolder: this.labels.strFolder,
                strEdit: this.labels.strEdit,
                strFileName: this.labels.strFileName,
                strDelete: this.labels.strDelete,
                strDate: this.labels.strDate
            };
        },
        newProfileName: function () {
            var result = '';
            result = this.labels.strProfile;
            if (result) {
                result += ' ' + (this.locationCombinations.length + 1);
            }
            return result;
        },
        profileCount: function () {
            return this.locationCombinations ? this.locationCombinations.length : 0;
        },
        detailMode: function () {
            return this.selectedLocationCombination;
        },
        editing: function () {
            return this.selectedLocationCombination && this.selectedLocationCombination.isEditing;
        },
        selectedProfile: function () {
            return this.selectedLocationCombination ? this.locationCombinations.find(y => y.guid == this.selectedLocationCombination.guid) : null;
        },
        figuresSelected: function () {
            return this.showFigures ? 'selected' : '';
        },
        eventsSelected: function () {
            return this.showEvents ? 'selected' : '';
        }
    },
    mounted() {
        this.init();
    },

    ionViewDidEnter() {
        this.redirecting = false;
        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.init();
        }
    },
    ionViewWillLeave: function () {
        if (!this.redirecting && !this.storageService.isLoggingOut) {
            return this.save();
        }
    },
    methods: {
        init: function () {
            this.loadTrigger++;
            var promises = [];
            promises.push(this.languageService.getText(EnumResourceStrings.ConfirmDelete).then(x => this.labels.strConfirmDelete = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.strAdd = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.strEdit = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.strDelete = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Optional).then(x => this.labels.strOptional = x));
            promises.push(this.languageService.getText(EnumResourceStrings.BirthPlace).then(x => this.labels.strBirthPlace = x));
            promises.push(this.languageService.getText(EnumResourceStrings.CurrentLocation).then(x => this.labels.strEventPlace = x));
            promises.push(this.languageService.getText(EnumResourceStrings.BirthTime).then(x => this.labels.strBirthTime = x));
            promises.push(this.languageService.getText(EnumResourceStrings.EventTime).then(x => this.labels.strEventTime = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Event).then(x => this.labels.strEvent = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Events).then(x => this.labels.strEvents = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Birth).then(x => this.labels.strBirth = x));
            promises.push(this.languageService.getText(EnumResourceStrings.DisplayName).then(x => this.labels.strDisplayName = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Profiles).then(x => this.labels.strTitle = x));
            promises.push(this.languageService.getText(EnumResourceStrings.ConfirmImportOverwrite).then(x => this.labels.strConfirmImport = x));
            promises.push(this.languageService.getText(EnumResourceStrings.TooManyCharts).then(x => this.labels.strTooManyCharts = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Cancel).then(x => this.labels.strCancel = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Overwrite).then(x => this.labels.strOverwrite = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Merge).then(x => this.labels.strMerge = x));
            promises.push(this.languageService.getText(EnumResourceStrings.OverwriteOrMerge).then(x => this.labels.strOverwriteOrMerge = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Copy).then(x => this.labels.strCopy = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Undo).then(x => this.labels.strUndo = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Expand).then(x => this.labels.strExpand = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Contract).then(x => this.labels.strContract = x));
            promises.push(this.languageService.getText(EnumResourceStrings.ReadOnly).then(x => this.labels.strReadOnly = x));
            promises.push(this.languageService.getText(EnumResourceStrings.FileName).then(x => this.labels.strFileName = x));
            promises.push(this.languageService.getText(EnumResourceStrings.SelectFolder).then(x => this.labels.strSelectFolder = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Folder).then(x => this.labels.strFolder = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Profile).then(x => this.labels.strProfile = x));
            promises.push(this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.strDate = x));
            promises.push(this.loadLicense());
//            promises.push(this.storageService.get(StorageEnum.StoreUserProfilesInDb).then(x => {
//                this.storeUserProfilesInDb = x;
//            }));
            Promise.all(promises).then(() => {

                this.loadAspectTemplate().then(() => {
                    this.load();
                    this.exportService.getExportJson().then(json => {
                        this._userProfileHash = this.utilService.getObjectHash(json);
                    });
                });
            });
        },
        load: function () {
            return this.loadFolders().then(() => {
                this.loadLocationCombinations();
            });
        },
        loadLicense: function () {
            return this.licenseService.getLicense().then(x => {
                this.license = x.license;
                if (this.license) {
                    this.countAllowed = this.license.maxConcurrentEvents;
                }
                var canShowLuckFiguresOnProfileScreen = this.license && this.license.propertiesVm && this.license.propertiesVm.canShowLuckFiguresOnProfileScreen;
                return this.storageService.get(StorageEnum.ShowLuckFiguresOnProfileScreen).then(x => {
                    this.showLuckFiguresOnProfileScreen = canShowLuckFiguresOnProfileScreen && x;

                    if (!this.showLuckFiguresOnProfileScreen) {
                        this.setShowEvents();
                    }
                });
            });
        },

        loadAspectTemplate: function () {

            return this.licenseService.getLicense().then(x => {
                this.outerLimit = x.license.propertiesVm.aspectSecondaryDegreeAsDouble;
                //this.getAspectTemplates(x.license.aspectTemplateIds);
                return this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                    //console.log('aspectTemplate', aspectTemplate);
                    this.aspectTemplate = aspectTemplate;
                    this.aspectTemplateHash = this.aspectTemplate.id + '_' + (this.aspectTemplate.changedOn ? this.aspectTemplate.changedOn : '-');
                    this.surfaceDeepMode = this.aspectTemplate.propertiesVm.surfaceDeepMode;
                });
            });
        },

        loadFolders: function () {
            return this.folderService.getFolderDictionary().then(folders => {
                this.folders = folders;
            });
        },
        setOptionsWidth: function () {
            if (!this.optionItemWidth) {
                var width = this.$el.offsetWidth;
                if (width > 500) {
                    width /= 2;
                }
                if (width) {
                    this.optionItemWidth = width - 50;
                }
            }
        },
        ensureOptionItemWidth: function () {
            var counter = 1;
            var interval = setInterval(() => {
                console.log('going for width', interval);
                this.setOptionsWidth();
                if (this.optionItemWidth > 0 || counter++ > 50) {
                    clearInterval(interval);
                }
            }, 100);
        },

        removeItemData: function (locationCombination) {
            //delete locationCombination.lookup;            
            delete locationCombination.isEditing;
            delete locationCombination.isSelected;
        },
        isSelectedLocationCombination: function (locationCombination) {
            return locationCombination == this.selectedLocationCombination;
        },

        loadLocationCombinations: function () {
            //var selectedLocationCombination = null;
            var locationCombinations = null;
            var promises = [];
            this.locationCombinationHistory = [];
            this.locationCombinationLookup = {};
            var selectedLocationCombinationGuid = null;
            promises.push(this.locationCombinationService.getSelectedLocationCombination().then(x => {
                if (x) {
                    selectedLocationCombinationGuid = x.guid;
                }
            }));
            promises.push(this.locationCombinationService.getLocationCombinations().then(x => {
                locationCombinations = x ? x : [];
            }));
            promises.push(this.locationCombinationService.getSelectedEvent().then(x => {
                this.selectedEvent = x;
            }));
            return Promise.all(promises).then(() => {
                if (locationCombinations && locationCombinations.length > 0) {
                    locationCombinations = locationCombinations.sort((first, second) => {
                        return first.name < second.name ? -1 : 1;
                    });
                }
                if (selectedLocationCombinationGuid) {
                    this.selectedLocationCombination = locationCombinations.find(y => y.guid == selectedLocationCombinationGuid);
                }
                if (this.selectedLocationCombination) {
                    this.selectedLocationCombination.isSelected = true;
                }

                var promises2 = [];
                this.locationCombinations = locationCombinations.map(locationCombination => {
                    if (this.isSelectedLocationCombination(locationCombination)) {
                        promises2.push(this.loadLocationCombinationLuckFigures(locationCombination));
                    }
                    return locationCombination;
                });
//                console.log('this.locationCombinations', this.locationCombinations);
                return Promise.all(promises2).then(() => {
                    //console.log('locationCombinations', this.locationCombinations, locationCombinations);
                    if (!this.locationCombinations || this.locationCombinations.length == 0) {
                        this.add();
//                    var locationCombination = this.add();
//                    this.setSelection(locationCombination);
                    }
                    this.ensureOptionItemWidth();
                });

            });
        },
        loadLocationCombinationLuckFigures: function (locationCombination) {
            var promises = [];
            Object.keys(locationCombination.events).forEach(key => {
                var event = locationCombination.events[key];
                promises.push(this.loadLuckFigure(locationCombination, event).then(evt => {
                    locationCombination.events[key] = evt;
                }));
            });
            return Promise.all(promises);
        },
        loadLuckFigure: function (locationCombination, event) {
            var eventDate = event.date;
            var promises = [];
            var locationCombinationHash = this.locationCombinationService.getLocationCombinationHash(locationCombination);
            //console.log('loadLuckFigure', locationCombination.birthDate, event.luckFigure.locationCombinationHash, locationCombinationHash);
            if (!event.luckFigure
                    || (event.luckFigure.aspectTemplateHash != this.aspectTemplateHash)                    
                    || (event.luckFigure.locationCombinationHash != locationCombinationHash)
                    ) {


                promises.push(this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, [], eventDate, false, false,
                        locationCombination.birthLocation, locationCombination.eventLocation, locationCombination.birthDate
                        ).then(x => {
                    var luckFigure = x.luckFigures[0];
                    delete luckFigure.birthDate;
                    delete luckFigure.birthLocation;
                    delete luckFigure.eventDate;
                    delete luckFigure.eventLocation;
                    delete luckFigure.eventDateStruct;
                    luckFigure.aspectTemplateHash = this.aspectTemplateHash;
                    luckFigure.surfaceDeepMode = this.surfaceDeepMode;
                    luckFigure.locationCombinationHash = locationCombinationHash;
                    event.luckFigure = luckFigure;
                    return event;
                }));
            }
            return Promise.all(promises).then(() => {
                return event;
            });
        },
        beforeShowHistoryCallback:function(){
            this.history = [];
            //this.save();
        },
        afterShowHistoryCallback: function () {
            return this.exportService.getExportJson().then(json => {
                this._userProfileHash = this.utilService.getObjectHash(json);
            })
        },
        save: function () {
            var locationCombinations = this.locationCombinations.filter(y => {
                return this.locationCombinationService.isComplete(y)
            }).map(x => {
                this.removeItemData(x);
                return x;
            }).sort((a, b) => {
                return a.name > b.name ? 1 : -1; //needed to avoid creating unneeded new records in tblUserProfile
            });

            var promises = [];

            if (this.selectedLocationCombination) {
                this.removeItemData(this.selectedLocationCombination);
            }
            promises.push(this.locationCombinationService.setSelectedLocationCombination(this.selectedLocationCombination));
            promises.push(this.locationCombinationService.setSelectedEvent(this.selectedEvent));

            promises.push(this.locationCombinationService.setLocationCombinations(locationCombinations));
            promises.push(this.exportService.getExportJson().then(json => {
                var newHash = this.utilService.getObjectHash(json);
                if (this._userProfileHash != newHash) {
                    return this.userProfileService.update(json).then(() => {
                        this._userProfileHash = newHash;
                    });
                }
            }));
            this.locationCombinationHistory = [];
            return Promise.all(promises);
        },

        redirect: function (route) {
            this.redirecting = true;
            this.save().then(() => {
                //this.$router.push({name: 'dashboard', params: {selectedLocationCombination: this.selectedLocationCombination}});
                router.replace(route);
            });
        },
        select: function (locationCombination/*: LocationCombination*/, eventDate) {
            console.log('select', locationCombination, eventDate);
            if (eventDate) {
                this.selectedEvent = locationCombination.events[this.utilService.formatISODate(eventDate)];
                //console.log('this.selectedEvent',this.selectedEvent);
            } else {
                this.selectedEvent = null
            }
            var selectedMode = this.selectedLocationCombination != null;
            this.selectedLocationCombination = locationCombination;
            var time = eventDate;
            if (!time) {
                time = this.selectedLocationCombination.eventDate;
            }
            if (selectedMode) {
                var promises = [];
                //          this.eventDate = time == null ? null : new Date(time.year, time.month, time.day, time.hour, time.minute, time.second, time.milliSecond);
                console.log('eventDate', time);
                promises.push(this.storageService.setDate(StorageEnum.Event, time));
                promises.push(this.locationCombinationService.setSelectedLocationCombination(locationCombination));
                Promise.all(promises).then(() => {
                    this.redirect('/tabs/dashboard');
                    //this.setShowFigures();
                });
            } else {
                this.locationCombinationService.setSelectedLocationCombination(locationCombination);
            }
        },
        selectFolder: function (locationCombination, event) {
            console.log('selectFolder', locationCombination, event);
            this.selectedLocationCombination = locationCombination;
            this.selectedEvent = event;
            //this.$router.push({name: 'dashboard', params: {selectedLocationCombination: this.selectedLocationCombination}});
            this.storageService.storeInMemory(StorageEnum.DrillToSelectedEvent, true);
            this.redirect('/tabs/events');
        },
        add: function () {
            var locationCombination = new LocationCombination();
            locationCombination.birthLocation = new Location();
            locationCombination.birthDate = null;
            locationCombination.eventLocation = new Location();
            locationCombination.eventDate = null;
            locationCombination.isEditing = true;
            locationCombination.name = this.newProfileName;
            locationCombination.guid = this.utilService.createGuid();
            this.locationCombinations.push(locationCombination);
            return this.setSelection(locationCombination).then(() => {
                return locationCombination;
            });
        },
        editSelected: function () {
            this.selectedLocationCombination.isEditing = true;
            this.selectedProfile.isEditing = true;
        },
        lockSelected: function () {
            this.selectedLocationCombination.isEditing = false;
            this.selectedProfile.isEditing = false;
        },
        copy: function (row) {
            var props = {
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: LocationCombinationSelectComponent,
                componentProps: {
//                    type: type,
//                    location: location,
//                    countAllowed: this.countAllowed,
//                    nameParam: name
                }
            };
            return modalController.create(props).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.role == "backdrop") {//escape button or backdrop clicked, so no data passed in callback
                    } else {
                        if (x.data) {
                            //            console.log(x.data);
                            row.birthLocation = x.data.birthLocation;
                            row.birthDate = x.data.birthDate;
                            //                this.locationCombinations[i].eventLocation = x.data.eventLocation;
                            //              this.locationCombinations[i].eventDate = x.data.eventDate;
                            row.name = x.data.name + '-copy';
                        }
                    }
                });

            });
        },

        updateLocation: function (locationCombination, type, newLocation, callback) {
            this.addToHistory(locationCombination);
            var promises = [];
            switch (type) {
                case StorageEnum.Birth:
                    locationCombination.birthLocation = newLocation;
                    break;
                case StorageEnum.Event:
                    locationCombination.eventLocation = newLocation;
                    promises.push(this.locationCombinationService.fixLocationCombinationEventsGmtOffset(locationCombination, true));
                    break;
            }
            promises.push(this.loadLocationCombinationLuckFigures(locationCombination));
            return Promise.all(promises).then(() => {
                return this.locationCombinationService.setSelectedLocationCombination(locationCombination).then(() => {
                    if (callback) {
                        callback();
                    }
                });
            })

        },
        updateDate: function (locationCombination, type, newDate, callback) {
            this.addToHistory(locationCombination);
            var promises = [];
            switch (type) {
                case StorageEnum.Birth:
                    locationCombination.birthDate = newDate;
                    break;
                case StorageEnum.Event: //not used...
                    locationCombination.eventDate = newDate;
                    break;
            }
            promises.push(this.loadLocationCombinationLuckFigures(locationCombination));
            return this.locationCombinationService.setSelectedLocationCombination(locationCombination).then(() => {
                if (callback) {
                    callback();
                }
            });
        },

        addToHistory: function (row) {
            var item = new LocationCombination();
            item.copy(row);
            //console.log('addToHistory', item.guid, item);
            this.locationCombinationHistory.push({guid: item.guid, locationCombination: item});
            //console.log('addToHistory', this.locationCombinationHistory);
        },

        undo: function () {
            var length = this.locationCombinationHistory.length;
            if (length > 0) {
                var item = this.locationCombinationHistory.pop();
                var guid = item.guid;

//                var index = this.locationCombinations.findIndex(x => {
//                    x.lookup == lookup
//                });
                var index = -1;
                var i = 0;
                this.locationCombinations.forEach(x => {
                    if (x.guid == guid)
                    {
                        index = i;
                        return false;
                    }
                    i++;
                });
//                console.log('undo', lookup, index);
                //              console.log('undo', index);
                if (index > -1) {
                    item.locationCombination.isEditing = this.locationCombinations[index].isEditing;
                    item.locationCombination.isSelected = this.locationCombinations[index].isSelected;
                    this.locationCombinations[index] = item.locationCombination;
                    if (item.locationCombination.isSelected) {
                        this.locationCombinationService.setSelectedLocationCombination(item.locationCombination);
                    }
                } else {
                    this.locationCombinations.push(item.locationCombination);
                }
            }
        },

        doDelete: function (locationCombination) {
            var index = this.locationCombinations.indexOf(locationCombination);
            this.addToHistory(locationCombination);
            this.locationCombinations.splice(index, 1);
            if (this.isSelectedLocationCombination(locationCombination)) {
                this.selectedLocationCombination = null;
                this.storageService.setLocation(this.type, null);
            }
            //          });
        },
        getSelectedIndex: function () {
            var index = null;
            for (var i = 0; i < this.locationCombinations.length; i++) {
                if (this.isSelectedLocationCombination(this.locationCombinations[i])) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        goPrevious: function () {
            var index = this.getSelectedIndex() - 1;
            if (index < 0) {
                index = this.locationCombinations.length - 1;
            }
            return this.setSelection(this.locationCombinations[index]);
        },
        goNext: function () {
            var count = this.locationCombinations.length;
            var index = this.getSelectedIndex() + 1;
            if (index >= count) {
                index = 0;
            }
            return this.setSelection(this.locationCombinations[index]);
        },
        setSelection: function (locationCombination) {
            this.selectedLocationCombination = locationCombination;
            this.locationCombinations.forEach(x => {
                var selected = this.isSelectedLocationCombination(x);
                x.isSelected = selected;
            });
            return this.locationCombinationService.setSelectedLocationCombination(locationCombination);
        },
        goUp: function () {
            this.locationCombinations.forEach(x => {
                x.isEditing = false;
            });
            this.selectedLocationCombination = null;
        },
        getLuckFigure: function (locationCombination) {
            return this.luckFigures[locationCombination.guid];
        },
        go: function () {
            if (this.selectedLocationCombination) {
                this.select(this.selectedLocationCombination);
            } else {
                //get luckfigure for all profiles
                var luckFigures = {};
                var promises = [];
                this.locationCombinations.forEach(locationCombination => {
                    if (locationCombination.birthLocation && locationCombination.eventLocation && locationCombination.birthDate) {
                        promises.push(this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, [], null, false, false,
                                locationCombination.birthLocation, locationCombination.eventLocation, locationCombination.birthDate).then(x => {
                            //this.luckFigure = x.luckFigures[0];  
                            luckFigures[locationCombination.guid] = x.luckFigures[0];
                        }));
                    }
                });
                Promise.all(promises).then(() => {
                    this.luckFigures = luckFigures;
                });
            }
        },
        setShowFigures: function () {
            this.showFigures = true;
            this.showEvents = false;
        },
        setShowEvents: function () {
            this.showEvents = true;//maybe toggle later
            this.showFigures = false;
        }
    }
}

<dialog-header :title="labels.title" :nameParam="nameParam" :showSave="false" @onClose="closeModal(null)"  ></dialog-header>
<ion-content class="dialog">
    <ion-row v-for="(row, index) in rows" :key="index" :class="row.class" >                                    
        <ion-col size="9" @click="select(row.location)" v-html="row.location.name"></ion-col>            
        <ion-col size="3">                                              
            <icon-button class="button ion-float-right" :title="labels.edit" @click="update(row.location)" icon="create"/>
        </ion-col>                        
    </ion-row>
    <ion-row v-if="this.locations && this.locations.length < this.countAllowed">            
        <ion-col size="10"></ion-col>
        <ion-col size="2">
            <icon-button class="button ion-float-right" :title="labels.add" @click="add" icon="add"/>                    
        </ion-col>
    </ion-row>                
</ion-content>
import { IonContent, IonRow, IonCol, modalController} from '@ionic/vue';
import IconButton from '@/components/icon-button';
import DialogHeader from '@/components/dialog-header';
//import {DateStruct} from '@/models/common'
import TimeComponent from '@/popups/time';
import {StorageEnum} from '@/constants/enums';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import { addIcons } from 'ionicons';
import { create, add } from 'ionicons/icons';

export default {
    components: {
        IonContent, IonRow, IonCol, DialogHeader, IconButton
    },
    setup() {
        addIcons({
            create, add
        });
    },
    props: {
        type: StorageEnum,
        date: null,
        nameParam: null,
        locationCombinations: null
    },
    inject: ["storageService", "languageService", "utilService"],
    data() {
        return {
            labels: {},
            selectedDate: null,
            dates: {}
        }
    },
    mounted() {
        this.selectedDate = this.date;
        this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.add = x);
        this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
        this.languageService.getText(this.type == StorageEnum.Birth ? EnumResourceStrings.BirthTime : EnumResourceStrings.EventTime).then(x => {
            this.labels.title = x;
        });
        this.loadDates();
    },
    methods: {
        sortOnKeys: function (dict) {
            return this.utilService.sortDictionaryOnKeys(dict);
        },
        loadDates: function () {
//                    this.storageService.getLocationCombinations().then(locationCombinations => {
            //         var test = locationCombinations[0].birthDate /*as DateStruct*/;
            var dates = {};
            this.locationCombinations.forEach(x => {
                var dt = (this.type == StorageEnum.Birth) ? x.birthDate : x.eventDate;
                if (dt != null) {
                    var selected = (this.selectedDate != null &&
                            this.selectedDate.year == dt.year
                            && this.selectedDate.month == dt.month
                            && this.selectedDate.day == dt.day
                            && this.selectedDate.hour == dt.hour
                            && this.selectedDate.minute == dt.minute
                            && this.selectedDate.second == dt.second
                            );
                    var lookup = this.getLookup(dt, selected);
                    var date = this.utilService.cloneObject(dt);
                    dates[lookup] = {
                        date: date,
                        dateString: this.dateString(date),
                        class: selected ? 'selected row border-default pointer' : 'row pointer'
                    };
                }
            });
            this.dates = this.sortOnKeys(dates);
            //            console.log('dates', this.dates);
            //                  });
        },
        getLookup: function (dt/*: DateStruct*/, selected) {
            var lookup = selected ? '00' : '';
            lookup += dt.year + '/';
            lookup += this.utilService.padStartString(dt.month.toString(), 2, '0') + '/';
            lookup += this.utilService.padStartString(dt.day.toString(), 2, '0') + ' ';
            lookup += this.utilService.padStartString(dt.hour.toString(), 2, '0') + ':';
            lookup += this.utilService.padStartString(dt.minute.toString(), 2, '0') + ':';
            if (dt.second) {
                lookup += this.utilService.padStartString(dt.second.toString(), 2, '0');
            }
            if (dt.milliSecond) {
                lookup += ':' + this.utilService.padStartString(dt.milliSecond.toString(), 3, '0');
            }
            return lookup;
        },

        dateString: function (dt) {
            var result = '';
            result += this.utilService.padStartString((parseInt(dt.month) + 1).toString(), 2, '0') + "/";
            result += this.utilService.padStartString(dt.day.toString(), 2, '0') + "/";
            result += dt.year + " ";
            result += this.utilService.padStartString(dt.hour.toString(), 2, '0') + ":";
            result += this.utilService.padStartString(dt.minute.toString(), 2, '0') + ":";
            result += dt.second == null ? '00' : this.utilService.padStartString(dt.second.toString(), 2, '0');
            if (dt.milliSecond) {
                result += ":" + this.utilService.padStartString(dt.milliSecond.toString(), 3, '0');
            }
            return result;
        },
        closeModal: function (date) {
//this.storageService.setLocations(this.type, this.dates);
            modalController.dismiss(date);
        },
        select: function (row) {
            this.selectedDate = row.date;
            this.closeModal(this.selectedDate);
        },
        add: function () {
            return this.update(null, null);
        },
        update: function (key, date) {
            return modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: TimeComponent,
                componentProps: {
                    type: this.type,
                    date: date,
                    nameParam: this.nameParam
                }
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.data) {
                        var dt = x.data;
                        if (!key) {
                            key = this.getLookup(dt, false);
                        }
                        this.dates[key] = {
                            date: dt,
                            class: 'row pointer',
                            dateString: this.dateString(dt)
                        };
                    }
                })
            });
        }
    }
}
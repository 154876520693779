import utilService from '@/services/utilService';

export class Folder
{
    constructor() {
        this.parentGuid = null;
        this.guid = utilService.createGuid();
        this.name = '';
        this.lookupCode = '';
    }
}

export class TreeFolder
{
    constructor(folder) {
        this.folder = folder ? folder : new Folder();
        this.children = [];
        this.events = {};
        this.isHidden = false;
        this.hasSelectedEvent = false;
        this.isEditing = false;
        this.isExpanded = false;
        this.isEventsExpanded = false;
        this.isNew = false;
//        this.isEditing = false;
//        this.selected = false;
    }

    get lookupCode() {
        return this.folder.lookupCode;
    }

    set lookupCode(value) {
        this.folder.lookupCode = value;
    }

    get parentGuid() {
        return this.folder.parentGuid;
    }

    set parentGuid(value) {
        this.folder.parentGuid = value;
    }

    get guid() {
        return this.folder.guid;
    }

    set guid(value) {
        this.folder.guid = value
    }

    get name() {
        return this.folder.name;
    }

    set name(value) {
        this.folder.name = value
    }
}


export class ProfileEvent
{
    constructor() {
        this.remark = '';
        this.date = null; //eventDateStruct
        this.description = '';
        this.folderGuid = null;
        this.gmtOffset = null;
        this.aspectTemplateHash = null;
    }
}

//export class ProfileEvents
//{
//    constructor(){
//        this.dict = {};
//    
//    }
//    
//    set(eventDate, profileEvent){
//        this.dict[eventDate] = profileEvent;
//    }
//    
//    get(eventDate)
//    {
//        return this.dict[eventDate];
//    }
//    
//}

export class LocationCombination {
    constructor() {
        this.name = '';
        this.guid = utilService.createGuid();
        this.birthLocation = null;
        this.eventLocation = null;
        this.birthDate = null;
        this.eventDate = null;// DateStruct;        
        this.events = {};
    }
    copy(other) {
        this.name = other.name;
        this.guid = other.guid;
        this.birthLocation = other.birthLocation;
        this.eventLocation = other.eventLocation;
        this.birthDate = other.birthDate;
        this.eventDate = other.eventDate;
        this.events = other.events ? utilService.cloneObject(other.events) : {};
    }
}



import FileSaver from 'file-saver';

export default  {

    init: function (storageService, locationCombinationService, cryptService, folderService, utilService) {
        this.storageService = storageService;
        this.locationCombinationService = locationCombinationService;
        this.cryptService = cryptService;
        this.folderService = folderService;
        this.utilService = utilService;
    },

    getExportJson: function () {
        return this.folderService.getFolders().then(folders => {
            return this.locationCombinationService.getLocationCombinations().then(locationCombinations => {
                if (locationCombinations) {
                    locationCombinations = locationCombinations.map(locationCombination => {
                        if (locationCombination.events) {
                            locationCombination.events = this.utilService.sortDictionaryOnKeys(locationCombination.events, true);
                        }
                        return locationCombination;
                    });
                }
                return {
                    locationCombinations: locationCombinations,
                    folders: folders
                };
            });
        });
    },
    exportLocationCombinations: function () {
        return this.getExportJson().then(json => {
            return this.cryptService.encrypt(JSON.stringify(json)).then(x => {
                var encrypted = x["result"];
                let blob = new Blob([encrypted], {/*type: 'application/json'*/})
                FileSaver.saveAs(blob, "luckfigures-locationcombinations.enc");
            });
        });
    }

}





export default  {

    init: function (language, storageService, httpClientService) {
        this.language = language;
        this.service = "language/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },

    getCachedValues: function (url) {
        return this.storageService.getCachedRequest(url).then(result => {
            if (result) {
                return result;
            } else {
                return this.httpClientService.get(url).then(res => {
                    return this.storageService.cacheRequest(url, res).then(() => {
                        return res;
                    });
                });
            }
        });
    },

    getText: function (resourceId) {
        var url = this.service + "text?l=" + this.language + '&resId='
        url += resourceId;
        return this.getCachedValues(url);
    },
    formatFullDate: function (date, weekDay, month) {
        //for now only english
        return weekDay + ' ' + month + ' ' + date.getDate() + ' ' + date.getFullYear();
    },
    getMonths: function () {
        var url = this.service + "months?l=" + this.language;
        return this.getCachedValues(url);
    },
    getWeekDays: function (shortWeekDays) {
        var url = this.service + (shortWeekDays ? "shortweekdays" : "weekdays") + "?l=" + this.language;
        return this.getCachedValues(url);
    },
}
